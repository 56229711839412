import React, { ChangeEventHandler } from 'react';
import ConvertHTML from '../tools/ConvertHTML';

class Toggle extends React.Component<
	{
		title: string;
		description: string;
		value: any;
		disabled: boolean;
		isLabelLeft: boolean;
		onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
		checked?: boolean;
	},
	{}
> {
	render() {
		var isLabelLeftStr = this.props.isLabelLeft ? 'fr-toggle--label-left' : '';
		return (
			<div
				className={'fr-toggle ' + isLabelLeftStr}
				onClick={(e) => e.stopPropagation()}>
				<input
					type='checkbox'
					className='fr-toggle__input'
					aria-describedby='toggle-698-hint-text'
					checked={this.props.checked}
					id={'toggle-' + this.props.value}
					disabled={this.props.disabled}
					onChange={this.props.onChange}
				/>
				<label
					className='fr-toggle__label'
					htmlFor={'toggle-' + this.props.value}
					data-fr-unchecked-label='Désactivé'>
					{this.props.title}
				</label>
				<span
					className='fr-hint-text'
					id='toggle-698-hint-text'>
					<ConvertHTML text={this.props.description} />
				</span>
			</div>
		);
	}
}

export default Toggle;
