import { useEffect, useState } from 'react';
import { EventCustomer } from '../../../models';
import Alert, { AlertSize, AlertType } from '../../alert/Alert';
import Radio from '../../radio/Radio';
import AppointmentAvailabilityPublicService, {
	AppointmentAvailabilitiesDisplayModel,
	AppointmentModalityType,
	AppointmentType
} from '../../../services/public/AppointmentAvailabilityPublicService';
import { AppointmentStepProps } from '../../../models/ComponentStepProps';

function TypeRDVStep({ model, handleChange }: AppointmentStepProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [countAppointment, setCountAppointment] = useState(0);

	useEffect(() => {
		setIsLoading(true);

		let eventPageNumber: EventCustomer = { name: 'pageNumber', value: 1 };
		handleChange(eventPageNumber);

		AppointmentAvailabilityPublicService.search({
			TenantID: 3,
			CirfaID: model.cirfaId || 0,
			AppointmentType: AppointmentType.All,
			PageNumber: 0,
			PageSize: 0
		})
			.then((resp) => {
				const appointmentsAvailabilities = resp?.data;
				appointmentsAvailabilities.forEach((x, index: number) => (x.id = index.toString()));
				let event: EventCustomer = { name: 'appointmentTypes', value: appointmentsAvailabilities };
				handleChange(event);
				setIsLoading(false);
				setCountAppointment(appointmentsAvailabilities.length);
			})
			.catch((reason: any) => {
				setIsLoading(false);
			});
	}, []);

	const appointmentModalityTypeStr = (x: AppointmentAvailabilitiesDisplayModel): string => {
		var appointmentModalityTypeStr = '';
		switch (x.appointmentModalityType) {
			case AppointmentModalityType.Physical:
				appointmentModalityTypeStr = 'présentiel';
				break;
			case AppointmentModalityType.Phone:
				appointmentModalityTypeStr = 'téléphonique';
				break;
			case AppointmentModalityType.Visio:
				appointmentModalityTypeStr = 'visioconférence';
				break;
		}

		return appointmentModalityTypeStr;
	};

	const appointmentModalityTypeInt = (x: AppointmentModalityType): number => {
		var appointmentModalityTypeInt = 0;
		switch (x) {
			case AppointmentModalityType.Physical:
				appointmentModalityTypeInt = 1;
				break;
			case AppointmentModalityType.Phone:
				appointmentModalityTypeInt = 2;
				break;
			case AppointmentModalityType.Visio:
				appointmentModalityTypeInt = 3;
				break;
		}

		return appointmentModalityTypeInt;
	};

	const formatRadioDisplayName = (x: AppointmentAvailabilitiesDisplayModel): string => {
		return `${x.appointmentLabel} ${appointmentModalityTypeStr(x)} de ${
			x.durationInMinutes ? x.durationInMinutes : ''
		} minutes\n${x.description ? x.description + '\n' : ''}${
			x.street && x.city?.zipCode && x.city?.name ? x.street + ' ' + x.city?.zipCode + ' ' + x.city?.name + '\n' : ''
		}${x.street == null && x.city?.zipCode && x.city?.name ? x.city?.zipCode + ' ' + x.city?.name + '\n' : ''}${
			x.street && x.city?.zipCode == null && x.city?.name == null ? x.street + '\n' : ''
		}${x.phoneNumber ? x.phoneNumber : ''}`;
	};

	return (
		<div>
			{isLoading && <div className='loader'></div>}
			{!isLoading && (
				<>
					{countAppointment === 0 && (
						<Alert
							description={`Nous sommes désolés mais aucun créneau n'est disponible.`}
							size={AlertSize.Small}
							type={AlertType.Warning}
							isShown={true}
						/>
					)}
					{model.appointmentError !== '' && (
						<Alert
							description={model.appointmentError}
							size={AlertSize.Small}
							type={AlertType.Warning}
							isShown={true}
						/>
					)}
					{countAppointment !== 0 && <h5>Choisir un type de rendez-vous parmi les propositions ci-dessous :</h5>}
					{model.appointmentTypes.some(
						(x: AppointmentAvailabilitiesDisplayModel) => x.appointmentType === AppointmentType.Individual
					) && (
						<>
							<Radio
								title='Rendez-vous individuel'
								name='configurationIdAndAppointmentTypeSelected'
								items={model.appointmentTypes
									.filter(
										(x: AppointmentAvailabilitiesDisplayModel) =>
											x.appointmentType === AppointmentType.Individual
									)
									.map((x: AppointmentAvailabilitiesDisplayModel) => {
										return {
											DisplayName: formatRadioDisplayName(x),
											Value: x.configurationId,
											AppointmentModalityTypeSelected: appointmentModalityTypeInt(
												x.appointmentModalityType
											),
											Checked:
												x.configurationId == model.configurationId &&
												x.appointmentModalityType == model.appointmentModalityTypeSelected,
											IsAppointmentLabel : true,
										};
									})}
								onChange={(e: any) => {
									let event: EventCustomer = {
										name: 'configurationIdAndAppointmentTypeSelected',
										value: {
											configurationId: e.target.value.split('-')[0],
											appointmentTypeSelected: AppointmentType.Individual,
											appointmentModalityTypeSelected: e.target.value.split('-')[1]
										}
									};
									handleChange(event);
								}}></Radio>
						</>
					)}
					{model.appointmentTypes.some(
						(x: AppointmentAvailabilitiesDisplayModel) => x.appointmentType === AppointmentType.Collective
					) && (
						<>
							<Radio
								title='Rendez-vous collectif'
								name='configurationIdAndAppointmentTypeSelected'
								items={model.appointmentTypes
									.filter(
										(x: AppointmentAvailabilitiesDisplayModel) =>
											x.appointmentType === AppointmentType.Collective
									)
									.map((x: AppointmentAvailabilitiesDisplayModel) => {
										return {
											DisplayName: formatRadioDisplayName(x),
											Value: x.configurationId,
											Checked: x.configurationId == model.configurationId,
											IsAppointmentLabel : true,
										};
									})}
								onChange={(e: any) => {
									let event: EventCustomer = {
										name: 'configurationIdAndAppointmentTypeSelected',
										value: {
											configurationId: e.target.value,
											appointmentTypeSelected: AppointmentType.Collective
										}
									};
									handleChange(event);
								}}></Radio>
						</>
					)}
					{model.appointmentTypes.some(
						(x: AppointmentAvailabilitiesDisplayModel) => x.appointmentType === AppointmentType.Permanence
					) && (
						<>
							<Radio
								title='Rendez-vous en permanence'
								name='configurationIdAndAppointmentTypeSelected'
								items={model.appointmentTypes
									.filter(
										(x: AppointmentAvailabilitiesDisplayModel) =>
											x.appointmentType === AppointmentType.Permanence
									)
									.map((x: AppointmentAvailabilitiesDisplayModel) => {
										return {
											DisplayName: formatRadioDisplayName(x),
											Value: x.configurationId,
											Checked: x.configurationId == model.configurationId,
											IsAppointmentLabel : true,
										};
									})}
								onChange={(e: any) => {
									let event: EventCustomer = {
										name: 'configurationIdAndAppointmentTypeSelected',
										value: {
											configurationId: e.target.value,
											appointmentTypeSelected: AppointmentType.Permanence
										}
									};
									handleChange(event);
								}}></Radio>
						</>
					)}
				</>
			)}
		</div>
	);
}

export default TypeRDVStep;
