import { useEffect, useState } from 'react';
import Radio, { RadioItem } from '../../radio/Radio';
import { AppointmentStepProps } from '../../../models/ComponentStepProps';

function FrenchNationalityStep({ model, handleChange }: AppointmentStepProps) {
	const [frenchItems, setFrenchItems] = useState<RadioItem[]>([]);

	useEffect(() => {
		const frenchItems: RadioItem[] = [
			{ DisplayName: 'Oui', Value: 'true', Checked: model.hasFrenchNationality == true },
			{ DisplayName: 'Non', Value: 'false', Checked: model.hasFrenchNationality == false }
		];
		setFrenchItems(frenchItems);
	}, []);

	const handleChangeFrenchNationality = (evt: any) => {
		if (evt.target) {
			frenchItems.filter((x) => x.Value != evt.target.value).map((x) => (x.Checked = false));
			frenchItems.filter((x) => x.Value == evt.target.value).map((x) => (x.Checked = true));
			handleChange(evt);
		}
	};

	return (
		<div>
			<h5>Renseigner votre nationalité</h5>
			<p>
				Vous devez avoir la nationalité française pour pouvoir vous engager dans{' '}
				{model.army?.name}
			</p>
			<p className='fr-hint-text account-creation-hint'>Sauf mention contraire, tous les champs sont obligatoires.</p>
			<Radio
				title='Êtes-vous de nationalité française ?'
				name='hasFrenchNationality'
				items={frenchItems || []}
				isHorizontal={false}
				disabled={false}
				isSmall={false}
				onChange={(e) => handleChangeFrenchNationality(e)}
				errorMessage={
					model.hasFrenchNationality !== undefined && model.hasFrenchNationality == false
						? `Vous n'êtes pas de nationalité française, vous ne pouvez pas vous engager dans ` +
						  model.army?.name +
						  `. Veuillez consulter <a href='https://www.legion-recrute.com/fr' title='La légion étrangère' target='_blank' rel='noopener'>la légion étrangère</a>`
						: ''
				}
			/>
		</div>
	);
}

export default FrenchNationalityStep;
