import React from 'react';
import JobCategorie from '../../components/jobcategories/jobs';
import PageTitle from '../../components/page-title/PageTitle';
import profileService from '../../services/ProfileService';
import ReferentialsPublicService from '../../services/public/ReferentialsPublicService';

class Index extends React.Component<{}, { jobsList: JSX.Element[]; countJobs: number; isloading: boolean }> {
	constructor(props: any) {
		super(props);
		this.state = {
			jobsList: [],
			countJobs: 0,
			isloading: false
		};
	}

	GetJobBycategorie(jobsOfInterest: any, categoryId: string) {
		const filtered = jobsOfInterest.filter((obj: any) => {
			return obj.categoryId === categoryId;
		});
		return filtered;
	}

	async BuildJobCategories(jobsOfInterest: any) {
		await ReferentialsPublicService.getJobCategories(localStorage.getItem('armyId') || '').then((data: any) => {
			var count = data.data.length;
			var cp = 0;
			var jobsCount = 0;
			const jobsElements = data.data.map((job: any) => {
				jobsCount += job.jobsNumber;

				var jobList = this.GetJobBycategorie(jobsOfInterest, job.id);
				return (
					<React.Fragment key={job.id}>
						<div className='fr-grid-row'>
							<JobCategorie
								title={job.name}
								id={job.id}
								nbpost={job.jobsNumber}
								jobs={jobList}></JobCategorie>
						</div>
						{cp++ < count && <div className='separator'></div>}
					</React.Fragment>
				);
			});
			this.setState({ countJobs: jobsCount });
			this.setState({ jobsList: jobsElements });
			this.setState({ isloading: false });
		});
	}

	async componentDidMount() {
		this.setState({ isloading: true });
		await profileService.get().then((response: any) => {
			this.BuildJobCategories(response.jobsOfInterest);
		});
	}

	render() {
		return (
			<>
				<div className='fr-container'>
					<div className='fr-grid-row'>
						<div className='fr-col-12'>
							<PageTitle
								imgLink=''
								size='h1'
								title='Gestion des postes souhaités'
								description='Vous trouverez ci-dessous les différents postes souhaités dans leurs catégories respectives.'
							/>
						</div>
					</div>
					{this.state.isloading == false && (
						<>
							<div className='separator'></div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<PageTitle
										imgLink=''
										title={'Mes postes'}
										description='Seuls les trois premiers postes de chaque catégorie sont vus par le conseiller.'
									/>
								</div>
							</div>

							{this.state.jobsList}

							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<ul className='fr-btns-group'>
										<li>
											<a
												className='fr-btn fr-btn--secondary'
												target='_blank'
												href={'/vitrines?armyId=' + (localStorage.getItem('armyId') || '')}>
												Choisir un autre poste
											</a>
										</li>
									</ul>
								</div>
							</div>
						</>
					)}

					{this.state.isloading && (
						<>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<div className='loader'></div>
								</div>
							</div>
						</>
					)}
				</div>
			</>
		);
	}
}

export default Index;
