import http, { baseURL } from '../http-common';
import axios from 'axios';
import QueryString from 'qs';
import ReferentialsService from './ReferentialsService';

export enum LoginResponse {
	UNKNOWN,
	SUCCESS,
	INVALID_CREDENTIALS,
	INTERNAL_SERVER_ERROR,
	REQUIRED_CREDENTIALS
}

class LoginService {
	async login(login: string, password: string, armyId: string): Promise<LoginResponse> {
		const getResp = axios.post<{
			username: string;
			password: string;
			grant_type: string;
			latestArmyId: any;
		}>(
			baseURL + '/token',
			QueryString.stringify({
				grant_type: 'password',
				username: login,
				password: password
			}),
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				validateStatus: function (status) {
					return true;
				}
			}
		);

		const resp = await getResp;

		if (armyId != '') {
			ReferentialsService.setArmy(parseInt(armyId));
		} else {
			ReferentialsService.setArmy(+resp.data.latestArmyId);
		}

		switch (resp.status) {
			case 200:
				localStorage.setItem('auth', JSON.stringify((await getResp).data));
				return LoginResponse.SUCCESS;
			case 400:
				return LoginResponse.INVALID_CREDENTIALS;
			case 500:
			default:
				return LoginResponse.INTERNAL_SERVER_ERROR;
		}
	}
}

export default new LoginService();
