import React from 'react';
import './0-Loading-mobile.scss';

class Loading extends React.Component {
	render(): React.ReactNode {
		return <div className='loading'></div>;
	}
}

export default Loading;
