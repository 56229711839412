import React from 'react';
import Loading from '../loading/Loading';
import './0-Container-mobile.scss';
import './1-Container-desktop.scss';

class Container extends React.Component<{ isLoading?: boolean | null; children: React.ReactNode }> {
	render(): React.ReactNode {
		return (
			<div className='fr-container sparta-container'>
				{this.props.isLoading && <Loading />}
				{this.props.children}
			</div>
		);
	}
}

export default Container;
