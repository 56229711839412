/* Composant de type Card utilisé pour la navigation par exemple */

import './0-Card-mobile.scss';
import './1-Card-desktop.scss';
import React from 'react';
import Label, { LabelType } from '../label/Label';
import { v4 as uuid } from 'uuid';

class Card extends React.Component<
	{
		title: string;
		description?: string;
		linkUrl?: string;
		imgLink?: string;
		isHorizontal?: boolean;
		tags?: CardTag[];
		hasBottomArrow?: boolean;
		links?: CardLink[];
		topDetail?: CardTopDetail;
		isDisabled?: boolean;
	},
	{}
> {
	renderHeaderElement() {
		if (this.props.imgLink === undefined) {
			return '';
		}
		return (
			<div
				className='fr-card__header'
				style={{ display: 'flex', alignItems: 'center' }}>
				{this.props.imgLink && (
					<div
						className='fr-card__img'
						style={{ maxHeight: '8rem', maxWidth: '8rem' }}>
						<img
							src={this.props.imgLink}
							alt=''
							style={{
								aspectRatio: '1',
								width: '100%',
								height: '100%',
								filter: this.props.isDisabled ? 'grayscale(1)' : 'none'
							}}></img>
					</div>
				)}
			</div>
		);
	}

	renderContentElement() {
		return (
			<div className='fr-card__content'>
				<div className='fr-card__start'>
					{this.props.topDetail && (
						<p className={'fr-card__detail ' + this.props.topDetail?.IconClass}>
							{this.props.topDetail?.DisplayName}
						</p>
					)}
					{this.props.tags && this.props.tags.length > 0 && (
						<ul className='fr-tags-group'>
							{this.props.tags.map((x) => {
								return (
									<li
										key={x.DisplayName}
										style={{ filter: this.props.isDisabled ? 'grayscale(1)' : 'none' }}>
										<Label
											description={x.DisplayName}
											hasIcon={false}
											isSmall={false}
											type={x.Type}></Label>
									</li>
								);
							})}
						</ul>
					)}
				</div>
				<h6 className='fr-card__title'>
					<a
						href={this.props.isDisabled ? undefined : this.props.linkUrl}
						style={{ outlineWidth: '0', backgroundImage: 'none' }}>
						{this.props.title}
					</a>
				</h6>
				{this.props.description && <p className='fr-card__desc'>{this.props.description}</p>}
			</div>
		);
	}

	renderFooterElement() {
		return (
			<>
				{this.props.links && this.props.links.length > 0 && (
					<div
						className='fr-card__footer'
						style={{ paddingLeft: '0', paddingRight: '0' }}>
						<ul className='fr-links-group'>
							{this.props.links.map((x) => {
								return (
									<span key={x.DisplayName}>
										<a
											style={{ marginLeft: '0px', marginRight: '0.5rem' }}
											className={'fr-link ' + x.IconClass + ' fr-link--icon-right'}
											href={x.Link || '#'}
											onClick={x.onClick}>
											{x.DisplayName}
										</a>
									</span>
								);
							})}
						</ul>
					</div>
				)}
			</>
		);
	}

	renderBodyElement() {
		return (
			<div className='fr-card__body'>
				{this.renderContentElement()}
				{this.renderFooterElement()}
			</div>
		);
	}

	render() {
		var isHorizontalStr = this.props.isHorizontal ? 'fr-card--horizontal fr-card--horizontal-tier' : '';
		var hasBottomArrowStr = this.props.hasBottomArrow ? 'fr-enlarge-link' : '';
		var hasImg = this.props.imgLink ? '' : 'no-image';

		return (
			<div
				className={`fr-card ${hasBottomArrowStr} ${isHorizontalStr} ${hasImg}`}
				style={{ flexDirection: this.props.isHorizontal ? 'row' : 'column' }}>
				{this.renderBodyElement()}
				{this.renderHeaderElement()}
			</div>
		);
	}
}

export interface CardLink {
	DisplayName: string;
	IconClass?: string;
	Link?: string;
	onClick?: () => void;
}

export interface CardTag {
	Type: LabelType;
	DisplayName: string;
}

export interface CardTopDetail {
	IconClass: string;
	DisplayName: string;
}

export default Card;
