import http from '../http-common';
import { baseURL } from '../http-common';

class JobService {
	private route_url = '/api/job/';

	async addJobOfInterest(jobCategoryId: string, jobTitle: string, maxAge: string) {
		const getResp = await http.post<{ JobCategoryId: string; JobTitle: string; maxAge: string }>(
			baseURL + this.route_url + 'addJobOfInterest',
			{ JobCategoryId: jobCategoryId, JobTitle: jobTitle, MaxAge: maxAge }
		);
		return await getResp;
	}

	async removeJobOfInterest(jobCategoryId: string, jobTitle: string) {
		const getResp = await http.post<{ JobCategoryId: string; JobTitle: string }>(
			baseURL + this.route_url + 'removeJobOfInterest',
			{ JobCategoryId: jobCategoryId, JobTitle: jobTitle }
		);
		return (await getResp).data;
	}

	async moveUpJobOfInterest(jobCategoryId: string, index: string) {
		const getResp = await http.post<{ JobCategoryId: string; Index: string }>(
			baseURL + this.route_url + 'moveUpJobOfInterest',
			{ JobCategoryId: jobCategoryId, Index: index }
		);
		return (await getResp).data;
	}

	async moveDownJobOfInterest(jobCategoryId: string, index: string) {
		const getResp = await http.post<{ JobCategoryId: string; Index: string }>(
			baseURL + this.route_url + 'moveDownJobOfInterest',
			{ JobCategoryId: jobCategoryId, Index: index }
		);
		return (await getResp).data;
	}
}

export default new JobService();
