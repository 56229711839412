class DateService {
	private GetDay(date: Date) {
		return date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	}

	private GetMonth(date: Date) {
		return date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
	}

	private GetYear(date: Date) {
		const yearSubThounsandStr = (date.getFullYear() / 1000).toString();
		var regex = /0/gi,
			result,
			zeros = [];
		while ((result = regex.exec(yearSubThounsandStr))) {
			zeros.push(result);
		}
		var year = date.getFullYear() < 1000 ? zeros.join('') + date.getFullYear().toString() : date.getFullYear().toString();

		if (year.length == 5 && year.charAt(0) == '0') {
			year = year.substring(1);
		}
		return year;
	}

	private GetHour(date: Date) {
		return date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
	}

	private GetMinutes(date: Date) {
		return date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
	}

	public Convert(date: string) {
		var timestamp = Date.parse(date);
		if (!isNaN(timestamp)) {
			const dateYes = new Date(date);
			const day = this.GetDay(dateYes);
			const month = this.GetMonth(dateYes);
			const year = this.GetYear(dateYes);

			return `${year}-${month}-${day}`;
		}
	}

	public ConvertForApplicationStatus(date: string) {
		var timestamp = Date.parse(date);
		if (!isNaN(timestamp)) {
			const dateYes = new Date(date);
			const day = this.GetDay(dateYes);
			const month = this.GetMonth(dateYes);
			const year = this.GetYear(dateYes);

			return 'Le ' + `${day}` + ' ' + monthName[parseInt(month)] + ' ' + `${year}`;
		}
	}

	public ConvertDateTimeLabel(date: string) {
		var timestamp = Date.parse(date);
		if (!isNaN(timestamp)) {
			var fullDay = this.ConvertAppointementDate(date);
			var fullHour = this.ConvertAppointementHour(date);

			return `le ${fullDay} à ${fullHour}`;
		}
		return ``;
	}

	public ConvertAppointementDate(date: string) {
		var timestamp = Date.parse(date);
		if (!isNaN(timestamp)) {
			const dateYes = new Date(date);
			const day = this.GetDay(dateYes);
			const month = this.GetMonth(dateYes);
			const year = this.GetYear(dateYes);

			return `${day}/${month}/${year}`;
		}
		return ``;
	}

	public ConvertAppointementHour(date: string) {
		var timestamp = Date.parse(date);
		if (!isNaN(timestamp)) {
			const dateYes = new Date(date);
			var hour = this.GetHour(dateYes);
			var mins = this.GetMinutes(dateYes);

			return `${hour}h${mins}`;
		}
		return ``;
	}

	public getAgeFromString(date: string) {
		const age = this.getAge(this.toDate(date));
		return age;
	}

	public getAge(date: Date) {
		var diff = Date.now() - date.getTime();
		var age = new Date(diff);
		return Math.abs(age.getUTCFullYear() - 1970);
	}

	public GetDateWithoutHours(date: Date) {
		var dateWithoutHours = new Date(date);
		dateWithoutHours.setHours(0, 0, 0, 0);
		return dateWithoutHours;
	}

	public toDate(date: string) {
		return new Date(date);
	}
}

export enum monthName {
	'janvier' = 1,
	'février',
	'mars',
	'avril',
	'mai',
	'juin',
	'juillet',
	'septembre',
	'octobre',
	'novembre',
	'décembre'
}
export default new DateService();
