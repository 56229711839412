import React from 'react';
import ConvertHTML from '../tools/ConvertHTML';

class Checkbox extends React.Component<
	{
		title?: string;
		name?: string;
		items: CheckboxItem[];
		errorMessage?: string;
		disabled?: boolean;
		isHorizontal?: boolean;
		onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
	},
	{}
> {
	static defaultProps: { name: string };
	render() {
		var errorStr = this.props.errorMessage ? 'fr-fieldset--error' : '';
		var isHorizontalStr = this.props.isHorizontal ? 'fr-fieldset--inline' : '';

		var itemElements = this.props.items.map((x) => {
			return (
				<div
					key={x.DisplayName}
					className='fr-checkbox-group'>
					<input
						name={this.props.name || ''}
						type='checkbox'
						id={'checkboxes-' + x.Value}
						value={x.Value}
						onChange={this.props.onChange}
						checked={x.Checked}></input>
					<label
						className='fr-label'
						htmlFor={'checkboxes-' + x.Value}>
						<ConvertHTML text={x.DisplayName}></ConvertHTML>
					</label>
				</div>
			);
		});
		return (
			<div className='fr-form-group'>
				<fieldset
					className={'fr-fieldset ' + isHorizontalStr + ' ' + errorStr}
					disabled={this.props.disabled}
					aria-labelledby={
						this.props.errorMessage ? 'checkboxes-legend checkboxes-error-desc-error' : 'checkboxes-legend'
					}
					role='group'>
					<legend
						className='fr-fieldset__legend fr-text--regular'
						id='checkboxes-legend'>
						{this.props.title}
					</legend>
					<div className='fr-fieldset__content'>{itemElements}</div>
					{this.props.errorMessage && (
						<p
							id='checkboxes-error-desc-error'
							className='fr-error-text'>
							{this.props.errorMessage}
						</p>
					)}
				</fieldset>
			</div>
		);
	}
}

export interface CheckboxItem {
	DisplayName: string;
	Value: any;
	Checked?: boolean;
}

export default Checkbox;
