import http from "../http-common";
import { baseURL } from "../http-common";

class ChatService
{
    private route_url = '/api/chat/';

    async getSenderAndChannel(armyId: number)
    {
        const getResp = await http.get<{ channelId: string, senderId: number }>(baseURL + this.route_url + '?armyId=' + armyId);
        return (await getResp).data;
    }
}

export default new ChatService();