import { ReactNode } from 'react';
import { v4 as uuid } from 'uuid';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonIconState, ButtonSize, ButtonType } from '../../components/button/Button';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import Modal, { ModalSize } from '../../components/modal/Modal';
import PageTitle from '../../components/page-title/PageTitle';
import Radio from '../../components/radio/Radio';
import Select, { SelectItem } from '../../components/select/Select';
import TileContainer from '../../components/tile-container/TileContainer';
import Tile from '../../components/tile/Tile';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import DateService from '../../services/DateService';
import AttachmentUpload from '../../components/attachment-upload/AttachmentUpload';
import { AttachmentState } from '../../services/AttachmentService';
import { NephosESLScopPoolEducationDetail } from '../../sparta.api';
import EnumGroupingType from '../../enums/EnumGroupingType';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import ReferentialSelect from '../../components/referential-select/ReferentialSelect';
import { BrowserView, MobileView } from 'react-device-detect';
import TabItem from '../../components/tabs/TabItem';
import Tabs from '../../components/tabs/Tabs';
import { createRef } from 'react';
interface ICompetencesScholarshipState extends IProfileState {
	isYearsLoading: boolean;
	successMessage?: string;
	currentEducationDetail?: NephosESLScopPoolEducationDetail;
	isEducationLoading: boolean;
	isUploadDiploma: boolean;
	isDeleteDiploma: boolean;
	isUploadScholarCertificateOrIdCard: boolean;
	isDeleteScholarCertificateOrIdCard: boolean;
	currentEducationIndex: number;
}

class CompetencesScholarship extends BaseComponent<ICompetencesScholarshipState> {
	state: ICompetencesScholarshipState = {
		isYearsLoading: true,
		isEducationLoading: false,
		isEdit: false,
		isDeleteDiploma: false,
		isUploadDiploma: false,
		isUploadScholarCertificateOrIdCard: false,
		isDeleteScholarCertificateOrIdCard: false,
		currentEducationIndex: -1
	};
	inputRef: any = createRef();
	certificateModelId: string = uuid();
	yearsSelectItems: SelectItem[] = [];

	groupingType: number = EnumGroupingType.COMPETENCES_SCOLARITE;

	async componentDidMount() {
		super.componentDidMount();

		ReferentialsService.getYears().then((years) => {
			this.yearsSelectItems = years.map((x) => {
				return { DisplayName: x.name, Value: x.id };
			});
			this.setState({ isYearsLoading: false });
		});
	}

	async checkIfCanDelete() {
		return await this.getRequiredFields();
	}

	setScholarCertificateOrIdCard(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				education: {
					...this.state.profile?.education,
					scholarCertificateOrIdCard: {
						id: guid,
						validationState: AttachmentState.WAITING_FOR_VALIDATION
					}
				}
			},
			isUploadScholarCertificateOrIdCard: true
		});
	}

	deleteScholarCertificateOrIdCard() {
		this.setState({
			profile: {
				...this.state.profile,
				education: {
					...this.state.profile?.education,
					scholarCertificateOrIdCard: undefined
				}
			},
			isDeleteScholarCertificateOrIdCard: true
		});
	}

	initUpload() {
		this.setState({
			isUploadScholarCertificateOrIdCard: false,
			isDeleteScholarCertificateOrIdCard: false
		});
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Scolarité'
							description='Veuillez remplir les informations demandées ci-dessous'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.successMessage && this.state.groupingIsValidate == false && (
							<Alert
								description={this.state.successMessage}
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<Tabs
							mobileOnly={true}
							isFormContainer={true}
							items={[
								{
									displayName: 'Formulaire',
									id: 'form',
									tabIndex: 0,
									selected: true
								},
								{
									displayName: 'Pièces jointes',
									id: 'attachments',
									tabIndex: 1,
									selected: false
								}
							]}>
							<TabItem
								id='form'
								tabIndex={0}
								selected={true}
								mobileOnly={true}>
								<h5>Mes diplômes</h5>
								<p>
									Pour ajouter un diplôme à votre dossier de candidature, veuillez cliquer sur le bouton
									“Ajouter un diplôme”
								</p>
								<Button
									disabled={this.state.groupingIsValidate ?? false}
									iconState={ButtonIconState.Left}
									onClick={() => {
										this.setState(
											{
												isEdit: false,
												currentEducationIndex: -1,
												isEducationLoading: true,
												currentEducationDetail: {},
												isUploadDiploma: false,
												isDeleteDiploma: false,
												isUploadScholarCertificateOrIdCard: false,
												isDeleteScholarCertificateOrIdCard: false
											},
											() => {
												this.setState({ isEducationLoading: false });
											}
										);
									}}
									text='Ajouter un diplôme'
									type={ButtonType.Primary}
									size={ButtonSize.Medium}
									modalId={this.certificateModelId}></Button>
								{this.state.profile?.education?.educationDetails &&
									this.state.profile?.education?.educationDetails.length > 0 && (
										<TileContainer>
											{this.state.profile?.education?.educationDetails.map((x, i) => {
												return (
													<Tile
														key={i + '' + x.degree}
														title={x.degree || ''}
														description={
															this.state.groupingIsValidate ?? false ? '' : 'En cours de validation'
														}
														disabled={this.state.groupingIsValidate ?? false}
														onDelete={async () => {
															let isGroupingValidated = await this.checkIfCanDelete();
															if (!isGroupingValidated) {
																let newdetails = this.state.profile?.education?.educationDetails!;
																newdetails = [
																	...newdetails?.slice(0, i),
																	...newdetails?.slice(i + 1, newdetails?.length)
																];
																this.setState(
																	{
																		successMessage: 'Le diplôme a été supprimé avec succès.',
																		profile: {
																			...this.state.profile,
																			education: {
																				...this.state.profile?.education,
																				educationDetails: newdetails
																			}
																		}
																	},
																	() => {
																		this.updateProfile();
																	}
																);
															}
														}}
														modalId={this.certificateModelId}
														onEdit={() => {
															this.setState(
																{
																	currentEducationIndex: i,
																	isEdit: true,
																	isEducationLoading: true,
																	currentEducationDetail:
																		this.state.profile?.education?.educationDetails?.at(i),
																	isUploadDiploma: false,
																	isDeleteDiploma: false,
																	isUploadScholarCertificateOrIdCard: false,
																	isDeleteScholarCertificateOrIdCard: false
																},
																() => {
																	this.setState({ isEducationLoading: false });
																}
															);
															this.setState({ isEducationLoading: false });
														}}
													/>
												);
											})}
										</TileContainer>
									)}
							</TabItem>
							{ReferentialsService.getArmy() === 2 && (
								<>
									<BrowserView>
										<hr className='fr-hr desktop-only' />
										<h5 className='desktop-only'>Pièces jointes</h5>
									</BrowserView>
									<TabItem
										id='attachments'
										tabIndex={1}
										selected={false}
										mobileOnly={true}>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<span className='fr-hint-text'>Taille maximale : 10 Mo.</span>
												<span className='fr-hint-text'>Formats supportés : jpg, png, pdf...</span>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<AttachmentUpload
													noExplanation
													hintText='Carte étudiant ou certificat de scolarité'
													guid={this.state.profile?.education?.scholarCertificateOrIdCard?.id}
													onDelete={this.deleteScholarCertificateOrIdCard.bind(this)}
													onUpload={this.setScholarCertificateOrIdCard.bind(this)}
													isDisabled={this.state.groupingIsValidate ?? false}
													isUploadInProgress={this.state.isUploadScholarCertificateOrIdCard}
													isDeleteInProgress={this.state.isDeleteScholarCertificateOrIdCard}
												/>
											</div>
										</div>
									</TabItem>
								</>
							)}
							<BrowserView>
								{!this.state.groupingIsValidate && (
									<div className='fr-grid-row fr-grid-row--right'>
										<div className='fr-col-12 fr-col-md-6 fr-align-right'>
											<Button
												disabled={this.state.groupingIsValidate ?? false}
												onClick={() => {
													this.updateProfile(() => {
														this.setState({
															successMessage: `Les informations renseignées ont été enregistrées avec succès`
														});
														this.displaySuccess();
														this.initUpload();
													});
												}}
												text='Enregistrer mes informations'
												type={ButtonType.Primary}
												iconClassName=''
												size={ButtonSize.Medium}
												modalId=''></Button>
										</div>
									</div>
								)}
							</BrowserView>
						</Tabs>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-8'>
										<Button
											className='mobile-button'
											disabled={false}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											iconClassName=''
											size={ButtonSize.Medium}
											modalId=''
											onClick={() => {
												this.updateProfile(() => {
													this.setState({
														successMessage: `Les informations renseignées ont été enregistrées avec succès`
													});
													this.displaySuccess();
													this.initUpload();
												});
											}}></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
				<Modal
					inputRef={this.inputRef}
					title={this.state.isEdit ? 'Modifier un diplome' : 'Ajouter un diplome'}
					id={this.certificateModelId}
					size={ModalSize.Large}
					buttons={[
						{
							Disabled:
							(this.state.currentEducationDetail?.degreeStatus == '1')? 
								this.state.currentEducationDetail?.rncpLevel?.code == '0' ||
								this.state.currentEducationDetail?.degree === undefined ||
								this.state.currentEducationDetail?.degree.trim() === '' ||
								this.state.currentEducationDetail?.degreeStatus === undefined ||
								this.state.currentEducationDetail?.graduationYear == undefined ||
								this.state.currentEducationDetail?.graduationYear == 0 
							:
								this.state.currentEducationDetail?.rncpLevel?.code == '0' ||
								this.state.currentEducationDetail?.degree === undefined ||
								this.state.currentEducationDetail?.degree.trim() === '' ||
								this.state.currentEducationDetail?.degreeStatus === undefined							
								,
							DisplayName: this.state.isEdit ? 'Modifier un diplome' : 'Ajouter un diplôme',
							OnClick: () => {
								if (this.inputRef && this.inputRef.current && !this.inputRef.current.disabled) {
									this.inputRef.current.disabled = true;
									let newdetails = this.state.profile?.education?.educationDetails || [];
									if (this.state.currentEducationIndex === -1 && this.state.currentEducationDetail) {
										newdetails.push(this.state.currentEducationDetail);
									} else if (this.state.currentEducationDetail) {
										newdetails[this.state.currentEducationIndex] = this.state.currentEducationDetail;
									}
									this.setState(
										{
											successMessage: this.state.isEdit
												? 'Le diplôme a été modifié avec succès'
												: 'Le diplôme a été ajouté avec succès',
											profile: {
												...this.state.profile,
												education: {
													...this.state.profile?.education,
													educationDetails: newdetails
												}
											},
											isDeleteDiploma: false,
											isUploadDiploma: false
										},
										() => {
											this.updateProfile();
											setTimeout(() => {
												this.inputRef.current.disabled = false;
											}, 2000);
										}
									);
								}
							}
						}
					]}>
					<>
						{!this.state.isEducationLoading && (
							<>
								<ReferentialSelect
									type={ReferentialType.DEGREE}
									label='Niveau du diplôme'
									value={this.state.currentEducationDetail?.level?.code}
									onChange={(e) => {
										this.setState({
											currentEducationDetail: {
												...this.state.currentEducationDetail,
												level: {
													code: e?.id,
													value: e?.name
												},
												rncpLevel: {
													code: e?.id,
													value: e?.rncpLevel == '0' ? '0' : e?.rncpLevel || ''
												}
											}
										});
									}}></ReferentialSelect>
								<Input
									title='Niveau RNCP'
									type={InputType.Hidden}
									value={this.state.currentEducationDetail?.rncpLevel?.value || ''}
									isReadonly={true}
									onChange={(v) => {
										this.setState({
											currentEducationDetail: {
												...this.state.currentEducationDetail,
												rncpLevel: {
													value: v
												}
											}
										});
									}}></Input>
								<Input
									title='Nom du diplôme'
									type={InputType.Text}
									value={this.state.currentEducationDetail?.degree}
									onChange={(v) => {
										this.setState({
											currentEducationDetail: {
												...this.state.currentEducationDetail,
												degree: v
											}
										});
									}}></Input>
								<Input
									title="Nom de l'établissement"
									type={InputType.Text}
									value={this.state.currentEducationDetail?.institute}
									onChange={(v) => {
										this.setState({
											currentEducationDetail: {
												...this.state.currentEducationDetail,
												institute: v
											}
										});
									}}></Input>
								<Input
									title="Ville de l'établissement"
									type={InputType.Text}
									value={this.state.currentEducationDetail?.city}
									onChange={(v) => {
										this.setState({
											currentEducationDetail: {
												...this.state.currentEducationDetail,
												city: v
											}
										});
									}}></Input>
								<Radio
									title='Etat du diplôme'
									disabled={false}
									name='certification-state'
									isHorizontal={false}
									isSmall={false}
									items={[
										{
											DisplayName: 'Obtenu',
											Value: 1,
											Checked: this.state.currentEducationDetail?.degreeStatus === '1'
										},
										{
											DisplayName: 'En cours',
											Value: 0,
											Checked: this.state.currentEducationDetail?.degreeStatus === '0'
										}
									]}
									onChange={(e) => {
										let value = e.currentTarget.value;
										if (value) {
											this.setState((prevState) => ({
												currentEducationDetail: {
													...this.state.currentEducationDetail,
													degreeStatus: value,
													graduationYear: value === '0' ? undefined : this.yearsSelectItems[0].Value
												}
											}));
										}
									}}></Radio>
								<Select
									title="Année d'obtention"
									disabled={this.state.currentEducationDetail?.degreeStatus !== '1'}
									defaultOptionDisplayName='Sélectionnez'
									items={this.yearsSelectItems}
									value={this.state.currentEducationDetail?.graduationYear}
									onChange={(e) => {
										this.setState({
											currentEducationDetail: {
												...this.state.currentEducationDetail,
												graduationYear: parseInt(e)
											}
										});
									}}></Select>
								{this.state.currentEducationDetail?.degreeStatus !== '0' && (
									<AttachmentUpload
										title='Pièce jointe'
										hintText=''
										isDisabled={this.state.groupingIsValidate ?? false}
										guid={this.state.currentEducationDetail?.diplomaAttachmentId?.id}
										validationState={this.state.currentEducationDetail?.diplomaAttachmentId?.validationState}
										onUpload={(guid) => {
											this.setState({
												currentEducationDetail: {
													...this.state.currentEducationDetail,
													diplomaAttachmentId: {
														id: guid,
														validationState: AttachmentState.ADDED
													}
												},
												isUploadDiploma: true
											});
										}}
										onDelete={() => {
											this.setState({
												currentEducationDetail: {
													...this.state.currentEducationDetail,
													diplomaAttachmentId: undefined
												},
												isDeleteDiploma: true
											});
										}}
										isUploadInProgress={this.state.isUploadDiploma}
										isDeleteInProgress={this.state.isDeleteDiploma}></AttachmentUpload>
								)}
							</>
						)}
					</>
				</Modal>
			</Container>
		);
	}
}

export default CompetencesScholarship;
