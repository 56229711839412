import mem from 'mem';
import axios from 'axios';
import QueryString from 'qs';
import conf from './configuration/api.json';
export let baseURL: string = conf.API_URL;

const publicPages = ['/prise-de-rdv', '/accountcreation', '/formulaire-prospect'];

const axiosApiInstance = axios.create({
	baseURL: baseURL
});

const refreshTokenFn = async () => {
	try {
		const authStorage = localStorage.getItem('auth');
		let auth = null;

		if (authStorage) {
			auth = JSON.parse(authStorage);
		}

		const data = QueryString.stringify({
			grant_type: 'refresh_token',
			refresh_token: auth.refresh_token
		});

		const respToken = await axiosApiInstance.post(baseURL + '/token', data, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		});

		if (respToken.status === 200) {
			localStorage.setItem('auth', JSON.stringify(respToken.data));
		} else {
			if (!publicPages.find((x) => x === window.location.pathname)) {
				window.location.href = '/login?redirect_url=' + encodeURI(window.location.pathname);
			}
		}

		return respToken.data;
	} catch (error) {
		console.log(error);

		if (!publicPages.find((x) => x === window.location.pathname)) {
			window.location.href = '/login?redirect_url=' + encodeURI(window.location.pathname);
		}
	}
};

const maxAge = 10000; //Milliseconds until the cache expires.

export const memoizedRefreshToken = mem(refreshTokenFn, {
	maxAge
});
