import { BrowserView, MobileView } from 'react-device-detect';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import Checkbox from '../../components/checkbox/Checkbox';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import PageTitle from '../../components/page-title/PageTitle';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import EnumGroupingType from '../../enums/EnumGroupingType';
import DateService from '../../services/DateService';
import FieldCheckerService from '../../services/FieldCheckerService';
import { ReferentialType } from '../../services/ReferentialsService';
import NationSelect from '../../components/referential-select/NationSelect';
import ReferentialSelectOptimise from '../../components/referential-select/ReferentialSelectOptimise';
import { v4 as uuid } from 'uuid';
import Modal, { ModalSize } from '../../components/modal/Modal';

interface IDomicilePrecedentState extends IProfileState {
	zipCode?: string;
}

class DomicilePrecedent extends BaseComponent<IDomicilePrecedentState> {
	state: IDomicilePrecedentState = { isEdit: false };

	groupingType: number = EnumGroupingType.SECURITE_DOMICILE_PRECEDENT;
	securityInformationModalId: string = uuid();

	componentDidUpdate() {
		this.requiredFields = this.state.profile?.securityDetails?.hasPreviousAddress
			? this.optionalFields
			: [{ Type: EnumApplicantInputArmyType.DOMICILE_PRECEDENT_J_AI_VECU_DANS_UN_DOMICILE_PRECEDENT, IsValid: true }];
		var checkField = this.requiredFields.find(
			(x) => x.Type === EnumApplicantInputArmyType.DOMICILE_PRECEDENT_J_AI_VECU_DANS_UN_DOMICILE_PRECEDENT
		);

		if (checkField && this.state.profile?.securityDetails?.hasPreviousAddress) {
			FieldCheckerService.check(
				EnumApplicantInputArmyType.DOMICILE_PRECEDENT_J_AI_VECU_DANS_UN_DOMICILE_PRECEDENT,
				this.state.profile.securityDetails.hasPreviousAddress,
				this.optionalFields
			);
			FieldCheckerService.check(
				EnumApplicantInputArmyType.DOMICILE_PRECEDENT_DU,
				this.state.profile?.securityDetails?.previousAddress?.startDate,
				this.optionalFields
			);
			FieldCheckerService.check(
				EnumApplicantInputArmyType.DOMICILE_PRECEDENT_AU,
				this.state.profile?.securityDetails?.previousAddress?.endDate,
				this.optionalFields
			);
			FieldCheckerService.check(
				EnumApplicantInputArmyType.DOMICILE_PRECEDENT_ADRESSE,
				this.state.profile?.securityDetails?.previousAddress?.street,
				this.optionalFields
			);
			FieldCheckerService.check(
				EnumApplicantInputArmyType.DOMICILE_PRECEDENT_COMPLEMENT_D_ADRESSE,
				this.state.profile?.securityDetails?.previousAddress?.additionalInformation,
				this.optionalFields
			);
			FieldCheckerService.check(
				EnumApplicantInputArmyType.DOMICILE_PRECEDENT_CODE_POSTAL,
				this.state.profile?.securityDetails?.previousAddress?.cityId,
				this.optionalFields
			);
			FieldCheckerService.check(
				EnumApplicantInputArmyType.DOMICILE_PRECEDENT_VILLE_OU_COMMUNE,
				this.state.profile?.securityDetails?.previousAddress?.cityId,
				this.optionalFields
			);
			FieldCheckerService.check(
				EnumApplicantInputArmyType.DOMICILE_PRECEDENT_PAYS_DE_RESIDENCE,
				this.state.profile?.securityDetails?.previousAddress?.countryId,
				this.optionalFields
			);
		}
	}

	constructor(props: any) {
		super(props);

		this.state = {
			zipCode: ''
		};
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Domicile précédent'
							description='Certains champs concernant vos informations personnelles sont automatiquement remplies. Veuillez vérifier l’exactitude des informations et compléter les informations manquantes.'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.groupingIsValidate == false && (
							<Alert
								description='Les informations renseignées ont été enregistrées avec succès'
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<FormContainer>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Checkbox
										items={[
											{
												DisplayName: "J'ai vécu dans un domicile précédent",
												Value: '1',
												Checked: this.state.profile?.securityDetails?.hasPreviousAddress
											}
										]}
										disabled={this.state.groupingIsValidate ?? false}
										onChange={(e) => {
											this.setState({
												profile: {
													...this.state.profile,
													securityDetails: {
														...this.state.profile?.securityDetails,
														previousAddress: e.currentTarget.checked ? {} : undefined,
														hasPreviousAddress: e.currentTarget.checked
													}
												}
											});
										}}></Checkbox>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Du'
										value={DateService.Convert(
											this.state.profile?.securityDetails?.previousAddress?.startDate || ''
										)}
										type={InputType.Date}
										disabled={
											!this.state.profile?.securityDetails?.hasPreviousAddress ||
											(this.state.groupingIsValidate ?? false)
										}
										onChange={(e) => {
											this.setState({
												profile: {
													...this.state.profile,
													securityDetails: {
														...this.state.profile?.securityDetails,
														previousAddress: {
															...this.state.profile?.securityDetails?.previousAddress,
															startDate: DateService.Convert(e)
														}
													}
												}
											});
										}}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Au'
										value={DateService.Convert(
											this.state.profile?.securityDetails?.previousAddress?.endDate || ''
										)}
										type={InputType.Date}
										disabled={
											!this.state.profile?.securityDetails?.hasPreviousAddress ||
											(this.state.groupingIsValidate ?? false)
										}
										onChange={(e) => {
											this.setState({
												profile: {
													...this.state.profile,
													securityDetails: {
														...this.state.profile?.securityDetails,
														previousAddress: {
															...this.state.profile?.securityDetails?.previousAddress,
															endDate: DateService.Convert(e)
														}
													}
												}
											});
										}}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Adresse'
										description='Numéro + rue'
										value={this.state.profile?.securityDetails?.previousAddress?.street}
										type={InputType.Text}
										disabled={
											!this.state.profile?.securityDetails?.hasPreviousAddress ||
											(this.state.groupingIsValidate ?? false)
										}
										onChange={(e) => {
											this.setState({
												profile: {
													...this.state.profile,
													securityDetails: {
														...this.state.profile?.securityDetails,
														previousAddress: {
															...this.state.profile?.securityDetails?.previousAddress,
															street: e
														}
													}
												}
											});
										}}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title="Complément d'adresse"
										description='Bâtiment, immeuble, escalier...'
										value={this.state.profile?.securityDetails?.previousAddress?.additionalInformation}
										type={InputType.Text}
										disabled={
											!this.state.profile?.securityDetails?.hasPreviousAddress ||
											(this.state.groupingIsValidate ?? false)
										}
										onChange={(e) => {
											this.setState({
												profile: {
													...this.state.profile,
													securityDetails: {
														...this.state.profile?.securityDetails,
														previousAddress: {
															...this.state.profile?.securityDetails?.previousAddress,
															additionalInformation: e
														}
													}
												}
											});
										}}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<NationSelect
										label='Pays de résidence'
										disabled={
											!this.state.profile?.securityDetails?.hasPreviousAddress ||
											(this.state.groupingIsValidate ?? false)
										}
										onChange={(e) => {
											this.setState({
												showFreeCityFieldsForSecurityDetailsPreviousAddressCountryId:
													e?.name !== 'FRANCE',
												profile: {
													...this.state.profile,
													securityDetails: {
														...this.state.profile?.securityDetails,
														previousAddress: {
															...this.state.profile?.securityDetails?.previousAddress,
															countryId: e?.id,
															country: {
																...this.state.profile?.securityDetails?.previousAddress?.country,
																name: e?.name,
																shortName: e?.shortname,
																nationality: e?.nationality
															},
															cityId: undefined,
															city: {
																...this.state.profile?.securityDetails?.previousAddress?.city,
																id: undefined,
																zipCode: e?.name === 'MONACO' ? '98000' : '',
																name: ''
															}
														}
													}
												}
											});
										}}
										value={this.state.profile?.securityDetails?.previousAddress?.countryId}
										type={ReferentialType.NATION}
										customOptionLabel={(o: any) => o.shortName}
									/>
								</div>
							</div>
							{this.state.showFreeCityFieldsForSecurityDetailsPreviousAddressCountryId && (
								<>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title='Code postal'
												type={InputType.Text}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																previousAddress: {
																	...this.state.profile?.securityDetails?.previousAddress,
																	city: {
																		...this.state.profile?.securityDetails?.previousAddress
																			?.city,
																		zipCode: e
																	}
																}
															}
														}
													})
												}
												value={this.state.profile?.securityDetails?.previousAddress?.city?.zipCode}
												disabled={
													!this.state.profile?.securityDetails?.hasPreviousAddress ||
													(this.state.groupingIsValidate ?? false)
												}></Input>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title='Ville ou commune'
												type={InputType.Text}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																previousAddress: {
																	...this.state.profile?.securityDetails?.previousAddress,
																	city: {
																		...this.state.profile?.securityDetails?.previousAddress
																			?.city,
																		name: e
																	}
																}
															}
														}
													})
												}
												value={this.state.profile?.securityDetails?.previousAddress?.city?.name}
												disabled={
													!this.state.profile?.securityDetails?.hasPreviousAddress ||
													(this.state.groupingIsValidate ?? false)
												}></Input>
										</div>
									</div>
								</>
							)}
							{!this.state.showFreeCityFieldsForSecurityDetailsPreviousAddressCountryId && (
								<>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<ReferentialSelectOptimise
												label='Code postal'
												onChange={(e) =>
													this.setState({
														zipCode: e?.zipCode,
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																previousAddress: {
																	...this.state.profile?.securityDetails?.previousAddress,
																	cityId: undefined,
																	city: {
																		...this.state.profile?.securityDetails?.previousAddress
																			?.city,
																		id: e.id,
																		zipCode: e?.zipCode
																	}
																}
															}
														}
													})
												}
												zipCode={this.state.profile?.securityDetails?.previousAddress?.city?.zipCode}
												value={this.state.profile?.securityDetails?.previousAddress?.city?.id}
												disabled={
													!this.state.profile?.securityDetails?.hasPreviousAddress ||
													(this.state.groupingIsValidate ?? false)
												}
												type={ReferentialType.ZIPCODE}
												customOptionLabel={(o) => o.zipCode}
											/>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<ReferentialSelectOptimise
												label='Ville ou commune'
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																previousAddress: {
																	...this.state.profile?.securityDetails?.previousAddress,
																	cityId: e?.id,
																	city: {
																		...this.state.profile?.securityDetails?.previousAddress
																			?.city,
																		zipCode: e?.zipCode,
																		id: e?.id,
																		name: e?.name
																	}
																}
															}
														}
													})
												}
												value={this.state.profile?.securityDetails?.previousAddress?.cityId}
												name={this.state.profile?.securityDetails?.previousAddress?.city?.name}
												disabled={
													!this.state.profile?.securityDetails?.hasPreviousAddress ||
													(this.state.groupingIsValidate ?? false)
												}
												type={ReferentialType.CITY}
												zipCode={this.state.profile?.securityDetails?.previousAddress?.city?.zipCode}
											/>
										</div>
									</div>
								</>
							)}
							<BrowserView>
								{!this.state.groupingIsValidate && (
									<div className='fr-grid-row fr-grid-row--right'>
										<div className='fr-col-12 fr-col-md-6 fr-align-right'>
											<Button
												className='no-margin'
												disabled={false}
												text='Enregistrer mes informations'
												type={ButtonType.Primary}
												size={ButtonSize.Medium}
												modalId={this.securityInformationModalId}></Button>
										</div>
									</div>
								)}
							</BrowserView>
						</FormContainer>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-8'>
										<Button
											className='mobile-button'
											disabled={false}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											size={ButtonSize.Medium}
											modalId={this.securityInformationModalId}></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
				<Modal
					size={ModalSize.Large}
					title='Certifier l’exactitude des informations renseignées'
					id={this.securityInformationModalId}
					buttons={[
						{
							DisplayName: 'Certifier l’exactitude des informations renseignées',
							IconClass: '',
							OnClick: () => {
								this.updateProfile(() => this.displaySuccess());
							}
						},
						{
							DisplayName: 'Annuler',
							IconClass: '',
							Type: ButtonType.Secondary
						}
					]}
					children={
						<>
							<div>
								Vous avez renseigné les informations concernant la section sécurité. Veuillez vérifier que les
								informations sont correctes et que vous reconnaissez les informations suivantes.
							</div>
							<br></br>
							<div>
								Je reconnais être informé(e) :
								<dl style={{ paddingLeft: 0 }}>
									<dt>- du caractère obligatoire des réponses qui me sont demandées ;</dt>
									<dt>
										- de ce que je dispose d'un droits d'accès et de rectification, en application des article
										34 et suivant de la loi n°78-17 du 6 janvier 1978 relatives à l'informatique aux fichiers
										et aux libertés que je pourrai exercer auprès des services du haut fonctionnaire de
										défense et de sécurité du ministère de la défense ;
									</dt>
									<dt>
										- que les informations recueillies font l'objet d'un traitement informatique destiné à la
										gestion de la protection du secret de la défense nationale ;
									</dt>
									<dt>
										- que les destinataires des données de ce traitement sont, en fonction de leurs
										attributions et dans la limite du besoin d'en connaître, les services du haut
										fonctionnaire de défense et de sécurité du ministère de la défense.
									</dt>
								</dl>
							</div>
						</>
					}
				/>
			</Container>
		);
	}
}
export default DomicilePrecedent;
