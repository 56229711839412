import axios from 'axios';
import conf from './configuration/api.json';
import { memoizedRefreshToken } from './refreshToken';

export let baseURL: string = conf.API_URL;

const publicPages = ['/prise-de-rdv', '/accountcreation', '/formulaire-prospect'];

const axiosApiInstance = axios.create({
	baseURL: baseURL
});

axiosApiInstance.interceptors.request.use(
	async (config) => {
		const authStorage = localStorage.getItem('auth');
		let auth = null;
		if (authStorage) {
			auth = JSON.parse(authStorage);
		}

		if (auth == null && !publicPages.find((x) => x === window.location.pathname)) {
			window.location.href = '/login?redirect_url=' + encodeURI(window.location.pathname);
		}

		// Si on est dans une page publique, il n'est pas nécessaire d'envoyer un Bearer
		config.headers = auth
			? {
					Authorization: 'Bearer ' + auth?.access_token,
					Accept: 'application/json',
					'Content-Type': 'application/json'
			  }
			: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
			  };
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

axiosApiInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const config = error?.config;

		if (error?.response?.status === 401 && !config?.sent) {
			config.sent = true;

			const result = await memoizedRefreshToken();

			if (result?.access_token) {
				config.headers = {
					...config.headers,
					authorization: `Bearer ${result?.access_token}`
				};
			}

			return axiosApiInstance(config);
		}

		return Promise.reject(error);
	}
);

export default axiosApiInstance;
