import React from 'react';

class TabHeader extends React.Component<{
	headerItem: TabHeaderItem;
}> {
	render() {
		return (
			<li role='presentation'>
				<button
					id={`tabpanel-${this.props.headerItem.id}`}
					className='fr-tabs__tab fr-background-alt--grey'
					tabIndex={this.props.headerItem.tabIndex}
					role='tab'
					aria-selected={this.props.headerItem.selected ? 'true' : 'false'}
					aria-controls={`tabpanel-${this.props.headerItem.id}-panel`}>
					{this.props.headerItem.displayName}
				</button>
			</li>
		);
	}
}

export interface TabHeaderItem {
	displayName: string;
	tabIndex: number;
	id: string;
	selected?: boolean;
}

export default TabHeader;
