import '@gouvfr/dsfr/dist/dsfr.min.css';
import '@gouvfr/dsfr/dist/utility/icons/icons.main.min.css';
import '@gouvfr/dsfr/dist/dsfr.module.min.js';
import './0-App-mobile.scss';
import './1-App-desktop.scss';
import AdtLogo from './images/army_logos/army_logo_adt.png';
import MnLogo from './images/army_logos/army_logo_mn.png';
import AaeLogo from './images/army_logos/army_logo_aae.png';
import React from 'react';
import Header, { HeaderItem } from './components/header/Header';
import { Routes, Route } from 'react-router-dom';
import InfosPersonnelles from './pages/InformationsPersonnelles/InformationsPersonnelles';
import Contact from './pages/InformationsPersonnelles/Contact';
import SituationFamiliale from './pages/InformationsPersonnelles/SituationFamiliale';
import RepresentantLegal from './pages/InformationsPersonnelles/RepresentantLegal';
import Applications from './pages/Applications';
import Footer from './components/footer/Footer';
import Link, { LinkSize } from './components/link/Link';
import BreadCrumbed from './components/BreadCrumbed';
import CompetencesMiscellaneous from './pages/Competences/Miscellaneous';
import CompetencesScholarship from './pages/Competences/Scholarship';
import ApplicationsMobile from './pages/ApplicationsMobile';
import ReferentialsService from './services/ReferentialsService';
import Competences from './pages/Competences/Competences';
import MaritalStatus from './pages/InformationsPersonnelles/MaritalStatus';
import CompetencesDiplomesFormations from './pages/Competences/DiplomesFormations';
import CompetencesExperiencesProfessionnelles from './pages/Competences/ExperiencesProfessionnelles';
import CompetencesDispositifsDefense from './pages/Competences/DispositifsDefense';
import CompetencesExperiencesMilitaires from './pages/Competences/ExperiencesMilitaires';
import CompetencesPermis from './pages/Competences/Permis';
import CompetencesLangues from './pages/Competences/Langues';
import Securite from './pages/Securité/Securite';
import Renseignements from './pages/Securité/Renseignements';
import LieuNaissance from './pages/Securité/LieuNaissance';
import Nationalite from './pages/Securité/Nationalite';
import DomicileActuel from './pages/Securité/DomicileActuel';
import DomicilePrecedent from './pages/Securité/DomicilePrecedent';
import RenseignementsFamille from './pages/Securité/RenseignementsFamille';
import EnvironnementNumerique from './pages/Securité/EnvironnementNumerique';
import Login from './pages/Login/Login';
import ForgottenPassword from './pages/Login/ForgottenPassword';
import ChangePassword from './pages/Login/ChangePassword';
import AccountCreationFunc from './pages/AccountCreation/AccountCreationFunc';
import VersioningService from './services/VersioningService';
import FormulaireProspect from './pages/FormulaireProspect/FormulaireProspect';
import RendezVousList from './pages/RendezVous/Index';
import RendezVousDetail from './pages/RendezVous/Detail';
import PostesSouhaites from './pages/PostesSouhaites/Index';
import Vitrines from './pages/Vitrines';
import MonCompte from './pages/MonCompte/MonCompte';
import { v4 as uuid } from 'uuid';
import Postuler from './pages/Postuler/Postuler';
import Page404 from './pages/Errors/404';
import Page500 from './pages/Errors/500';
import PriseDeRDVFunc from './pages/PriseDeRDV/PriseDeRDVFunc';
import Modal, { ModalSize } from './components/modal/Modal';
import Button, { ButtonSize, ButtonType } from './components/button/Button';
import QueryStringService from './services/QueryStringService';

interface IAppState {
	SpartaApplicantSHA1?: string;
	SpartaApplicantVersion?: string;
	SpartaVersion?: string;
	logo: string;
}

class App extends React.Component<{ location: Location }> {
	state: IAppState = {
		SpartaApplicantSHA1: '',
		SpartaApplicantVersion: '',
		SpartaVersion: '',
		logo: ''
	};

	constructor(props: any) {
		super(props);

		this.switchArmy = this.switchArmy.bind(this);
		this.switchArmyAndRefresh = this.switchArmyAndRefresh.bind(this);
	}

	headerItems: HeaderItem[] = [];

	async componentDidMount() {
		if (this.props.location.pathname == '/prise-de-rdv' || this.props.location.pathname == '/accountcreation') {
			QueryStringService.initialiseLocalStorageForHeader(this.props.location);
		}
		let armyId = ReferentialsService.getArmy();
		this.switchArmy(`${armyId}`);

		await VersioningService.getVersion().then((resp) => {
			this.setState({
				SpartaApplicantSHA1: resp.spartaApplicantSHA1,
				SpartaApplicantVersion: resp.spartaApplicantVersion,
				SpartaVersion: resp.spartaVersion
			});
		});
	}

	render() {
		if (!localStorage.getItem('auth')) {
			this.headerItems = [
				{
					Id: 0,
					DisplayName: 's’engager.fr',
					IconClass: 'fr-icon-arrow-left-line',
					Link: 'https://www.sengager.fr/'
				},
				{
					Id: 1,
					DisplayName: 'lamarinerecrute.fr',
					IconClass: 'fr-icon-arrow-left-line',
					Link: 'https://www.lamarinerecrute.fr/'
				},
				{
					Id: 2,
					DisplayName: 'devenir-aviateur.fr',
					IconClass: 'fr-icon-arrow-left-line',
					Link: 'https://www.devenir-aviateur.fr/'
				}
			];
		} else {
			this.headerItems = [
				{
					Id: 0,
					DisplayName: 'Mon compte',
					IconClass: 'fr-icon-user-fill',
					Link: '/mon-compte'
				},
				{
					Id: 1,
					DisplayName: 'Déconnexion',
					IconClass: 'fr-icon-logout-box-r-fill',
					Link: '#',
					ModalId: 'logoutmodal'
				}
			];
		}

		return (
			<Routes>
				<Route
					path='/postuler'
					element={<Postuler location={this.props.location} />}
				/>
				<Route
					path='/vitrines'
					element={<Vitrines location={this.props.location}></Vitrines>}
				/>
				<Route
					path='*'
					element={
						<div>
							<Header
								title_part_1='Ministère'
								title_part_2='des Armées'
								armyLogoLink={this.state.logo}
								items={this.headerItems}
								location={this.GetLocation()}></Header>
							<div id='top-anchor'></div>
							<Routes>
								{/* WARNING: Pour tout nouvel écran / chemin, vous devez l'ajouter dans l'object components/Breadcrumbed.tsx */}
								<Route
									path='/'
									element={<Applications switchArmy={this.switchArmy}></Applications>}></Route>
								<Route
									path='/mon-compte'
									element={
										<BreadCrumbed>
											<MonCompte switchArmy={this.switchArmy}></MonCompte>
										</BreadCrumbed>
									}
								/>
								<Route
									path='/infos-perso'
									element={
										<BreadCrumbed>
											<InfosPersonnelles />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/infos-perso/maritalstatus'
									element={
										<BreadCrumbed>
											<MaritalStatus />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/infos-perso/contact'
									element={
										<BreadCrumbed>
											<Contact />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/infos-perso/situationfamiliale'
									element={
										<BreadCrumbed>
											<SituationFamiliale />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/infos-perso/representantlegal'
									element={
										<BreadCrumbed>
											<RepresentantLegal />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/maritalstatus'
									element={
										<BreadCrumbed>
											<MaritalStatus />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/competences'
									element={
										<BreadCrumbed>
											<Competences />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/competences/extras'
									element={
										<BreadCrumbed>
											<CompetencesMiscellaneous />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/competences/scholarship'
									element={
										<BreadCrumbed>
											<CompetencesScholarship />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/competences/diplomes-formations'
									element={
										<BreadCrumbed>
											<CompetencesDiplomesFormations />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/competences/experiences-professionnelles'
									element={
										<BreadCrumbed>
											<CompetencesExperiencesProfessionnelles />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/competences/dispositif-defense'
									element={
										<BreadCrumbed>
											<CompetencesDispositifsDefense />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/competences/army-experience'
									element={
										<BreadCrumbed>
											<CompetencesExperiencesMilitaires />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/competences/driving-license'
									element={
										<BreadCrumbed>
											<CompetencesPermis />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/competences/languages'
									element={
										<BreadCrumbed>
											<CompetencesLangues />
										</BreadCrumbed>
									}
								/>
								<Route
									path='/securite'
									element={
										<BreadCrumbed>
											<Securite />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/securite/renseignements'
									element={
										<BreadCrumbed>
											<Renseignements />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/securite/lieu-naissance'
									element={
										<BreadCrumbed>
											<LieuNaissance />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/securite/nationalite'
									element={
										<BreadCrumbed>
											<Nationalite />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/securite/domicile-actuel'
									element={
										<BreadCrumbed>
											<DomicileActuel />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/securite/domicile-precedent'
									element={
										<BreadCrumbed>
											<DomicilePrecedent />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/securite/renseignements-famille'
									element={
										<BreadCrumbed>
											<RenseignementsFamille />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/securite/environnement-numerique'
									element={
										<BreadCrumbed>
											<EnvironnementNumerique />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/rendez-vous/'
									element={
										<BreadCrumbed>
											<RendezVousList />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/rendez-vous/detail'
									element={
										<BreadCrumbed>
											<RendezVousDetail />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/postes-souhaites'
									element={
										<BreadCrumbed>
											<PostesSouhaites />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/applications-mobile'
									element={<ApplicationsMobile />}></Route>
								<Route
									path='/login'
									element={<Login location={this.props.location} />}></Route>
								<Route
									path='/forgottenpassword'
									element={
										<BreadCrumbed>
											<ForgottenPassword />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/changepassword/'
									element={
										<BreadCrumbed>
											<ChangePassword location={this.props.location} />
										</BreadCrumbed>
									}></Route>
								<Route
									path='/accountcreation/'
									element={<AccountCreationFunc location={this.props.location} />}></Route>
								<Route
									path='/prise-de-rdv'
									element={<PriseDeRDVFunc location={this.props.location}></PriseDeRDVFunc>}></Route>
								<Route
									path='/formulaire-prospect'
									element={<FormulaireProspect location={this.props.location}></FormulaireProspect>}></Route>
								<Route
									path='*'
									element={<Page404 />}
								/>
								<Route
									path='/error-500'
									element={<Page500 />}
								/>
							</Routes>
							<div className='top-anchor-link'>
								<div className='fr-container fr-py-6w'>
									<Link
										iconClass='fr-icon-arrow-up-line'
										isIconLeft={true}
										link='#top-anchor'
										size={LinkSize.Large}
										title='Haut de la page'></Link>
								</div>
							</div>
							<Footer
								title_part_1='Ministère'
								title_part_2='des Armées'></Footer>
							<div id='versioning'>
								{this.state.SpartaApplicantSHA1 && (
									<input
										type='hidden'
										id='spartaApplicantSHA1'
										value={this.state.SpartaApplicantSHA1}></input>
								)}
								{this.state.SpartaApplicantVersion && (
									<input
										type='hidden'
										id='spartaApplicantVersion'
										value={this.state.SpartaApplicantVersion}></input>
								)}
								{this.state.SpartaVersion && (
									<input
										type='hidden'
										id='spartaVersion'
										value={this.state.SpartaVersion}></input>
								)}
							</div>
							<Modal
								title='Déconnexion'
								size={ModalSize.Large}
								id={this.headerItems[1].ModalId}
								buttons={[
									{
										DisplayName: 'Se déconnecter',
										Link: `/login?army_id=${ReferentialsService.getArmy()}`
									},
									{
										DisplayName: 'Annuler',
										Type: ButtonType.Secondary
									}
								]}
								children={
									<p>
										Vous êtes sur le point de vous déconnecter. Êtes-vous sûr de vouloir vous déconnecter ?{' '}
									</p>
								}
							/>
							<Modal
								title='Déconnexion'
								size={ModalSize.Large}
								id={this.headerItems[1].ModalId + '_copy'}
								buttons={[
									{
										DisplayName: 'Se déconnecter',
										Link: `/login?army_id=${ReferentialsService.getArmy()}`
									},
									{
										DisplayName: 'Annuler',
										Type: ButtonType.Secondary
									}
								]}
								children={
									<p>
										Vous êtes sur le point de vous déconnecter. Êtes-vous sûr de vouloir vous déconnecter ?{' '}
									</p>
								}
							/>
						</div>
					}
				/>
			</Routes>
		);
	}

	private switchArmyAndRefresh(id: string) {
		this.switchArmy(id);
		window.location.reload();
	}

	private switchArmy(id: string) {
		switch (id.toString()) {
			case '1':
				this.setState({ logo: AdtLogo });
				break;
			case '2':
				this.setState({ logo: MnLogo });
				break;
			case '3':
				this.setState({ logo: AaeLogo });
				break;
			default:
				this.setState({ logo: '' });
		}
		ReferentialsService.setArmy(+id);
	}

	private GetLocation() {
		return window.location.pathname;
	}
}

export default App;
