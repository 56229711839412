import http, { baseURL } from '../http-common';

class AppointmentService {
	private route_url = '/api/appointment/';

	async createAppointment(model: AppointmentCreateModel) {
		const getResp = await http.post<{ data: any }>(baseURL + this.route_url, model);
		return (await getResp).data;
	}

	async getFutureAppointmentList(armyId: string) {
		const getResp = await http.get<{ data: any }>(baseURL + this.route_url + 'get-future?armyId=' + armyId);
		return (await getResp).data;
	}

	async getPastAppointmentList(armyId: string) {
		const getResp = await http.get<{ data: any }>(baseURL + this.route_url + 'get-past?armyId=' + armyId);
		return (await getResp).data;
	}
}

export interface AppointmentCreateModel {
	TenantID: number;
	ApplicantID: number;
	ApplicantComment: string;
	JobCategoryId: number;
	ShowCaseWebSiteVacancyLabel: string;
	AppointmentId: number;
	Applicant: ApplicantAppointmentCreateModel;
}

export interface ApplicantAppointmentCreateModel {
	Email: string;
	BirthDate: string;
	FrenchNationality: boolean;
	BirthFirstname: string;
	PhoneNumber: string;
	BirthLastname: string;
	ArmyId: number;
	CirfaId: number;
	JobCategoryId: number;
	JobTitle: string;
	OriginID: number;
	RGPDAcceptance: boolean;
}

export interface SendAppointmentMailModel {
	TenantID: number;
	Email: string;
	AppointmentId: number;
}

export default new AppointmentService();
