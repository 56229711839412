import { baseURL } from '../../http-common';
import EnumApplicantCheckCreation from '../../enums/EnumApplicantCheckCreation';
import http from '../../http-common';
import AttachmentPublicService from './AttachmentPublicService';
import { NephosESLScopPoolDocument } from '../../sparta.api';
import { AttachmentState } from '../AttachmentService';

export enum AccountCreateStatus {
	SUCCESS,
	INVALID_INFORMATIONS,
	INTERNAL_SERVER_ERROR,
	UNKNOWN
}

export enum ProspectCreationStatus {
	SUCCESS,
	NO_CIRFA_FOUND,
	DUPLICATE,
	INTERNAL_SERVER_ERROR,
	UNKNOWN
}

export interface AccountCreateModel {
	rgpdAcceptance?: boolean;
	email?: string;
	birthDate?: string;
	frenchNationality?: boolean;
	genderId: number;
	birthFirstname?: string;
	birthLastname?: string;
	phoneNumber?: string;
	password?: string;
	passwordConfirmation?: string;
	confirmationCode?: string;
	armyId?: number;
	jobTitle?: string;
	jobCategoryId?: number;
	originId?: number;
	originURL?: string;
	cirfaId?: number;
	recaptchaResponse?: string;
	cv?: File;
	lm?: File;
	cvDocument?: NephosESLScopPoolDocument;
	lmDocument?: NephosESLScopPoolDocument;
}

class AccountPublicService {
	private routeUrl: string = '/api/public';

	async checkIfUserExist(
		email: string,
		fromAppointment: boolean
	): Promise<{ hasApplicantAccount: boolean; errorMessage?: string; hasSpartaAccount?: boolean }> {
		const getResp = http.get(baseURL + `${this.routeUrl}/applicant/verification?email=${email}`, {
			validateStatus: function (status) {
				return true;
			}
		});

		const resp = await getResp;

		switch (resp.data.status) {
			case EnumApplicantCheckCreation.VALIDATED:
				return {
					hasApplicantAccount: true,
					errorMessage: fromAppointment
						? "Cette adresse électronique est déjà associée à un compte existant. Veuillez vous connectez <a href='/login' rel='noopener'>ici</a>. pour prendre un rendez-vous."
						: "Cet identifiant est déjà associé à un compte existant. Veuillez vous connecter <a href='/login' rel='noopener'>ici</a>."
				};
			case EnumApplicantCheckCreation.NOT_VALIDATED:
				return { hasApplicantAccount: false, hasSpartaAccount: true };
			case EnumApplicantCheckCreation.APPLICANT_NOT_FOUND:
				return { hasApplicantAccount: false };
			default:
				return { hasApplicantAccount: true, errorMessage: 'Impossible de vérifier cet email' };
		}
	}

	async createAccount(model: AccountCreateModel): Promise<AccountCreateStatus> {
		var attachmentType = undefined;
		if (model.cv) {
			attachmentType = 1;
			model.cvDocument = {
				id : await AttachmentPublicService.post(model.cv, model.confirmationCode!, model.email!, attachmentType),
				validationState: AttachmentState.ADDED,
			};
		}
		if (model.lm) {
			attachmentType = 2;
			model.lmDocument = {
				id : await AttachmentPublicService.post(model.lm, model.confirmationCode!, model.email!, attachmentType),
				validationState: AttachmentState.ADDED,
			};
		}
			
		const getResp = http.post<AccountCreateModel>(baseURL + `${this.routeUrl}/users`, model, {
			validateStatus: function (status) {
				return true;
			}
		});

		const resp = await getResp;

		switch (resp.status) {
			case 200:
				return AccountCreateStatus.SUCCESS;
			case 400:
				return AccountCreateStatus.INVALID_INFORMATIONS;
			default:
				return AccountCreateStatus.INTERNAL_SERVER_ERROR;
		}
	}

	async createProspect(model: any): Promise<ProspectCreationStatus> {
		const getResp = http.post<any, { status: number; data: { errors: [{ code: number }] } }>(
			baseURL + `${this.routeUrl}/prospect`,
			model,
			{
				validateStatus: function (status) {
					return true;
				}
			}
		);

		const resp = await getResp;

		if (resp.status === 200) return ProspectCreationStatus.SUCCESS;

		switch (resp.data.errors[0].code) {
			case 105:
				return ProspectCreationStatus.DUPLICATE;
			case 109:
				return ProspectCreationStatus.NO_CIRFA_FOUND;
			default:
				return ProspectCreationStatus.INTERNAL_SERVER_ERROR;
		}
	}
}

export default new AccountPublicService();
