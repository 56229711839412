/* Composant pour l'affichage/gestion d'une pièce jointe */

import React from 'react';
import filesize from 'filesize';
import Link, { LinkSize } from '../link/Link';

import './0-Attachment-mobile.scss';
import attachmentService, { AttachmentResponse, AttachmentState } from '../../services/AttachmentService';

interface IAttachmentState {
	size?: number;
	filename?: string;
	guid?: string;
}

class Attachment extends React.Component<{
	guid?: string;
	validationState?: AttachmentState;
	isDisabled?: boolean;
	isUploadInProgress?: boolean;
	isDeleteInProgress?: boolean;
	publicFile?: File;
	onDelete?: () => void;
	onEdit?: () => void;
}> {
	state: IAttachmentState = { guid: this.props.guid };

	constructor(props: any) {
		super(props);
		this.state.guid = this.props.guid;
		this.getAttachment(this.props.guid);
	}

	componentDidUpdate(prevProps: any) {
		if (this.props.guid != prevProps.guid && this.props.guid !== '00000000-0000-0000-0000-000000000000') {
			this.getAttachment(this.props.guid);
		}
	}

	getAttachment(guid?: string) {
		if (guid && guid !== 'PUBLIC') {
			attachmentService.get(guid).then((resp: AttachmentResponse) => {
				this.setState({
					size: resp.size,
					filename: resp.filename
				});
			});
		}
	}

	getAttachmentExtension = (filename?: string) => {
		if (!filename) return null;
		const splitted = /(?:\.([^.]+))?$/.exec(filename);
		return splitted && splitted!.length > 0 ? splitted[1] : null;
	};

	getHumanReadableSize = (size?: number) => {
		if (!size) return null;
		return filesize(size);
	};

	editAttachment() {
		if (this.props.onEdit) this.props.onEdit();
	}

	deleteAttachment() {
		if (this.props.onDelete) this.props.onDelete();
	}

	setIsDisabled(isDisabled: boolean, callback?: () => void) {
		this.setState({ isDisabled: isDisabled }, callback);
	}

	setIsUploadInProgress(isUploadInProgress: boolean, callback?: () => void) {
		this.setState({ isUploadInProgress: isUploadInProgress }, callback);
	}

	setIsDeleteInProgress(isDeleteInProgress: boolean, callback?: () => void) {
		this.setState({ isDeleteInProgress: isDeleteInProgress }, callback);
	}

	render() {
		return (
			((this.props.guid &&
			this.props.guid === 'PUBLIC' &&
			this.props.publicFile) 
			||
			(this.props.guid &&
			this.props.guid != '00000000-0000-0000-0000-000000000000' &&
			this.props.guid != 'PUBLIC' &&
			!this.props.isUploadInProgress &&
			!this.props.isDeleteInProgress)) && (
				<div className='attachment-item'>
					<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
						<div className='fr-col-12 fr-col-lg-6'>
							<div className='fr-download'>
								<p className='fr-download__attachment_name'>
									<a
										href='#'
										className='fr-download__link'
										onClick={() => {
											if (this.props.guid && this.props.guid !== 'PUBLIC') attachmentService.download(this.props.guid);
										}}>
										<span>{this.state.filename ?? this.props.publicFile?.name}</span>
										<span className='fr-download__detail'>
											{this.getAttachmentExtension(this.state.filename ?? this.props.publicFile?.name)} -{' '}
											{this.getHumanReadableSize(this.state.size ?? this.props.publicFile?.size)}
										</span>
										{this.props.validationState === AttachmentState.ADDED && (
											<span className='fr-download__detail fr-text--sm attachment-state'>Ajoutée</span>
										)}
										{this.props.validationState === AttachmentState.VALIDATED && (
											<span className='fr-download__detail fr-text--sm attachment-state'>
												Validée
												<span
													className='fr-icon-success-line fr-fi--sm'
													aria-hidden='true'></span>
											</span>
										)}
										{!this.props.validationState || this.props.validationState === AttachmentState.WAITING_FOR_VALIDATION && (
											<span className='fr-download__detail fr-text--sm attachment-state'>
												En attente de validation
												<span
													className='fr-icon-time-line fr-fi--sm'
													aria-hidden='true'></span>
											</span>
										)}
									</a>
								</p>
							</div>
						</div>
						<div className='fr-col-12 fr-col-lg-6 attachment-actions fr-align-right'>
							<Link
								className='naked'
								title='Modifier'
								size={LinkSize.Medium}
								link='#'
								onClick={this.editAttachment.bind(this)}
								isdisabled={this.props.isDisabled}
							/>
							<Link
								className='naked'
								title='Supprimer'
								size={LinkSize.Medium}
								link='#'
								onClick={this.deleteAttachment.bind(this)}
								isdisabled={this.props.isDisabled}
							/>
						</div>
					</div>
				</div>
			)
		);
	}
}

export default Attachment;
