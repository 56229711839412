import { ReferentialItem, ReferentialQuery, ReferentialType } from '../ReferentialsService';
import http, { baseURL } from '../../http-common';

class ReferentialsPublicService {
	private routeUrl: string = '/api/public/referentials';
	
	private _genders?: ReferentialItem[] | null = null;
	private _baseQuery: ReferentialQuery = {
		RankId: 1,
		ShowActivesOnly: true,
		PageNumber: 1,
		PageSize: 5
	};


	public getPublicPaginatedReferentialUrl(type: ReferentialType): string {
		switch (type) {
			case ReferentialType.JOB_CATEGORY_PUBLIC:
				return `${this.routeUrl}/jobCategories/`;
			case ReferentialType.ARMY_PUBLIC:
				return `${this.routeUrl}/armies/`;
			case ReferentialType.DEGREE_PUBLIC:
				return `${this.routeUrl}/degrees/`;
			case ReferentialType.BUSINESS_DOMAIN_PUBLIC:
				return `${this.routeUrl}/businessDomains/`;
			default:
				return `${this.routeUrl}`;
		}
	}

	public async getGenders() {
		if (this._genders) {
			return this._genders;
		}
		const getResp = http.post<{ items: ReferentialItem[] }[]>(this.routeUrl + '/genders', this._baseQuery);
		this._genders = (await getResp).data[0].items;
		return this._genders;
	}

	public async getJobCategories(armyId: string) {
		const getResp = await http.get(
			baseURL + this.getPublicPaginatedReferentialUrl(ReferentialType.JOB_CATEGORY_PUBLIC) + 
			'?ArmyId=' + armyId + '&RankId=1&ShowActivesOnly=true'
		);
		return (await getResp).data;
	}
}

export default new ReferentialsPublicService();

