import React from 'react';

class Footer extends React.Component<{ title_part_1: string; title_part_2: string }, {}> {
	render() {
		return (
			<footer
				className='fr-footer'
				role='contentinfo'
				id='footer'>
				<div className='fr-container'>
					<div className='fr-footer__body'>
						<div className='fr-footer__brand fr-enlarge-link'>
							<p className='fr-logo'>
								{this.props.title_part_1}
								<br />
								{this.props.title_part_2}
							</p>
						</div>
						<div className='fr-footer__content'>
							<ul className='fr-footer__content-list'>
								<li className='fr-footer__content-item'>
									<a
										className='fr-footer__content-link'
										target='_blank'
										href='https://legifrance.gouv.fr'>
										legifrance.gouv.fr
									</a>
								</li>
								<li className='fr-footer__content-item'>
									<a
										className='fr-footer__content-link'
										target='_blank'
										href='https://gouvernement.fr'>
										gouvernement.fr
									</a>
								</li>
								<li className='fr-footer__content-item'>
									<a
										className='fr-footer__content-link'
										target='_blank'
										href='https://service-public.fr'>
										service-public.fr
									</a>
								</li>
								<li className='fr-footer__content-item'>
									<a
										className='fr-footer__content-link'
										target='_blank'
										href='https://data.gouv.fr'>
										data.gouv.fr
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className='fr-footer__bottom'>
						<ul className='fr-footer__bottom-list'>
							<li className='fr-footer__bottom-item'>
								<a
									className='fr-footer__bottom-link'
									href='#'>
									Plan du site
								</a>
							</li>
							<li className='fr-footer__bottom-item'>
								<a
									className='fr-footer__bottom-link'
									href='#'>
									Accessibilité
								</a>
							</li>
							<li className='fr-footer__bottom-item'>
								<a
									className='fr-footer__bottom-link'
									href='#'>
									Mentions légales
								</a>
							</li>
							<li className='fr-footer__bottom-item'>
								<a
									className='fr-footer__bottom-link'
									href='#'>
									Données personnelles
								</a>
							</li>
							<li className='fr-footer__bottom-item'>
								<a
									className='fr-footer__bottom-link'
									href='#'>
									Gestion des cookies
								</a>
							</li>
						</ul>
						<div className='fr-footer__bottom-copy'>
							<p>
								Sauf mention contraire, tous les contenus de ce site sont sous{' '}
								<a
									href='https://github.com/etalab/licence-ouverte/blob/master/LO.md'
									target='_blank'>
									licence etalab-2.0
								</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
