import React from 'react';
import ConvertHTML from '../tools/ConvertHTML';

import './0-CodeInput-mobile.scss';

interface ICodeInputState {
	values: string[];
}

class CodeInput extends React.Component<{
	onFullFilled?: (input: string) => void;
	onChange?: (input: string) => void;
	length: number;
	allowCharacter: boolean;
	isError?: boolean;
	errorMessage?: string;
	isDisabled?: boolean;
}> {
	state: ICodeInputState = {
		values: new Array<string>(this.props.length).fill('')
	};

	inputs: string[] = new Array<string>(this.props.length).fill('');

	inputRefs: Array<React.RefObject<HTMLInputElement>> = new Array<React.RefObject<HTMLInputElement>>(this.props.length);

	fullFilled() {
		const value = this.state.values.join('');
		if (value.length < this.props.length) return;

		if (this.props.onFullFilled) this.props.onFullFilled(value);
	}

	constructor(props: any) {
		super(props);
		for (var i = 0; i < this.props.length; i++) {
			this.inputRefs[i] = React.createRef<HTMLInputElement>();
		}
	}

	render() {
		return (
			<div className='codeinput-container'>
				<div
					className='codeinput-inputs'
					style={{ border: 'none' }}>
					{this.inputs.map((input, i) => {
						return (
							<label
								className='fr-label'
								htmlFor={'codeinput-input_' + i}>
								<span style={{ display: 'none' }}>{'Code de vérification numéro ' + (i + 1)}</span>
								<input
									id={'codeinput-input_' + i}
									key={i}
									ref={this.inputRefs[i]}
									className={'codeinput-input ' + (this.props.isError === true ? 'fr-input--error' : '')}
									value={this.state.values[i]}
									maxLength={1}
									disabled={this.props.isDisabled}
									type='text'
									onPaste={(e) => {
										e.preventDefault();
										e.stopPropagation();

										const clipboard = e.clipboardData.getData('text');
										let values = this.state.values;
										var j = i;

										for (var k = 0; k < clipboard.length && j < this.props.length; k++) {
											if (/[^0-9]/.test(clipboard[k])) break;
											values[j] = clipboard[k];
											j++;
										}

										if (j < this.props.length) this.inputRefs[j].current?.focus();

										this.setState({ values: values }, () => {
											if (this.props.onChange) this.props.onChange(this.state.values.join(''));
											this.fullFilled();
										});
									}}
									onChange={(e) => {
										if (e.currentTarget.value.length > 1 || /[^0-9]/.test(e.currentTarget.value)) return;

										let values = this.state.values;
										values[i] = e.currentTarget.value;
										this.setState({ values: values }, () => {
											if (i < this.props.length - 1) {
												this.inputRefs[i + 1].current?.focus();
											}
											if (this.props.onChange) this.props.onChange(this.state.values.join(''));
											this.fullFilled();
										});
									}}
								/>
							</label>
						);
					})}
				</div>

				{this.props.errorMessage && this.props.isError === true && (
					<div className='codeinput-error'>
						<p className='fr-error-text'>{this.props.errorMessage}</p>
					</div>
				)}
			</div>
		);
	}
}

export default CodeInput;
