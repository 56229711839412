import { CardTag } from '../components/card/Card';
import { LabelType } from '../components/label/Label';
import http from '../http-common';

class GroupingValidationService {
	private _baseUrl: string = '/api/groupingvalidation/';

	public async getGroupingValidationsList(armyId: string, parentGroupingType?: number) {
		const getResp = http.get<
			{ isValidated: boolean; name: string; type: number; isComplete: boolean; armyId: string; isVisible: boolean }[]
		>(this._baseUrl + `?armyId=${armyId}&parentGroupingType=${parentGroupingType}`);
		const groupings = (await getResp).data;
		return groupings;
	}

	public async getGroupingValidation(armyId: string, groupingTypeId: number) {
		const getResp = http.get<{ isValidated: boolean; requiredFieldTypes: any[]; optionalFieldTypes: any[] }>(
			this._baseUrl + `?armyId=${armyId}&groupingTypeId=${groupingTypeId}`
		);
		const grouping = (await getResp).data;
		return grouping;
	}

	public async addGroupingValidation(groupingIds: number[], armyId: string) {
		await http.put(this._baseUrl, { groupingIds: groupingIds, armyId: armyId, isValidated: false });
	}

	public async updateGroupingValidation(armyId: string, isComplete: boolean, groupingType: number, applicantId: number) {
		await http.post(this._baseUrl, {
			armyId: armyId,
			isComplete: isComplete,
			groupingType: groupingType,
			applicantId: applicantId
		});
	}

	public getCardTag(grouping: any): CardTag {
		var displayName = 'INCOMPLET';
		var labelType: LabelType = LabelType.Error;

		if (grouping.isComplete) {
			displayName = 'COMPLET';
			labelType = LabelType.Info;

			if (grouping.isValidated) {
				displayName = 'VALIDÉ';
				labelType = LabelType.Success;
			}
		} else if (grouping.isValidated) {
			// le groupement est validé malgrès qu'il soit incomplet par le CER
			displayName = 'VALIDÉ';
			labelType = LabelType.Success;
		}

		return { DisplayName: displayName, Type: labelType };
	}

	public getParentCardTagAndDesc(
		parentGroupingType: number,
		groupings: any[],
		isAdult: boolean
	): { cardTag: CardTag; description: string } {
		var displayName = 'INCOMPLET';
		var labelType: LabelType = LabelType.Error;
		var description = '';

		const childrenGroupings = isAdult
			? groupings.filter(
					(x) => x.name != 'Représentant légal' && x.type > parentGroupingType && x.type < parentGroupingType + 100
			  )
			: groupings.filter((x) => x.type > parentGroupingType && x.type < parentGroupingType + 100);

		if (childrenGroupings.every((x) => x.isComplete)) {
			displayName = 'COMPLET';
			labelType = LabelType.Info;
			description = 'En cours de validation...';
		}

		if (childrenGroupings.every((x) => x.isValidated)) {
			displayName = 'VALIDÉ';
			labelType = LabelType.Success;
			description = '';
		}

		return { cardTag: { DisplayName: displayName, Type: labelType }, description: description };
	}
}

export default new GroupingValidationService();
