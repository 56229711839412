enum EnumApplicantInputArmyType {
	ETAT_CIVIL_SEXE = 100,
	ETAT_CIVIL_NOM,
	ETAT_CIVIL_PRENOM,
	ETAT_CIVIL_DATE_DE_NAISSANCE,
	ETAT_CIVIL_NATIONALITE_ACTUELLE,
	ETAT_CIVIL_PAYS_DE_NAISSANCE,
	ETAT_CIVIL_CODE_POSTAL_DE_LA_COMMUNE_DE_NAISSANCE,
	ETAT_CIVIL_COMMUNE_DE_NAISSANCE,
	ETAT_CIVIL_CARTE_D_IDENTITE_NATIONALE_OU_PASSEPORT,
	ETAT_CIVIL_EXTRAIT_D_ACTE_DE_NAISSANCE,
	ETAT_CIVIL_ATTESTATION_DE_RECENSEMENT,
	ETAT_CIVIL_CV_DU_CANDIDAT,
	ETAT_CIVIL_LETTRE_DE_MOTIVATION,
	ETAT_CIVIL_PHOTO_D_IDENTITE,

	CONTACT_ADRESSE = 200,
	CONTACT_COMPLEMENT_D_ADRESSE,
	CONTACT_CODE_POSTAL,
	CONTACT_VILLE_OU_COMMUNE,
	CONTACT_PAYS_DE_RESIDENCE,
	CONTACT_NUMERO_DE_TELEPHONE,
	CONTACT_AUTRE_NUMERO,
	CONTACT_ADRESSE_ELECTRONIQUE,

	SITUATION_FAMILIALE_SITATION_MATRIMONIALE = 300,
	SITUATION_FAMILIALE_NOMBRE_D_ENFANTS,

	REPRESENTANT_LEGAL_QUALITE_REPRESENTANT_LEGAL = 400,
	REPRESENTANT_LEGAL_NOM_DU_REPRESENTANT_LEGAL,
	REPRESENTANT_LEGAL_PRENOM_DU_REPRESENTANT_LEGAL,
	REPRESENTANT_LEGAL_ADRESSE_DU_REPRESENTANT_LEGAL,
	REPRESENTANT_LEGAL_COMPLEMENT_D_ADRESSE_DU_REPRESENTANT_LEGAL,
	REPRESENTANT_LEGAL_CODE_POSTAL_DU_REPRESENTANT_LEGAL,
	REPRESENTANT_LEGAL_VILLE_OU_COMMUNE_DU_REPRESENTANT_LEGAL,
	REPRESENTANT_LEGAL_PAYS_DE_RESIDENCE_DU_REPRESENTANT_LEGAL,
	REPRESENTANT_LEGAL_NUMERO_DE_TELEPHONE_DU_REPRESENTANT_LEGAL,
	REPRESENTANT_LEGAL_ADRESSE_ELECTRONIQUE_DU_REPRESENTANT_LEGAL,
	REPRESENTANT_LEGAL_CONSENTEMENT_DU_REPRESENTANT_LEGAL,

	EXPERIENCES_PROFESSIONNELLES_SITUATION_VIS_A_VIS_DE_L_EMPLOI = 600,
	EXPERIENCES_PROFESSIONNELLES_DATE_DE_DISPONIBILITE_AU_RECRUTEMENT,

	DISPOSITIF_DEFENSE_AVEZ_VOUS_FAIT_UNE_PREPARATION_MILITAIRE = 700,

	EXPERIENCES_MILITAIRES_AVEZ_VOUS_DEJA_UNE_EXPERIENCE_MILITAIRE = 800,

	PERMIS_AVEZ_VOUS_UN_PERMIS = 900,

	DIVERS_AVEZ_VOUS_UN_BREVET_DE_NATATION = 1000,

	DOMICILE_ACTUEL_DEPUIS_LE = 1100,

	DOMICILE_PRECEDENT_J_AI_VECU_DANS_UN_DOMICILE_PRECEDENT = 1200,
	DOMICILE_PRECEDENT_DU,
	DOMICILE_PRECEDENT_AU,
	DOMICILE_PRECEDENT_ADRESSE,
	DOMICILE_PRECEDENT_COMPLEMENT_D_ADRESSE,
	DOMICILE_PRECEDENT_CODE_POSTAL,
	DOMICILE_PRECEDENT_VILLE_OU_COMMUNE,
	DOMICILE_PRECEDENT_PAYS_DE_RESIDENCE,

	ENVIRONNEMENT_NUMERIQUE_UTILISEZ_VOUS_LES_RESEAUX_SOCIAUX = 1300,
	ENVIRONNEMENT_NUMERIQUE_UTILISEZ_VOUS_D_AUTRES_NUMEROS_OU_ADRESSES_ELECTRONIQUES,

	SCOLARITE_AVEZ_VOUS_UNE_CERTIFICATION_PIX = 1400
}

export default EnumApplicantInputArmyType;
