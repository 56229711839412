class VersioningService {
	async getVersion() {
		let sha1value = await this.getSha1FromLocalFile();

		let appVersion: IVersioningModel = {
			spartaApplicantSHA1: sha1value!,
			spartaApplicantVersion: process.env.REACT_APP_VERSION,
			spartaVersion: require('./../../package.json').spartaVersion
		};

		return appVersion;
	}

	async getSha1FromLocalFile() {
		const versionFileName = process.env.REACT_APP_VERSION_FILENAME;

		const result = await fetch(versionFileName!)
			.then((response) => {
				if (response.ok) {
					return response.arrayBuffer();
				} else {
					return Promise.reject('wrong or missing file');
				}
			})
			.then((buffer) => {
				let decoder = new TextDecoder('utf-16le');
				let text = decoder.decode(buffer);

				if (text && text?.length <= 40) return text;
			})
			.catch(() => {});

		return result;
	}
}

export interface IVersioningModel {
	spartaApplicantSHA1?: string;
	spartaApplicantVersion?: string;
	spartaVersion?: string;
}

export default new VersioningService();
