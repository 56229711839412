import { baseURL } from '../../http-common';
import axios from 'axios';

export enum EmailVerificationResponseStatus {
	SUCCESS,
	NOTFOUND,
	INTERNAL_SERVER_ERROR,
	UNKNOWN
}

class EmailVerificationPublicService {
	private routeUrl: string = '/api/public/emailverification';

	async verifyEmail(email: string): Promise<EmailVerificationResponseStatus> {
		const getResp = axios.get(baseURL + `${this.routeUrl}?email=${email}`, {
			validateStatus: function (status) {
				return true;
			}
		});

		const resp = await getResp;

		switch (resp.status) {
			case 200:
				return EmailVerificationResponseStatus.SUCCESS;
			default:
				return EmailVerificationResponseStatus.INTERNAL_SERVER_ERROR;
		}
	}

	async verifyCode(code: string, email: string): Promise<EmailVerificationResponseStatus> {
		const getResp = axios.post<{ password: string; token: string }>(
			baseURL + `${this.routeUrl}`,
			{ email: email, confirmationCode: code },
			{
				validateStatus: function (status) {
					return true;
				}
			}
		);

		const resp = await getResp;

		switch (resp.status) {
			case 200:
				return EmailVerificationResponseStatus.SUCCESS;
			case 400:
				return EmailVerificationResponseStatus.NOTFOUND;
			default:
				return EmailVerificationResponseStatus.INTERNAL_SERVER_ERROR;
		}
	}
}

export default new EmailVerificationPublicService();
