import React from 'react';
import { MobileView, BrowserView, isMobile } from 'react-device-detect';
import FormContainer from '../form-container/FormContainer';
import './0-Tabs-mobile.scss';
import './1-Tabs-desktop.scss';
import TabHeader, { TabHeaderItem } from './TabHeader';

class Tabs extends React.Component<
	{
		items: TabHeaderItem[];
		className?: string;
		children: React.ReactNode;
		mobileOnly?: boolean;
		isFormContainer?: boolean;
	},
	{}
> {
	render() {
		const tabHeaders = this.props.items.map((x) => {
			return (
				<TabHeader
					key={x.id}
					headerItem={x}></TabHeader>
			);
		});

		if (isMobile || !this.props.mobileOnly) {
			return (
				<div className={'fr-tabs ' + (this.props.mobileOnly === false ? ' ' : ' tabs-mobile ') + this.props.className}>
					<ul
						className='fr-tabs__list'
						role='tablist'
						aria-label=''>
						{tabHeaders}
					</ul>
					{this.props.children}
				</div>
			);
		} else if (this.props.isFormContainer) {
			return <FormContainer>{this.props.children}</FormContainer>;
		} else {
			return this.props.children;
		}
	}
}

export default Tabs;
