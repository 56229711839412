/* Composant permettant de contenir des composants Card et de les afficher correctement */

import React from 'react';
import Card from '../card/Card';

import './CardContainer.scss';
import './0-CardContainer-mobile.scss';
import './1-CardContainer-desktop.scss';

class CardContainer extends React.Component<{ children: React.ReactNode }> {
	render() {
		const numberOfElemClass = React.Children.count(this.props.children) % 3 === 0 ? 'row-3-elem' : 'row-4-elem';
		return <div className={`card-container ${numberOfElemClass}`}>{this.props.children}</div>;
	}
}

export default CardContainer;
