import axios from 'axios';
import http, { baseURL } from '../http-common';
import ReferentialsPublicService  from './public/ReferentialsPublicService'

export enum ReferentialType {
	ARMY,
	ARMY_PUBLIC,
	GENDER,
	LEGAL_QUALITY,
	MARITAL_STATUS,
	CITY,
	NATION,
	NATIONALITY,
	ZIPCODE,
	GROUPING,
	SOCIAL_NETWORK_USE_FREQUENCY,
	PROFESSIONAL_SITUATION,
	CONTRACT_TYPE,
	MILITARY_PREPARATION_TYPE,
	MENTION,
	MILITARY_CONTRACT_TYPE,
	DRIVING_LICENCE_TYPE,
	LANGUAGE,
	LANGUAGE_LEVEL,
	DEGREE,
	JOB_CATEGORY_PUBLIC,
	DEGREE_PUBLIC,
	BUSINESS_DOMAIN_PUBLIC
}

class ReferentialsService {
	private _baseUrl: string = '/api/referentials/';
	private _baseQuery: ReferentialQuery = {
		RankId: 1,
		ShowActivesOnly: true,
		PageNumber: 1,
		PageSize: 5
	};
	private _baseArmySpecificQuery: ReferentialArmySpecificQuery = {
		RankId: 1,
		ShowActivesOnly: true,
		ArmyId: +(localStorage.getItem('armyId') || 1),
		PageNumber: 1,
		PageSize: 5
	};

	private _armies?: ReferentialItem[] | null;
	private _availableArmies?: ReferentialItem[] | null;
	private _groupings?: ReferentialItem[] | null;
	private _nations?: NationItem[] | null;
	private _nationalities?: NationItem[] | null;
	private _cities?: ReferentialItem[] | null;
	private _maritalStatuses?: ReferentialItem[] | null;
	private _legalQualities?: ReferentialItem[] | null;
	private _degrees?: ReferentialItem[] | null;
	private _years?: ReferentialItem[] | null;
	private _jobCategories?: ReferentialItem[] | null;

	constructor() {
		this._armies = null;
		this._availableArmies = null;
		this._groupings = null;
		this._nations = null;
		this._nationalities = null;
		this._cities = null;
		this._maritalStatuses = null;
		this._legalQualities = null;
		this._degrees = null;
		this._years = null;
		this._jobCategories = null;
	}

	public getCountryIdFrance() {
		return 27;
	}

	public setArmy(id: number) {
		localStorage.setItem('armyId', `${id}`);
	}

	public getArmy(): number | undefined {
		const armyId = localStorage.getItem('armyId');
		if (armyId) return +armyId;
		return undefined;
	}

	public changeArmyName(name: string) {
		switch (name) {
			case 'Armée de Terre':
				return 'AdT';
			case 'Marine Nationale':
				return 'MN';
			case "Armée de l'Air et de l'Espace":
				return 'AAE';
			case 'Service de Santé des Armées':
				return 'SSA';
			case 'Légion Étrangère':
				return 'LE';
			default:
				return name;
		}
	}

	// Plus utilisée ?
	public getJobCategory(): number | undefined {
		const jobCategoryId = localStorage.getItem('jobCategoryId');
		if (jobCategoryId) return +jobCategoryId;
		return undefined;
	}

	public async getArmies() {
		if (this._armies) {
			return this._armies;
		}
		const getResp = http.post<{ items: ReferentialItem[] }[]>(this._baseUrl + 'armies', this._baseQuery);
		this._armies = (await getResp).data[0].items;
		return this._armies;
	}

	public async getAvailableArmies() {
		const getResp = http.post<{ items: ReferentialItem[] }[]>(this._baseUrl + 'armies', {
			rankId: 1,
			showActivesOnly: true,
			ShowAvailableArmies: true
		});
		this._availableArmies = (await getResp).data[0].items;
		return this._availableArmies;
	}

	public async getGroupings() {
		if (this._groupings) {
			return this._groupings;
		}
		const getResp = http.post<{ items: ReferentialItem[] }[]>(this._baseUrl + 'groupings', this._baseArmySpecificQuery);
		this._groupings = (await getResp).data[0].items;
		return this._groupings;
	}

	public async getMaritalStatuses() {
		if (this._maritalStatuses) {
			return this._maritalStatuses;
		}
		const getResp = http.post<{ items: ReferentialItem[] }[]>(this._baseUrl + 'maritalStatuses', this._baseQuery);
		this._maritalStatuses = (await getResp).data[0].items;
		return this._maritalStatuses;
	}

	public async getLegalQualities() {
		if (this._legalQualities) {
			return this._legalQualities;
		}
		const getResp = http.post<{ items: ReferentialItem[] }[]>(this._baseUrl + 'legalQualities', this._baseQuery);
		this._legalQualities = (await getResp).data[0].items;
		return this._legalQualities;
	}

	// Plus utilisée ?
	public async getJobCategories() {
		if (this._jobCategories) {
			return this._jobCategories;
		}
		const getResp = axios.post<{ items: ReferentialItem[] }[]>(baseURL + '/api/referentials/jobCategories', {
			armyId: this.getArmy(),
			rankId: 1,
			showActivesOnly: true
		});
		this._jobCategories = (await getResp).data[0].items;
		return this._jobCategories;
	}

	public async getNations(getNationalities: boolean): Promise<NationItem[]> {
		if (this._nations && !getNationalities) {
			return this._nations;
		} else if (this._nationalities && getNationalities) {
			return this._nationalities;
		}
		const getResp = http.get<{ items: NationItem[] }>(
			this._baseUrl + 'byname/' + (getNationalities ? 'nationalities' : 'nations'),
			{
				params: {
					pageNumber: 1,
					pageSize: 50,
					armyId: this.getArmy(),
					rankId: 1,
					showActivesOnly: true
				}
			}
		);
		var ret;
		if (!getNationalities) {
			this._nations = (await getResp).data.items;
			ret = this._nations;
		} else {
			this._nationalities = (await getResp).data.items;
			ret = this._nationalities;
		}
		return ret;
	}

	public async getNation(id: number): Promise<NationItem> {
		const getResp = http.get<NationItem>(this._baseUrl + 'nations?id=' + id);
		return (await getResp).data;
	}

	public async getPaginatedReferential(type: ReferentialType, query: ReferentialArmySpecificQuery) {
		var url = this._baseUrl + 'byname/';
		switch (type) {
			case ReferentialType.ARMY:
				url += 'armies';
				break;
			case ReferentialType.GENDER:
				url += 'genders';
				break;
			case ReferentialType.LEGAL_QUALITY:
				url += 'legalQualities';
				break;
			case ReferentialType.CITY:
				url += 'cities';
				break;
			case ReferentialType.MARITAL_STATUS:
				url += 'maritalStatuses';
				break;
			case ReferentialType.GROUPING:
				url += 'groupings';
				break;
			case ReferentialType.NATION:
				url += 'nations';
				break;
			case ReferentialType.NATIONALITY:
				url += 'nationalities';
				break;
			case ReferentialType.ZIPCODE:
				url += 'zipcodes';
				break;
			case ReferentialType.SOCIAL_NETWORK_USE_FREQUENCY:
				url += 'socialNetworkUseFrequencies';
				break;
			case ReferentialType.PROFESSIONAL_SITUATION:
				url += 'professionalSituations';
				break;
			case ReferentialType.CONTRACT_TYPE:
				url += 'contractTypes';
				break;
			case ReferentialType.MILITARY_PREPARATION_TYPE:
				url += 'militaryPreparationTypes';
				break;
			case ReferentialType.MENTION:
				url += 'mentions';
				break;
			case ReferentialType.MILITARY_CONTRACT_TYPE:
				url += 'militaryContractTypes';
				break;
			case ReferentialType.DRIVING_LICENCE_TYPE:
				url += 'drivingLicenceTypes';
				break;
			case ReferentialType.LANGUAGE:
				url += 'languages';
				break;
			case ReferentialType.LANGUAGE_LEVEL:
				url += 'languageLevels';
				break;
			case ReferentialType.DEGREE:
				url += 'degrees';
				break;
			default:
				url = ReferentialsPublicService.getPublicPaginatedReferentialUrl(type);
				break;
		}

		query.ArmyId = this.getArmy();

		const getResp = await http.get(url, {
			params: query
		});

		return getResp.data as ReferentialPaginatedResult;
	}

	public async getReferentialArmy() {
		return this.getReferential(ReferentialType.ARMY_PUBLIC, +(this.getArmy() || '')).then((x) => {
			// A changer c'est moche (peut être mettre en base)
			var resp: any = { ...x.data };
			resp.name = (resp.name as string).toLowerCase();
			if (resp.name[0] === 'a') {
				resp.name = "l'" + resp.name;
			} else {
				resp.name = 'la ' + resp.name;
			}

			// A changer c'est moche (peut être mettre en base)
			switch (this.getArmy()) {
				case 1:
					resp.website = 'sengager.fr';
					break;
				case 2:
					resp.website = 'lamarinerecrute.fr';
					break;
				case 3:
					resp.website = 'devenir-aviateur.fr';
					break;
			}

			return resp;
		});
	}

	public async getReferential(type: ReferentialType, id: number, value?: string) {
		try {
			var url = this._baseUrl + 'byname/';
			switch (type) {
				case ReferentialType.ARMY:
					url += 'armies/' + id;
					break;
				case ReferentialType.GENDER:
					url += 'genders/' + id;
					break;
				case ReferentialType.LEGAL_QUALITY:
					url += 'legalQualities/' + id;
					break;
				case ReferentialType.CITY:
					url += 'cities/' + id;
					break;
				case ReferentialType.MARITAL_STATUS:
					url += 'maritalStatuses/' + id;
					break;
				case ReferentialType.GROUPING:
					url += 'groupings/' + id;
					break;
				case ReferentialType.NATION:
					url += 'nations/' + id;
					break;
				case ReferentialType.NATIONALITY:
					url += 'nationalities/' + id;
					break;
				case ReferentialType.ZIPCODE:
					url += 'zipcodes/' + id;
					break;
				case ReferentialType.SOCIAL_NETWORK_USE_FREQUENCY:
					url += 'socialNetworkUseFrequencies/' + id;
					break;
				case ReferentialType.PROFESSIONAL_SITUATION:
					url += 'professionalSituations/' + id;
					break;
				case ReferentialType.CONTRACT_TYPE:
					url += 'contractTypes/' + id;
					break;
				case ReferentialType.MILITARY_PREPARATION_TYPE:
					url += 'militaryPreparationTypes/' + id;
					break;
				case ReferentialType.MENTION:
					url += 'mentions/' + id;
					break;
				case ReferentialType.MILITARY_CONTRACT_TYPE:
					url += 'militaryContractTypes/' + id;
					break;
				case ReferentialType.DRIVING_LICENCE_TYPE:
					url += 'drivingLicenceTypes/' + id;
					break;
				case ReferentialType.LANGUAGE:
					url += 'languages/' + id + '/' + value;
					break;
				case ReferentialType.LANGUAGE_LEVEL:
					url += 'languageLevels/' + id;
					break;
				case ReferentialType.DEGREE:
					url += 'degrees/' + id;
					break;
				default:
					url = ReferentialsPublicService.getPublicPaginatedReferentialUrl(type) + '?id=' + id;
					break;
			}
			const getResp = await http.get(url);
			return getResp.data;
		} catch (error) {
			console.log(error);
		}
	}

	public async getYears() {
		if (this._years) {
			return this._years;
		}
		this._years = [];
		this._years.push({ id: 0, name: 'Sélectionnez', isActive: true});
		for (var i = 1980; i <= new Date().getFullYear(); i++) {
			this._years.push({
				id: i,
				name: `${i}`,
				isActive: true
			});
		}
		return this._years;
	}
}

export default new ReferentialsService();

export interface ReferentialItem {
	id: number;
	name: string;
	isActive: boolean;
}

export interface NationItem extends ReferentialItem {
	formattedNationality: string;
	shortName: string;
}

export interface ReferentialQuery {
	id?: number | null;
	RankId: number;
	ShowActivesOnly: boolean;
	PageNumber: number;
	PageSize: number;
	Name?: string | null;
	ArmyId?: number | null;
	ZipCode?: string | null;
}

export interface ReferentialPaginatedResult {
	id: number;
	name: string;
	count: number;
	totalQueryCount: number;
	totalCount: number;
	totalPages: number;
	currentPage: number;
	data: any[]; // TODO: Implementer plusieurs objets par type de referentiel etc...
}

export interface ReferentialArmySpecificQuery extends ReferentialQuery {
	ArmyId?: number | null;
}
