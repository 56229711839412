import React, { MouseEventHandler } from 'react';
import './0-Button-mobile.scss';

class Button extends React.Component<
	{
		text: string;
		disabled?: boolean;
		onClick?: React.MouseEventHandler<HTMLButtonElement>;
		type: ButtonType;
		iconState?: ButtonIconState;
		iconClassName?: string;
		size: ButtonSize;
		modalId?: string;
		className?: string;
	},
	{}
> {
	render() {
		var typeStr = '';
		var iconStateStr = '';
		var sizeStr = '';

		switch (this.props.type) {
			case ButtonType.Secondary:
				typeStr = 'fr-btn--secondary';
				break;
			case ButtonType.Tertiary:
				typeStr = 'fr-btn--tertiary';
				break;
		}

		if (this.props.iconState !== undefined) {
			switch (this.props.iconState) {
				case ButtonIconState.Left:
					iconStateStr = 'fr-btn--icon-left';
					break;
				case ButtonIconState.Right:
					iconStateStr = 'fr-btn--icon-right';
					break;
			}
		}

		switch (this.props.size) {
			case ButtonSize.Small:
				sizeStr = 'fr-btn--sm';
				break;
			case ButtonSize.Large:
				sizeStr = 'fr-btn--lg';
				break;
		}

		return (
			<button
				className={
					'fr-btn ' +
					sizeStr +
					' ' +
					typeStr +
					' ' +
					this.props.iconClassName +
					' ' +
					iconStateStr +
					' ' +
					this.props.className
				}
				disabled={this.props.disabled}
				onClick={this.props.onClick}
				aria-controls={this.props.modalId}
				data-fr-opened='false'>
				{this.props.text}
			</button>
		);
	}
}

export enum ButtonType {
	Primary,
	Secondary,
	Tertiary
}

export enum ButtonIconState {
	Left,
	Right,
	None
}

export enum ButtonSize {
	Small,
	Medium,
	Large
}

export default Button;
