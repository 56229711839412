import QueryString, { ParsedQs } from 'qs';
import React, { MouseEventHandler } from 'react';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import Container from '../../components/container/Container';
import Input, { InputType } from '../../components/input/Input';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import FormContainer from '../../components/form-container/FormContainer';
import PasswordPublicService, { PasswordResetResponseStatus } from '../../services/public/PasswordPublicService';
import PageTitle from '../../components/page-title/PageTitle';
import './0-ChangePassword-Mobile.scss';

interface IChangePasswordState {
	token?: ParsedQs;
	password?: string;
	confirmPassword?: string;
	isLoading: boolean;
	validationLength: boolean;
	validationSpecialCharacter: boolean;
	validationLowerCaseCharacter: boolean;
	validationUpperCaseCharacter: boolean;
	validationNumber: boolean;
	validationSamePassword?: boolean;
	isValid: boolean;
	changePasswordResponse: PasswordResetResponseStatus;
}

class ChangePassword extends React.Component<{ location: Location }> {
	state: IChangePasswordState = {
		isLoading: false,
		validationLength: false,
		validationSpecialCharacter: false,
		validationLowerCaseCharacter: false,
		validationUpperCaseCharacter: false,
		validationNumber: false,
		changePasswordResponse: PasswordResetResponseStatus.UNKNOWN,
		isValid: false
	};

	constructor(props: any) {
		super(props);
		const parsedQueryString = QueryString.parse(this.props.location.search, {
			ignoreQueryPrefix: true
		});
		if (parsedQueryString.token) {
			this.state.token = parsedQueryString.token as ParsedQs;
		}
	}

	validatePassword() {
		let newState = {
			validationLength: false,
			validationSpecialCharacter: false,
			validationNumber: false,
			isValid: false,
			validationLowerCaseCharacter: false,
			validationUpperCaseCharacter: false,
			validationSamePassword: this.state.validationSamePassword
		};

		if (this.state.password) {
			if (this.state.password.length >= 10) {
				newState.validationLength = true;
			}

			if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.state.password)) {
				newState.validationSpecialCharacter = true;
			}

			if (/[0-9]/.test(this.state.password)) {
				newState.validationNumber = true;
			}

			if (/[a-zàâäéèêëiïîôöùûüÿçæœ]/.test(this.state.password)) {
				newState.validationLowerCaseCharacter = true;
			}

			if (/[A-ZÂÊÎÔÛÄËÏÖÜÀÆÇÉÈŒÙ]/.test(this.state.password)) {
				newState.validationUpperCaseCharacter = true;
			}

			if (this.state.password === this.state.confirmPassword) {
				newState.validationSamePassword = true;
			}
		}

		this.setState(newState, () => this.isValidStep5());
	}

	isValidStep5() {
		this.setState({
			isValid:
				this.state.validationLength &&
				this.state.validationNumber &&
				this.state.validationSpecialCharacter &&
				this.state.validationLowerCaseCharacter &&
				this.state.validationUpperCaseCharacter &&
				this.state.validationSamePassword
		});
	}
	onChangePassword: MouseEventHandler = async (e) => {
		this.setState(
			{
				isLoading: true
			},
			() => {
				if (this.state.password && this.state.confirmPassword && this.state.password === this.state.confirmPassword) {
					PasswordPublicService.changePassword(this.state.token!.toString(), this.state.password).then(
						(resp: PasswordResetResponseStatus) => {
							this.setState({
								isLoading: false,
								changePasswordResponse: resp
							});
						}
					);
				}
			}
		);
	};

	render() {
		return (
			<>
				<Container isLoading={this.state.isLoading}>
					<div className='fr-grid-row fr-grid-row--center'>
						<div className='fr-col-12 fr-col-lg-6'>
							<FormContainer>
								<PageTitle
									title={
										this.state.changePasswordResponse === PasswordResetResponseStatus.SUCCESS
											? 'Confirmation de votre nouveau mot de passe'
											: 'Créer votre nouveau mot de passe'
									}
									description={
										this.state.changePasswordResponse === PasswordResetResponseStatus.UNKNOWN
											? 'Veuillez saisir votre nouveau mot de passe ci-dessous.'
											: ''
									}
								/>
								<Alert
									description='Un récapitulatif vous a été envoyé par courriel à l’adresse indiquée.'
									type={AlertType.Success}
									hasCloseButton={false}
									isShown={this.state.changePasswordResponse === PasswordResetResponseStatus.SUCCESS}
									size={AlertSize.Small}
								/>
								{this.state.changePasswordResponse === PasswordResetResponseStatus.SUCCESS && (
									<p className='fr-hint-text password-reset-detail'>
										Détail — Si vous n'avez pas reçu de courriel (n'hésitez pas à vérifier dans les
										indésirables).
									</p>
								)}
								{this.state.changePasswordResponse === PasswordResetResponseStatus.UNKNOWN && (
									<>
										<p className='fr-hint-text password-reset-detail-bis'>
											Sauf mention contraire, tous les champs sont obligatoires.
										</p>
										<Input
											title='Mot de passe'
											type={InputType.Password}
											value={this.state.password}
											onChange={(e) => {
												this.setState(
													{
														password: e,
														validationSamePassword: this.state.confirmPassword === e
													},
													() => this.validatePassword()
												);
											}}></Input>
										<div className='change-password-rules'>
											<p className='fr-hint-text'>Votre mot de passe doit contenir:</p>
											<span
												className={
													'fr-hint-text fr-text-default--' +
													(this.state.validationLength ? 'success' : 'info')
												}>
												<span
													className={
														'fr-icon--sm fr-icon-' +
														(this.state.validationLength ? 'success-fill' : 'info-fill')
													}></span>{' '}
												10 caractères minimum
											</span>
											<span
												className={
													'fr-hint-text fr-text-default--' +
													(this.state.validationSpecialCharacter ? 'success' : 'info')
												}>
												<span
													className={
														'fr-icon--sm fr-icon-' +
														(this.state.validationSpecialCharacter ? 'success-fill' : 'info-fill')
													}></span>{' '}
												1 caractère spécial
											</span>
											<span
												className={
													'fr-hint-text fr-text-default--' +
													(this.state.validationLowerCaseCharacter ? 'success' : 'info')
												}>
												<span
													className={
														'fr-icon--sm fr-icon-' +
														(this.state.validationLowerCaseCharacter ? 'success-fill' : 'info-fill')
													}></span>{' '}
												1 caractère minuscule
											</span>
											<span
												className={
													'fr-hint-text fr-text-default--' +
													(this.state.validationUpperCaseCharacter ? 'success' : 'info')
												}>
												<span
													className={
														'fr-icon--sm fr-icon-' +
														(this.state.validationUpperCaseCharacter ? 'success-fill' : 'info-fill')
													}></span>{' '}
												1 caractère majuscule
											</span>
											<span
												className={
													'fr-hint-text fr-text-default--' +
													(this.state.validationNumber ? 'success' : 'info')
												}>
												<span
													className={
														'fr-icon--sm fr-icon-' +
														(this.state.validationNumber ? 'success-fill' : 'info-fill')
													}></span>{' '}
												1 chiffre minimum
											</span>
										</div>
										<Input
											title='Confirmation du mot de passe'
											type={InputType.Password}
											value={this.state.confirmPassword}
											onChange={(e) => {
												this.setState(
													{
														confirmPassword: e,
														validationSamePassword: this.state.password === e
													},
													() => this.isValidStep5()
												);
											}}
											errorMessage={
												this.state.validationSamePassword === false
													? 'Le champ "Confirmation du mot de passe" doit être similaire au champ "Mot de passe"'
													: undefined
											}></Input>
										<Button
											className='mobile-button'
											size={ButtonSize.Medium}
											text='Continuer'
											type={ButtonType.Primary}
											onClick={this.onChangePassword.bind(this)}
											disabled={!this.state.isValid}
										/>
									</>
								)}
							</FormContainer>
						</div>
					</div>
				</Container>
			</>
		);
	}
}

export default ChangePassword;
