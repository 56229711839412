import { BrowserView, MobileView } from 'react-device-detect';
import { v4 as uuid } from 'uuid';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import AttachmentUpload from '../../components/attachment-upload/AttachmentUpload';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import Modal from '../../components/modal/Modal';
import PageTitle from '../../components/page-title/PageTitle';
import Radio from '../../components/radio/Radio';
import ReferentialSelect from '../../components/referential-select/ReferentialSelect';
import { TabHeaderItem } from '../../components/tabs/TabHeader';
import TabItem from '../../components/tabs/TabItem';
import Tabs from '../../components/tabs/Tabs';
import TileContainer from '../../components/tile-container/TileContainer';
import Tile from '../../components/tile/Tile';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import EnumGroupingType from '../../enums/EnumGroupingType';
import { AttachmentState } from '../../services/AttachmentService';
import FieldCheckerService from '../../services/FieldCheckerService';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import { NephosESLScopPoolMilitaryEmploymentHistoryDetail } from '../../sparta.api';
import { createRef } from 'react';
interface ICompetencesExperiencesMilitairesState extends IProfileState {
	isExperienceMilitaireLoading?: boolean;
	currentExperienceMilitaire?: NephosESLScopPoolMilitaryEmploymentHistoryDetail;
	successMessage?: string;
	isValid: boolean;
	isUploadSecurityCertificateOrFICE: boolean;
	isDeleteSecurityCertificateOrFICE: boolean;
	isUploadAnnualSportsTestsResults: boolean;
	isDeleteAnnualSportsTestsResults: boolean;
	isUploadSignaliticStateofServices: boolean;
	isDeleteSignaliticStateofServices: boolean;
	isUploadReserveOrOriginalArmyDischargeCopy: boolean;
	isDeleteReserveOrOriginalArmyDischargeCopy: boolean;
}

class CompetencesExperiencesMilitaires extends BaseComponent<ICompetencesExperiencesMilitairesState> {
	state: ICompetencesExperiencesMilitairesState = {
		isExperienceMilitaireLoading: false,
		isEdit: false,
		isValid: false,
		isUploadSecurityCertificateOrFICE: false,
		isDeleteSecurityCertificateOrFICE: false,
		isUploadAnnualSportsTestsResults: false,
		isDeleteAnnualSportsTestsResults: false,
		isUploadSignaliticStateofServices: false,
		isDeleteSignaliticStateofServices: false,
		isUploadReserveOrOriginalArmyDischargeCopy: false,
		isDeleteReserveOrOriginalArmyDischargeCopy: false
	};
	militaryPreparationModalId: string = uuid();
	currentExperienceMilitaireIndex: number = -1;
	inputRef: any = createRef();
	groupingType: number = EnumGroupingType.COMPETENCES_EXPERIENCE_MILITAIRE;

	componentDidUpdate() {
		FieldCheckerService.check(
			EnumApplicantInputArmyType.EXPERIENCES_MILITAIRES_AVEZ_VOUS_DEJA_UNE_EXPERIENCE_MILITAIRE,
			this.state.profile?.education?.militaryEducationDetails,
			this.requiredFields
		);
	}

	async checkIfCanDelete() {
		return await this.getRequiredFields();
	}

	setSecurityCertificateOrFICE(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				professional: {
					...this.state.profile?.professional,
					securityCertificateOrFICE: {
						id: guid,
						validationState: AttachmentState.ADDED
					}
				}
			},
			isUploadSecurityCertificateOrFICE: true
		});
	}

	deleteSecurityCertificateOrFICE() {
		this.setState({
			profile: {
				...this.state.profile,
				professional: {
					...this.state.profile?.professional,
					securityCertificateOrFICE: undefined
				}
			},
			isDeleteSecurityCertificateOrFICE: true
		});
	}

	setAnnualSportsTestsResults(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				professional: {
					...this.state.profile?.professional,
					annualSportsTestsResults: {
						id: guid,
						validationState: AttachmentState.ADDED
					}
				}
			},
			isUploadAnnualSportsTestsResults: true
		});
	}

	deleteAnnualSportsTestsResults() {
		this.setState({
			profile: {
				...this.state.profile,
				professional: {
					...this.state.profile?.professional,
					annualSportsTestsResults: undefined
				}
			},
			isDeleteAnnualSportsTestsResults: true
		});
	}

	setSignaliticStateofServices(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				professional: {
					...this.state.profile?.professional,
					signaliticStateofServices: {
						id: guid,
						validationState: AttachmentState.ADDED
					}
				}
			},
			isUploadSignaliticStateofServices: true
		});
	}

	deleteSignaliticStateofServices() {
		this.setState({
			profile: {
				...this.state.profile,
				professional: {
					...this.state.profile?.professional,
					signaliticStateofServices: undefined
				}
			},
			isDeleteSignaliticStateofServices: true
		});
	}

	setReserveOrOriginalArmyDischargeCopy(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				professional: {
					...this.state.profile?.professional,
					reserveOrOriginalArmyDischargeCopy: {
						id: guid,
						validationState: AttachmentState.ADDED
					}
				}
			},
			isUploadReserveOrOriginalArmyDischargeCopy: true
		});
	}

	deleteReserveOrOriginalArmyDischargeCopy() {
		this.setState({
			profile: {
				...this.state.profile,
				professional: {
					...this.state.profile?.professional,
					reserveOrOriginalArmyDischargeCopy: undefined
				}
			},
			isDeleteReserveOrOriginalArmyDischargeCopy: true
		});
	}

	initUpload() {
		this.setState({
			isUploadSecurityCertificateOrFICE: false,
			isDeleteSecurityCertificateOrFICE: false,
			isUploadAnnualSportsTestsResults: false,
			isDeleteAnnualSportsTestsResults: false,
			isUploadSignaliticStateofServices: false,
			isDeleteSignaliticStateofServices: false,
			isUploadReserveOrOriginalArmyDischargeCopy: false,
			isDeleteReserveOrOriginalArmyDischargeCopy: false
		});
	}

	tabs: TabHeaderItem[] =
		ReferentialsService.getArmy() === 1 || ReferentialsService.getArmy() === 2
			? [
					{
						displayName: 'Formulaire',
						id: 'form',
						tabIndex: 0,
						selected: true
					},
					{
						displayName: 'Pièces jointes',
						id: 'attachments',
						tabIndex: 1,
						selected: false
					}
			  ]
			: [{ displayName: 'Formulaire', id: 'form', tabIndex: 0, selected: true }];

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Expériences militaires'
							description='Veuillez remplir les informations demandées ci-dessous'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.successMessage && this.state.groupingIsValidate == false && (
							<Alert
								description={this.state.successMessage}
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						<Tabs
							mobileOnly={true}
							isFormContainer={true}
							items={this.tabs}>
							<TabItem
								id='form'
								tabIndex={0}
								selected={true}
								mobileOnly={true}>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<h5>Expériences militaires</h5>
										<p>Avez vous déjà une expérience militaire ?</p>
										<Radio
											items={[
												{
													DisplayName: 'Oui',
													Value: 1,
													Checked: this.state.profile?.professional?.hasMilitaryEmploymentHistory
												},
												{
													DisplayName: 'Non',
													Value: 0,
													Checked: !this.state.profile?.professional?.hasMilitaryEmploymentHistory
												}
											]}
											disabled={this.state.groupingIsValidate ?? false}
											isHorizontal={false}
											isSmall={false}
											name='has-military-prep'
											title=''
											onChange={(e) => {
												this.setState({
													currentExperienceMilitaire: e.currentTarget.value === '1' ? {} : undefined,
													profile: {
														...this.state.profile,
														professional: {
															...this.state.profile?.professional,
															hasMilitaryEmploymentHistory: e.currentTarget.value === '1'
														}
													}
												});
											}}></Radio>
									</div>
								</div>
								{this.state.profile?.professional?.hasMilitaryEmploymentHistory && (
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Button
												text='Ajouter une expérience'
												disabled={this.state.groupingIsValidate ?? false}
												size={ButtonSize.Medium}
												type={ButtonType.Primary}
												modalId={this.militaryPreparationModalId}
												onClick={() => {
													this.setState(
														{
															isEdit: false,
															currentExperienceMilitaire: undefined,
															isExperienceMilitaireLoading: true
														},
														() => {
															this.setState({ isExperienceMilitaireLoading: false });
														}
													);
													this.setState({ isExperienceMilitaireLoading: false });
													this.currentExperienceMilitaireIndex = -1;
												}}></Button>
										</div>
									</div>
								)}
								{!this.state.profile?.professional?.hasMilitaryEmploymentHistory && (
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Button
												text='Enregistrer mes informations'
												disabled={this.state.groupingIsValidate ?? false}
												size={ButtonSize.Medium}
												type={ButtonType.Primary}
												onClick={() => {
													this.setState(
														{
															successMessage:
																'Les informations renseignées ont été enregistrées avec succès'
														},
														this.updateProfile
													);
												}}></Button>
										</div>
									</div>
								)}
								<TileContainer>
									{this.state.profile?.professional?.militaryEmploymentHistoryDetails?.map((x, i) => {
										return (
											<Tile
												key={x.contractCategory + ' ' + x.army}
												title=''
												onLoad={() =>
													ReferentialsService.getReferential(
														ReferentialType.MILITARY_CONTRACT_TYPE,
														x.contractCategory ? x.contractCategory : 0
													)
												}
												description={
													this.state.groupingIsValidate ?? false ? '' : 'En cours de validation...'
												}
												modalId={this.militaryPreparationModalId}
												disabled={this.state.groupingIsValidate ?? false}
												onDelete={async () => {
													let isGroupingValidated = await this.checkIfCanDelete();
													if (!isGroupingValidated) {
														var militaryExperiences = [
															...(this.state.profile?.professional
																?.militaryEmploymentHistoryDetails || [])
														];
														militaryExperiences.splice(i, 1);
														this.setState(
															{
																successMessage: "L'expérience a été supprimée avec succès",
																profile: {
																	...this.state.profile,
																	professional: {
																		...this.state.profile?.professional,
																		militaryEmploymentHistoryDetails: militaryExperiences
																	}
																}
															},
															() => {
																this.updateProfile();
															}
														);
													}
												}}
												onEdit={() => {
													this.currentExperienceMilitaireIndex = i;
													this.setState(
														{
															isEdit: true,
															currentExperienceMilitaire:
																this.state.profile?.professional?.militaryEmploymentHistoryDetails?.at(
																	i
																),
															isExperienceMilitaireLoading: true
														},
														() => {
															this.setState({ isExperienceMilitaireLoading: false });
														}
													);
													this.setState({ isExperienceMilitaireLoading: false });
												}}></Tile>
										);
									})}
								</TileContainer>
							</TabItem>
							<Modal
								inputRef={this.inputRef}
								title={this.state.isEdit ? 'Modifier une expérience' : 'Ajouter une expérience'}
								id={this.militaryPreparationModalId}
								buttons={[
									{
										DisplayName: this.state.isEdit ? 'Modifier une expérience' : 'Ajouter une expérience',
										OnClick: () => {
											if (this.inputRef && this.inputRef.current && !this.inputRef.current.disabled) {
												this.inputRef.current.disabled = true;
												var militaryExperience = [
													...(this.state.profile?.professional?.militaryEmploymentHistoryDetails || [])
												];
												if (this.state.currentExperienceMilitaire) {
													if (this.currentExperienceMilitaireIndex === -1) {
														militaryExperience.push(this.state.currentExperienceMilitaire);
													} else {
														militaryExperience[this.currentExperienceMilitaireIndex] =
															this.state.currentExperienceMilitaire;
													}
												}
												this.setState(
													{
														successMessage: this.state.isEdit
															? `L'expérience a été modifiée avec succès`
															: `L'expérience a été ajoutée avec succès`,
														profile: {
															...this.state.profile,
															professional: {
																...this.state.profile?.professional,
																militaryEmploymentHistoryDetails: militaryExperience
															}
														}
													},
													() => {
														this.updateProfile();
														setTimeout(() => {
															this.inputRef.current.disabled = false;
														}, 2000);
													}
												);
											}
										}
									}
								]}>
								<>
									{!this.state.isExperienceMilitaireLoading && (
										<>
											<ReferentialSelect
												custom={true}
												type={ReferentialType.ARMY}
												label="Armée d'appartenance"
												value={this.state.currentExperienceMilitaire?.army}
												onChange={(e) => {
													this.setState({
														currentExperienceMilitaire: {
															...this.state.currentExperienceMilitaire,
															army: e?.id
														}
													});
												}}></ReferentialSelect>
											<ReferentialSelect
												type={ReferentialType.MILITARY_CONTRACT_TYPE}
												label='Type de contrat'
												value={this.state.currentExperienceMilitaire?.contractCategory}
												onChange={(e) => {
													this.setState({
														currentExperienceMilitaire: {
															...this.state.currentExperienceMilitaire,
															contractCategory: e?.id
														}
													});
												}}></ReferentialSelect>
											<Input
												title='Durée'
												type={InputType.Text}
												value={
													this.state.currentExperienceMilitaire != undefined
														? this.state.currentExperienceMilitaire?.length
														: ''
												}
												onChange={(e) => {
													this.setState({
														currentExperienceMilitaire: {
															...this.state.currentExperienceMilitaire,
															length: e
														}
													});
												}}></Input>
											<Radio
												disabled={false}
												isHorizontal={false}
												isSmall={false}
												items={[
													{
														DisplayName: 'Oui',
														Value: 1,
														Checked: this.state.currentExperienceMilitaire?.inProgress
													},
													{
														DisplayName: 'Non',
														Value: 0,
														Checked: !this.state.currentExperienceMilitaire?.inProgress
													}
												]}
												name='is-currently-in-service'
												title='Êtes-vous actuellement en service ?'
												onChange={(e) => {
													this.setState({
														currentExperienceMilitaire: {
															...this.state.currentExperienceMilitaire,
															inProgress: e.currentTarget.value === '1'
														}
													});
												}}></Radio>
											<hr className='fr-hr'></hr>
										</>
									)}
								</>
							</Modal>
							{(ReferentialsService.getArmy() === 1 || ReferentialsService.getArmy() === 2) && (
								<>
									<BrowserView>
										<hr className='fr-hr desktop-only' />
										<h5 className='desktop-only'>Pièces jointes</h5>
									</BrowserView>
									<TabItem
										id='attachments'
										tabIndex={1}
										selected={false}
										mobileOnly={true}>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<span className='fr-hint-text'>Taille maximale : 10 Mo.</span>
												<span className='fr-hint-text'>Formats supportés : jpg, png, pdf...</span>
											</div>
										</div>
										{ReferentialsService.getArmy() === 2 && (
											<div className='fr-grid-row'>
												<div className='fr-col-12'>
													<AttachmentUpload
														noExplanation
														hintText='Certificat de sécurité ou FICE'
														guid={this.state.profile?.professional?.securityCertificateOrFICE?.id}
														onUpload={this.setSecurityCertificateOrFICE.bind(this)}
														onDelete={this.deleteSecurityCertificateOrFICE.bind(this)}
														isDisabled={this.state.groupingIsValidate ?? false}
														isUploadInProgress={this.state.isUploadSecurityCertificateOrFICE}
														isDeleteInProgress={
															this.state.isDeleteSecurityCertificateOrFICE
														}></AttachmentUpload>
												</div>
											</div>
										)}
										{(ReferentialsService.getArmy() === 1 || ReferentialsService.getArmy() === 2) && (
											<div className='fr-grid-row'>
												<div className='fr-col-12'>
													<AttachmentUpload
														noExplanation
														hintText='Etat signalitique des services'
														guid={this.state.profile?.professional?.signaliticStateofServices?.id}
														onUpload={this.setSignaliticStateofServices.bind(this)}
														onDelete={this.deleteSignaliticStateofServices.bind(this)}
														isDisabled={this.state.groupingIsValidate ?? false}
														isUploadInProgress={this.state.isUploadSignaliticStateofServices}
														isDeleteInProgress={
															this.state.isDeleteSignaliticStateofServices
														}></AttachmentUpload>
												</div>
											</div>
										)}
										{ReferentialsService.getArmy() === 2 && (
											<div className='fr-grid-row'>
												<div className='fr-col-12'>
													<AttachmentUpload
														noExplanation
														hintText='Résultats des épreuves sportives annuelles interarmées'
														guid={this.state.profile?.professional?.annualSportsTestsResults?.id}
														onUpload={this.setAnnualSportsTestsResults.bind(this)}
														onDelete={this.deleteAnnualSportsTestsResults.bind(this)}
														isDisabled={this.state.groupingIsValidate ?? false}
														isUploadInProgress={this.state.isUploadAnnualSportsTestsResults}
														isDeleteInProgress={
															this.state.isDeleteAnnualSportsTestsResults
														}></AttachmentUpload>
												</div>
											</div>
										)}
										{ReferentialsService.getArmy() === 2 && (
											<div className='fr-grid-row'>
												<div className='fr-col-12'>
													<AttachmentUpload
														noExplanation
														hintText="Copie demande de radiation dans la réserve ou dans l'armée d'origine"
														guid={
															this.state.profile?.professional?.reserveOrOriginalArmyDischargeCopy
																?.id
														}
														onUpload={this.setReserveOrOriginalArmyDischargeCopy.bind(this)}
														onDelete={this.deleteReserveOrOriginalArmyDischargeCopy.bind(this)}
														isDisabled={this.state.groupingIsValidate ?? false}
														isUploadInProgress={this.state.isUploadReserveOrOriginalArmyDischargeCopy}
														isDeleteInProgress={
															this.state.isDeleteReserveOrOriginalArmyDischargeCopy
														}></AttachmentUpload>
												</div>
											</div>
										)}
									</TabItem>
								</>
							)}
							<BrowserView>
								{!this.state.groupingIsValidate &&
									(ReferentialsService.getArmy() === 1 || ReferentialsService.getArmy() === 2) && (
										<div className='fr-grid-row fr-grid-row--right'>
											<div className='fr-col-12 fr-col-md-6 fr-align-right'>
												<Button
													// disabled={!this.state.isValid}
													onClick={() => {
														this.updateProfile(() => {
															this.setState({
																successMessage: `Les informations renseignées ont été enregistrées avec succès`
															});
															this.displaySuccess();
															this.initUpload();
														});
													}}
													text='Enregistrer mes informations'
													type={ButtonType.Primary}
													iconClassName=''
													size={ButtonSize.Medium}
													modalId=''></Button>
											</div>
										</div>
									)}
							</BrowserView>
						</Tabs>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-8'>
										<Button
											className='mobile-button'
											disabled={false}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											iconClassName=''
											size={ButtonSize.Medium}
											modalId=''
											onClick={() => {
												this.updateProfile(() => {
													this.setState({
														successMessage: `Les informations renseignées ont été enregistrées avec succès`
													});
													this.displaySuccess();
													this.initUpload();
												});
											}}></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
			</Container>
		);
	}
}
export default CompetencesExperiencesMilitaires;
