import React, { MouseEventHandler } from 'react';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import Container from '../../components/container/Container';
import Input, { InputType } from '../../components/input/Input';
import PasswordPublicService, { PasswordResetResponse, PasswordResetResponseStatus } from '../../services/public/PasswordPublicService';
import FormContainer from '../../components/form-container/FormContainer';
import PageTitle from '../../components/page-title/PageTitle';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import FormatCheckService from '../../services/FormatCheckService';
import './0-ForgottenPassword-Mobile.scss';

interface IForgottenPasswordState {
	email?: string;
	isLoading: boolean;
	emailErrorMessage?: string;
	passwordResetResponse: PasswordResetResponse;
	isCurrentStepValid: boolean;
}

class ForgottenPassword extends React.Component {
	state: IForgottenPasswordState = {
		isLoading: false,
		emailErrorMessage: '',
		isCurrentStepValid: false,
		passwordResetResponse: { status: PasswordResetResponseStatus.UNKNOWN }
	};

	onRequestClick: MouseEventHandler = async (e) => {
		this.setState(
			{
				isLoading: true
			},
			() => {
				if (this.state.email) {
					PasswordPublicService.requestPasswordReset(this.state.email).then((resp: PasswordResetResponse) => {
						this.setState({
							isLoading: false,
							passwordResetResponse: resp
						});
					});
				}
			}
		);
	};

	isCurrentStepValid() {
		this.setState({ isCurrentStepValid: this.state.email && !this.state.emailErrorMessage });
	}

	render() {
		return (
			<>
				<Container isLoading={this.state.isLoading}>
					<div className='fr-grid-row fr-grid-row--center'>
						<div className='fr-col-12 fr-col-lg-6'>
							<FormContainer>
								<PageTitle
									title='Réinitialiser votre mot de passe'
									description={
										this.state.passwordResetResponse.status === PasswordResetResponseStatus.UNKNOWN
											? 'Vous avez oublié votre mot de passe ? Saisissez votre adresse électronique ci-dessous.'
											: ''
									}
								/>
								<Alert
									description={
										'Nous n’avons pas trouvé de compte correspondant à ' +
										this.state.email +
										". Essayez de vous identifier avec une autre adresse e-mail ou de créer votre compte si ce n'est pas encore fait."
									}
									type={AlertType.Error}
									hasCloseButton={false}
									isShown={this.state.passwordResetResponse.status === PasswordResetResponseStatus.NOTFOUND}
									size={AlertSize.Small}
								/>
								<Alert
									description='Un courriel vous a été envoyé à l’adresse indiquée. Pour créer votre nouveau mot de passe, cliquez sur le lien reçu dans ce courriel.'
									type={AlertType.Success}
									hasCloseButton={false}
									isShown={this.state.passwordResetResponse.status === PasswordResetResponseStatus.SUCCESS}
									size={AlertSize.Small}
								/>
								{this.state.passwordResetResponse.status === PasswordResetResponseStatus.SUCCESS && (
									<p className='fr-hint-text password-reset-detail'>
										Détail — Si vous n'avez pas reçu de courriel (n'hésitez pas à vérifier dans les
										indésirables).
									</p>
								)}
								{this.state.passwordResetResponse.status === PasswordResetResponseStatus.UNKNOWN && (
									<>
										<Input
											title='Adresse électronique'
											description='Format attendu : nom@domaine.fr'
											type={InputType.Text}
											value={this.state.email}
											onChange={(e) => {
												this.setState(
													{
														emailErrorMessage: FormatCheckService.checkEmail(e)
															? ''
															: 'Le format de l’adresse électronique saisie n’est pas valide. Le format attendu est : nom@exemple.org',
														email: e
													},
													() => {
														this.isCurrentStepValid();
													}
												);
											}}
											onBlur={(e) => {
												this.setState(
													{
														emailErrorMessage: FormatCheckService.checkEmail(e)
															? ''
															: 'Le format de l’adresse électronique saisie n’est pas valide. Le format attendu est : nom@exemple.org',
														email: e
													},
													() => {
														this.isCurrentStepValid();
													}
												);
											}}
											errorMessage={this.state.emailErrorMessage}></Input>
										<Button
											className='mobile-button'
											size={ButtonSize.Medium}
											text='Continuer'
											type={ButtonType.Primary}
											disabled={!this.state.isCurrentStepValid}
											onClick={this.onRequestClick.bind(this)}
										/>
									</>
								)}
							</FormContainer>
						</div>
					</div>
				</Container>
			</>
		);
	}
}

export default ForgottenPassword;
