import React from 'react';
import ConvertHTML from '../tools/ConvertHTML';
import { v4 as uuid } from 'uuid';

import './0-Select-mobile.scss';

class Select extends React.Component<
	{
		title: string;
		value?: any;
		description?: string;
		defaultOptionDisplayName: string;
		items: SelectItem[];
		errorMessage?: string;
		disabled: boolean;
		onChange: (e: string) => void;
	},
	{}
> {
	render() {
		var errorStr = this.props.errorMessage ? 'fr-select-group--error' : '';
		var itemElements = this.props.items.map((x) => {
			return (
				<option
					key={x.DisplayName}
					value={x.Value}>
					{x.DisplayName}
				</option>
			);
		});
		return (
			<div
				className={'fr-select-group ' + errorStr}
				onClick={(e) => e.stopPropagation()}>
				<label
					className='fr-label'
					htmlFor='select'>
					<ConvertHTML text={this.props.title} />
					<span className='fr-hint-text'>{this.props.description}</span>
				</label>
				<select
					className='fr-select'
					id='select'
					name='select'
					value={this.props.value}
					disabled={this.props.disabled}
					onChange={(e) => {
						this.props.onChange(e.target.value);
					}}>
					{this.props.defaultOptionDisplayName && (
						<option
							key='0'
							value=''
							disabled
							hidden>
							{this.props.defaultOptionDisplayName}
						</option>
					)}
					{itemElements}
				</select>
				{this.props.errorMessage && (
					<p
						id='select-error-desc-error'
						className='fr-error-text'>
						{this.props.errorMessage}
					</p>
				)}
			</div>
		);
	}
}

export interface SelectItem {
	DisplayName: string;
	Value: any;
}

export default Select;
