import http from '../http-common';
import ApplicationStatus from '../models/ApplicationStatus';

class ApplicationStatusSevice {
	private _baseURL: string = '/api/applicationstatus';

	async get(armyId: string) {
		try {
			const getResp = await http.get<{ armyId: string }>(this._baseURL + `?armyId=${armyId}`);
			const status = (await getResp).data;
			return status;
		} catch (error: any) {
			console.log('Cancellation threw an error', error);
			return error.code;
		}
	}

	public ConvertToApplicationStatusListToDisplay(applicationStatusList: ApplicationStatus[]) {
		var arrayUniqueByKey = applicationStatusList.filter(
			(value, index, self) => self.map((x) => x.ApplicantDisplayName).indexOf(value.ApplicantDisplayName) == index
		);

		const applicationStatusDisplayNameList = arrayUniqueByKey.map((x) => x.ApplicantDisplayName);

		for (let i = 0; i < arrayUniqueByKey.length; i++) {
			const applicationStatusWithEarliestDate = applicationStatusList
				.filter((applicantStatus) => applicantStatus.ApplicantDisplayName == applicationStatusDisplayNameList[i])
				.reduce(function (r, a) {
					return Date.parse(r.StatusHistoryDate) < Date.parse(a.StatusHistoryDate) ? r : a;
				});
			if (applicationStatusWithEarliestDate != undefined) {
				arrayUniqueByKey = arrayUniqueByKey.map((x) =>
					x.ApplicantDisplayName == applicationStatusWithEarliestDate?.ApplicantDisplayName ? applicationStatusWithEarliestDate : x
				);
			}
		}

		const applicantStatusCurrent = applicationStatusList.find(
			(applicantStatus) => applicantStatus.IsCurrentApplicantStatus == true
		);

		if (applicantStatusCurrent != undefined) {
			var applicantStatusBindCurrent = arrayUniqueByKey.find(
				(applicantStatus) => applicantStatus.ApplicantDisplayName == applicantStatusCurrent.ApplicantDisplayName
			);
			if (applicantStatusBindCurrent != undefined) {
				applicantStatusBindCurrent.IsCurrentApplicantStatus = true;
				applicantStatusBindCurrent.StatusHistoryDate =
					applicantStatusBindCurrent.StatusHistoryDate != '9999-11-30T00:00:00.000' &&
					applicantStatusBindCurrent.StatusHistoryDate < applicantStatusCurrent.StatusHistoryDate
						? applicantStatusBindCurrent.StatusHistoryDate
						: applicantStatusCurrent.StatusHistoryDate;
				arrayUniqueByKey = arrayUniqueByKey.map((x) =>
					x.ApplicantDisplayName == applicantStatusBindCurrent?.ApplicantDisplayName ? applicantStatusBindCurrent : x
				);
			}
		}

		const currentApplicationStatusDate = applicantStatusBindCurrent?.StatusHistoryDate;
		const currentApplicationStatusOrder = applicantStatusBindCurrent?.Order;

		if (currentApplicationStatusDate != undefined && currentApplicationStatusOrder != undefined) {
			const applicationStatusListWithDateUpdate: ApplicationStatus[] = arrayUniqueByKey.map((x: any) => {
				return {
					Order: x.Order,
					ApplicantDisplayName: x.ApplicantDisplayName,
					Description: x.Description,
					StatusHistoryDate:
						x.Order > currentApplicationStatusOrder
							? '9999-11-30T00:00:00.000'
							: x.StatusHistoryDate == '9999-11-30T00:00:00.000' && x.Order < currentApplicationStatusOrder
							  ? currentApplicationStatusDate
							  : x.StatusHistoryDate,
					IsCurrentApplicantStatus: x.IsCurrentApplicantStatus
				};
			});

			return applicationStatusListWithDateUpdate;
		}
		return arrayUniqueByKey;
	}
}

export default new ApplicationStatusSevice();
