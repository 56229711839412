import { useEffect, useState } from 'react';
import Alert, { AlertSize, AlertType } from '../../alert/Alert';
import ReferentialSelect from '../../referential-select/ReferentialSelect';
import SearchBar from '../../search-bar/SearchBar';
import CirfaCard from '../../cirfa-card/CirfaCard';
import EventContainer from '../../../models/EventContainer';
import { ComponentStepProps, EventCustomer } from '../../../models';
import { ReferentialType } from '../../../services/ReferentialsService';
import { CirfaPublicService } from '../../../services';

function CirfaStep({ model, handleChange }: ComponentStepProps) {
	const [selectZipCode, setSelectZipCode] = useState<string | undefined>('');
	const [cirfas, setCirfas] = useState<any>();
	const [wrongZipcode, setWrongZipcode] = useState(false);

	useEffect(() => {
		if (model.cirfaId) {
			searchCirfa(model.zipCode);
		}
	}, []);

	const handleCirfaCategoryId = (e: any) => {
		const evt: EventCustomer = { name: 'cirfaCategoryId', value: e?.id };
		handleChange(evt);
	};

	const handleZipCodeChange = (e: any) => {
		const evt: EventContainer = { target: { name: 'zipCode', value: e } };
		handleChange(evt);
	};

	const handleCirfaChange = (e: any, id: number) => {
		const evt: EventContainer = { target: { name: 'cirfaId', value: id } };
		handleChange(evt);
		e.preventDefault();
	};

	const searchCirfa = (e: any) => {
		CirfaPublicService.getCirfasByZipCode(model.zipCode || '', model.cirfaCategoryId || 0).then((resp) => {
			setSelectZipCode(model.zipCode);
			setCirfas(
				resp
					? resp.map((data, key) => {
							return { ...data, isSelected: false };
					  })
					: null
			);
			setWrongZipcode(resp ? false : true);
		});
		if (e.preventDefault) {
			e.preventDefault();
		}
	};

	return (
		<div>
			<p className='subtitle'>
				Trouvez le Centre d'Information et de Recrutement des Forces Armées (CIRFA), le plus proche de chez vous ou du
				lieu de votre choix (Exemple : là où vous faites vos études, la ville où habitent vos parents, etc.)
			</p>
			<h5>Renseignez une catégorie souhaitée</h5>
			<p className='fr-hint-text account-creation-hint'>Sauf mention contraire, tous les champs sont obligatoires.</p>
			<ReferentialSelect
				type={ReferentialType.JOB_CATEGORY_PUBLIC}
				value={model.cirfaCategoryId}
				onChange={(e) => handleCirfaCategoryId(e)}></ReferentialSelect>
			<h5>Renseignez votre code postal</h5>
			<SearchBar
				label='Renseignez votre code postal'
				onClick={(e) => {
					searchCirfa(e);
				}}
				onChange={(e: any) => handleZipCodeChange(e)}
				value={model.zipCode}
				onkeydown={(e) => {
					if (e === 'Enter') {
						searchCirfa(e);
					}
				}}
				isLarge={false}
				placeholder='Votre code postal (exemple. : 69700)'></SearchBar>
			{selectZipCode && <h5>CIRFA disponible pour le code postal : {selectZipCode}</h5>}
			<Alert
				description="Le code postal saisi n'existe pas"
				size={AlertSize.Small}
				type={AlertType.Warning}
				isShown={wrongZipcode}></Alert>
			{!model.zipCode && <span className='fr-text fr-text--xs'>Aucun code postal renseigné</span>}
			{cirfas && (
				<div className='card-container card-container-cirfa row-2-elem'>
					{cirfas.map((cirfa: any) => (
						<CirfaCard
							key={cirfa.id}
							cirfa={cirfa}
							onClickEvent={(e: any) => {
								handleCirfaChange(e, cirfa.id);
							}}></CirfaCard>
					))}
				</div>
			)}
		</div>
	);
}

export default CirfaStep;
