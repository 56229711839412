class FormatCheckService {
	checkLastName(name: string): boolean {
		const regex = /^[a-zA-Z‘’'-.àâäéèêëiïîôöùûüÿçÀÂÄÉÈÊËIÏÎÔÖÙÛÜŸÇ\s]{1,32}$/;
		return regex.test(name.trim());
	}

	checkFirstName(name: string): boolean {
		const regex = /^[a-zA-Z‘’'-.àâäéèêëiïîôöùûüÿçÀÂÄÉÈÊËIÏÎÔÖÙÛÜŸÇ\s]{1,20}$/;
		return regex.test(name.trim());
	}

	checkEmail(email: string): boolean {
		const regex =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(email);
	}

	checkPhoneNumber(phoneNumber: string | undefined): boolean {
		if (phoneNumber === undefined) {
			return true;
		}

		var reg = phoneNumber.startsWith('(+33)') ? /^[(][+][0-9]{1,3}[)][1-9]{1}[0-9]{8}$/ : /^[(][+][0-9]{1,3}[)][0-9]{1,25}$/;
		var isvalidPhone = reg.test(phoneNumber);

		return isvalidPhone;
	}
	checkZipCode(zipCode: string): boolean {
		const regex = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/i;
		return regex.test(zipCode);
	}
}

export default new FormatCheckService();
