import './0-StatusTimeline-mobile.scss';
import './1-StatusTimeline-desktop.scss';
import DateService from '../../services/DateService';
import ApplicationStatus from '../../models/ApplicationStatus';

import React from 'react';

class StatusTimeline extends React.Component<{ applicationStatus: ApplicationStatus[] }, {}> {
	render() {
		var orderIsCurrentApplicantStatus = this.props.applicationStatus.find((x) => x.IsCurrentApplicantStatus == true)?.Order;
		var maxLength = this.props.applicationStatus.length;
		var itemElements = this.props.applicationStatus.map((x: ApplicationStatus, i: number) => {
			return (
				<div
					key={x.ApplicantDisplayName + x.Order}
					className={
						maxLength != undefined && i + 1 == maxLength
							? 'timeline end'
							: orderIsCurrentApplicantStatus != undefined && x.Order >= orderIsCurrentApplicantStatus
							  ? 'timeline future'
							  : 'timeline passed'
					}>
					<div
						className={
							(x.Order.toString() == '1' && x.IsCurrentApplicantStatus) ||
							(x.Order.toString() != '1' && x.IsCurrentApplicantStatus)
								? 'container current'
								: orderIsCurrentApplicantStatus != undefined && x.Order < orderIsCurrentApplicantStatus
								  ? 'container passed'
								  : 'container future'
						}>
						<div className='content'>
							<p
								className={
									orderIsCurrentApplicantStatus != undefined && x.Order > orderIsCurrentApplicantStatus
										? 'status_name future'
										: 'status_name passed'
								}>
								{!x.Description && (
									<span
										style={{ cursor: 'pointer' }}
										id={x.Order != undefined ? 'link-' + x.Order.toString() : 'link'}>
										{x.ApplicantDisplayName}
									</span>
								)}

								{x.Description && (
									<>
										{' '}
										<span
											style={{ cursor: 'pointer' }}
											id={x.Order != undefined ? 'link-' + x.Order.toString() : 'link'}
											aria-describedby={x.Order != undefined ? 'tooltip-' + x.Order.toString() : 'tooltip'}>
											{x.ApplicantDisplayName}
										</span>
										<span
											className='fr-tooltip fr-placement'
											id={x.Order != undefined ? 'tooltip-' + x.Order.toString() : 'tooltip'}
											role='tooltip'
											aria-hidden='true'>
											{x.Description}
										</span>
									</>
								)}
							</p>
							<p
								className={
									orderIsCurrentApplicantStatus != undefined && x.Order > orderIsCurrentApplicantStatus
										? 'status_date future'
										: 'status_date passed'
								}>
								{x.StatusHistoryDate != '9999-11-30T00:00:00.000'
									? DateService.ConvertForApplicationStatus(x.StatusHistoryDate)
									: ''}
							</p>
						</div>
					</div>
				</div>
			);
		});

		return (
			<div className='fr-card'>
				<div className='fr-card__body'>
					<div className='fr-card__content'>
						<div className='fr-card__start'>{itemElements}</div>
					</div>
				</div>
			</div>
		);
	}
}

export default StatusTimeline;
