import React from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb, { BreadcrumbItem } from './breadcrumb/Breadcrumb';
import Banner from '../components/banner/Banner';

interface ILocation {
	path: string;
	displayName: string;
	children?: ILocation[];
}

const BreadCrumbed: React.FC<{ children: React.ReactNode }> = (props) => {
	const location = useLocation();
	const paths: ILocation[] = [
		{
			path: 'mon-compte',
			displayName: 'Mon compte'
		},
		{
			path: 'infos-perso',
			displayName: 'Informations personnelles',
			children: [
				{
					path: 'maritalstatus',
					displayName: 'Etat civil / Identité'
				},
				{
					path: 'situationfamiliale',
					displayName: 'Situation Familiale'
				},
				{
					path: 'contact',
					displayName: 'Contact'
				},
				{
					path: 'representantlegal',
					displayName: 'Representant Legal'
				}
			]
		},
		{
			path: 'competences',
			displayName: 'Compétences',
			children: [
				{
					path: 'scholarship',
					displayName: 'Scolarité'
				},
				{
					path: 'diplomes-formations',
					displayName: 'Diplômes et formations'
				},
				{
					path: 'experiences-professionnelles',
					displayName: 'Expériences professionnelles civiles'
				},
				{
					path: 'dispositif-defense',
					displayName: 'Dispositifs défense'
				},
				{
					path: 'army-experience',
					displayName: 'Expériences militaires'
				},
				{
					path: 'driving-license',
					displayName: 'Permis'
				},
				{
					path: 'languages',
					displayName: 'Langues'
				},
				{
					path: 'extras',
					displayName: 'Divers'
				}
			]
		},
		{
			path: 'securite',
			displayName: 'Sécurité',
			children: [
				{
					path: 'renseignements',
					displayName: "Renseignement sur l'intéressé"
				},
				{
					path: 'lieu-naissance',
					displayName: 'Lieu de naissance'
				},
				{
					path: 'nationalite',
					displayName: 'Nationalité'
				},
				{
					path: 'domicile-actuel',
					displayName: 'Domicile actuel'
				},
				{
					path: 'domicile-precedent',
					displayName: 'Domicile précédent'
				},
				{
					path: 'renseignements-famille',
					displayName: 'Renseignement sur la famille'
				},
				{
					path: 'environnement-numerique',
					displayName: 'Environnement numérique'
				}
			]
		},
		{
			path: 'rendez-vous',
			displayName: 'Mes rendez-vous',
			children: [
				{
					path: 'detail',
					displayName: 'Detail'
				}
			]
		},
		{
			path: 'postes-souhaites',
			displayName: 'Gestion des postes souhaités'
		},
		{
			path: 'login',
			displayName: 'Connexion'
		},
		{
			path: 'forgottenpassword',
			displayName: 'Mot de passe oublié'
		},
		{
			path: 'accountcreation',
			displayName: 'Création de compte'
		},
		{
			path: 'messagerie',
			displayName: 'Messagerie'
		},
		{
			path: 'moncompte',
			displayName: 'Mon compte'
		}
	];
	const currentPaths: ILocation[] = [];

	const splitted = location.pathname.split('/');
	var pathtoadd: ILocation | null = null;

	splitted.forEach((el: any) => {
		var filtered: ILocation[] | null = null;
		if (pathtoadd === null) {
			filtered = paths.filter((loc) => {
				return loc.path === el;
			});
		} else if (pathtoadd !== null) {
			filtered =
				pathtoadd.children?.filter((loc) => {
					return loc.path === el;
				}) ?? null;
		}
		if (filtered && filtered.length > 0) {
			pathtoadd = filtered[0];
		}

		if (pathtoadd) currentPaths.push(pathtoadd);
	});
	var path: string = '';
	var itemElements: BreadcrumbItem[] = [{ DisplayName: 'Accueil', Link: '/' }];
	currentPaths.forEach((x) => {
		path = path + '/' + x.path;
		const bd: BreadcrumbItem = {
			DisplayName: x.displayName,
			Link: path
		};
		itemElements.push(bd);
	});

	document.title = 'Espace Candidat - ' + itemElements[itemElements.length - 1].DisplayName;

	return (
		<>
			<Banner armyid={localStorage.getItem('armyId') || ''} />
			<div className='fr-container sparta-container'>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-12'>
						<Breadcrumb items={itemElements} />
					</div>
				</div>
			</div>
			{props.children}
		</>
	);
};

export default BreadCrumbed;
