import EnumApplicantInputArmyType from '../enums/EnumApplicantInputArmyType';
import RequiredField from '../models/RequiredField';

class FieldCheckerService {
	check(fieldType: EnumApplicantInputArmyType, value: any, requiredFields: RequiredField[]) {
		var field: RequiredField | undefined = undefined;
		if (value) {
			field = requiredFields.find((f) => f.Type === fieldType);
			if (field) field.IsValid = true;
		}
	}
}
export default new FieldCheckerService();
