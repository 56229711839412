import React from 'react';
import PageTitle from '../../components/page-title/PageTitle';
import JobCard from './jobcard';
import Modal, { ModalSize } from '../modal/Modal';
import Button, { ButtonSize, ButtonType } from '../button/Button';
import JobService from '../../services/JobService';

class JobCategorie extends React.Component<
	{
		title: string;
		id: string;
		nbpost: string;
		jobs: any;
	},
	{
		jobsList: JSX.Element[];
		jobCardShow: JSX.Element[];
		jobCardsHide: JSX.Element[];
		titleToDelete: string;
		categoryToDelete: number;
		islock: boolean;
	}
> {
	constructor(props: any) {
		super(props);
		this.state = {
			jobsList: [],
			jobCardShow: [],
			jobCardsHide: [],
			titleToDelete: '',
			categoryToDelete: 0,
			islock: false
		};
	}

	jobLoad() {
		var count = 1;
		const jobsElements = this.state.jobsList
			.sort((a: any, b: any) => a.index.toString().localeCompare(b.index.toString()))
			.map((job: any) => {
				return (
					<JobCard
						key={job.title}
						title={job.title}
						category={job.categoryId}
						index={job.index}
						count={count++}
						isLock={this.state.islock}
						maxItem={this.state.jobsList.length}
						onDelete={(title: string, category: number) => {
							this.setState({ titleToDelete: title, categoryToDelete: category });
						}}
						onUp={(index: number, category: number) => {
							this.upJob(index, category);
						}}
						onDown={(index: number, category: number) => {
							this.downJob(index, category);
						}}></JobCard>
				);
			});
		if (this.state.jobsList.length > 3) {
			const showElement = jobsElements.slice(0, parseInt(this.props.nbpost)).map((job: any) => {
				return job;
			});
			const hiddenElement = jobsElements.slice(parseInt(this.props.nbpost), this.state.jobsList.length).map((job: any) => {
				return job;
			});
			this.setState({ jobCardShow: showElement });
			this.setState({ jobCardsHide: hiddenElement });
		} else {
			this.setState({ jobCardShow: jobsElements });
			this.setState({ jobCardsHide: [] });
		}
	}

	componentDidMount() {
		this.setState({ jobsList: this.props.jobs }, () => {
			this.jobLoad();
		});
	}

	async deleteJob() {
		await JobService.removeJobOfInterest(this.state.categoryToDelete.toString(), this.state.titleToDelete).then((result) => {
			const filtered = this.state.jobsList.filter((j: any) => {
				return j.title !== this.state.titleToDelete || j.categoryId !== this.state.categoryToDelete;
			});
			this.setState({ jobsList: filtered }, () => {
				this.jobLoad();
			});
		});
	}

	upJob(index: number, category: number) {
		this.setState({ islock: true }, () => {
			this.jobLoad();
			this.moveJobUp(index, category);
		});
	}

	async moveJobUp(index: number, category: number) {
		await JobService.moveUpJobOfInterest(category.toString(), index.toString()).then((result) => {
			const updated = this.state.jobsList.map((j: any) => {
				if (j.index === index - 1 && j.categoryId === category) {
					j.index = index;
				} else if (j.index === index && j.categoryId === category) {
					j.index = index - 1;
				}
				return j;
			});

			this.setState({ jobsList: updated, islock: false }, () => {
				this.jobLoad();
			});
		});
	}

	downJob(index: number, category: number) {
		this.setState({ islock: true }, () => {
			this.jobLoad();
			this.moveJobDown(index, category);
		});
	}

	async moveJobDown(index: number, category: number) {
		await JobService.moveDownJobOfInterest(category.toString(), index.toString()).then((result) => {
			const updated = this.state.jobsList.map((j: any) => {
				if (j.index === index && j.categoryId === category) {
					j.index = index + 1;
				} else if (j.index === index + 1 && j.categoryId === category) {
					j.index = index;
				}
				return j;
			});

			this.setState({ jobsList: updated, islock: false }, () => {
				this.jobLoad();
			});
		});
	}

	render() {
		return (
			<>
				<div className='fr-col-12'>
					<PageTitle
						imgLink=''
						size='h3'
						title={this.props.title}
					/>
				</div>
				<div className='fr-col-12'>
					<div className='fr-home'>
						<div className='form-container for-job-page'>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									{this.state.jobsList?.length == 0 && (
										<span>
											Vous n’avez aucun poste pour cette catégorie.{' '}
											<a
												title='Choisir un poste'
												href={'/vitrines?armyId=' + (localStorage.getItem('armyId') || '')}
												target='_blank'
												rel='noopener'>
												Choisir un poste
											</a>
										</span>
									)}
									{this.state.jobsList?.length > 0 && (
										<span>
											Postes vus par le conseiller.
											<br />
											&nbsp;
										</span>
									)}
								</div>
							</div>
							{this.state.jobCardShow}
						</div>
					</div>
					{this.state.jobCardsHide.length > 0 && (
						<div
							className='fr-home'
							style={{ background: 'white' }}>
							<div className='form-container for-job-page'>{this.state.jobCardsHide}</div>
						</div>
					)}
				</div>

				<Modal
					title={'Supprimer le poste'}
					size={ModalSize.Large}
					id={'delete-' + this.props.id}
					buttons={[
						{
							DisplayName: 'Supprimer le poste',
							OnClick: () => {
								this.deleteJob();
							}
						},
						{
							DisplayName: 'Annuler',
							Type: ButtonType.Secondary,
							OnClick: () => {
								var permis = '';
							}
						}
					]}>
					<>
						Vous êtes sur le point de vous supprimer le poste {this.state.titleToDelete}.<br />
						Êtes-vous sûr de vouloir supprimer ce poste ?
					</>
				</Modal>
			</>
		);
	}
}

export default JobCategorie;
