import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Button, { ButtonSize, ButtonType } from '../button/Button';
import Step from '../../models/Step';

interface Props {
	model: any;
	steps: Step[];
	forceNextStep: number;
	forcePreviousStep: number;
	reInitilized: () => void;
	submitForm: () => void;
}

function StepContainer({ steps, model, forceNextStep, forcePreviousStep, reInitilized, submitForm }: Props) {
	const [currentStepIndex, setCurrentStepIndex] = useState(0);
	const [isDisableNextButton, setIsDisableNextButton] = useState(true);

	useEffect(() => {
		if (forceNextStep != 0) {
			setCurrentStepIndex((currentStepIndex) => currentStepIndex + 1);
		}
	}, [forceNextStep]);

	useEffect(() => {
		if (forcePreviousStep != -1) {
			setCurrentStepIndex(forcePreviousStep);
			reInitilized();
		}
	}, [forcePreviousStep]);

	useEffect(() => {
		currentStepIsValid();
	}, [model, isDisableNextButton, currentStepIndex]);

	const handleValid = (e: any) => {
		if (steps[currentStepIndex].isFinish) {
			submitForm();
		} else {
			setCurrentStepIndex((currentStepIndex) => currentStepIndex + 1);
		}

		e.preventDefault();
	};

	const handlePrevious = (e: any) => {
		setCurrentStepIndex((currentStepIndex) => currentStepIndex - 1);
		e.preventDefault();
	};

	const currentStepIsValid = () => {
		const isvalid = steps[currentStepIndex].valid();
		setIsDisableNextButton(!isvalid);
	};

	return (
		<>
			{currentStepIndex === steps.length && (
				<Navigate
					replace
					to='/login'
				/>
			)}
			<div className='fr-stepper'>
				<span className='fr-stepper__state'>
					Étape {currentStepIndex + 1} sur {steps.length}
				</span>
				<h2 className='fr-stepper__title'>{steps[currentStepIndex].title}</h2>
				<div
					className='fr-stepper__steps'
					data-fr-current-step={currentStepIndex + 1}
					data-fr-steps={steps.length}></div>
				{currentStepIndex + 1 < steps.length && (
					<p className='fr-stepper__details'>
						<span className='fr-text--bold'>Étape suivante :</span> {steps[currentStepIndex + 1].title}
					</p>
				)}
			</div>
			{<div className='step'>{steps[currentStepIndex].children}</div>}
			{currentStepIndex > 0 && (
				<Button
					className='mobile-button fr-btn fr-btn--secondary'
					size={ButtonSize.Medium}
					text='Précédent'
					type={ButtonType.Primary}
					onClick={handlePrevious}
				/>
			)}
			{steps[currentStepIndex].isHideButtonValidationStep !== true && (
				<Button
					className='mobile-button'
					size={ButtonSize.Medium}
					text='Valider'
					type={ButtonType.Primary}
					disabled={isDisableNextButton}
					onClick={handleValid}
				/>
			)}
		</>
	);
}

export default StepContainer;
