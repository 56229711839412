import React from 'react';
import ConvertHTML from '../tools/ConvertHTML';
import { v4 as uuid } from 'uuid';

class Radio extends React.Component<
	{
		name?: string;
		title?: string;
		isHorizontal?: boolean;
		items: RadioItem[];
		onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
		disabled?: boolean;
		isSmall?: boolean;
		errorMessage?: string;
	},
	{}
> {
	render() {
		var isHorizontalStr = this.props.isHorizontal ? 'fr-fieldset--inline' : '';
		var isSmallSTr = this.props.isSmall ? 'fr-radio-group--sm' : '';
		var errorStr = this.props.errorMessage ? 'fr-fieldset--error' : '';
		var itemElements = this.props.items.map((x) => {
			return (
				<div
					key={x.DisplayName}
					className={'fr-radio-group ' + isSmallSTr}>
					<input
						type='radio'
						name={this.props.name}
						id={
							'radio-' +
							this.props.name +
							'-' +
							x.Value.toString() +
							(x.AppointmentModalityTypeSelected != undefined
								? '-' + x.AppointmentModalityTypeSelected.toString()
								: '')
						}
						value={
							x.Value +
							(x.AppointmentModalityTypeSelected != undefined
								? '-' + x.AppointmentModalityTypeSelected.toString()
								: '')
						}
						onChange={this.props.onChange}
						checked={x.Checked}></input>
					<label
						className={
							'fr-label '+ (x.IsAppointmentLabel != undefined
								? 'appointment'
								: '') 
						}						
						htmlFor={
							'radio-' +
							this.props.name +
							'-' +
							x.Value.toString() +
							(x.AppointmentModalityTypeSelected != undefined
								? '-' + x.AppointmentModalityTypeSelected.toString()
								: '')
						}>
						{x.DisplayName}
						<span className='fr-hint-text'>{x.HintText}</span>
					</label>
				</div>
			);
		});

		return (
			<div className='fr-form-group'>
				<fieldset
					id={this.props.name}
					className={'fr-fieldset ' + isHorizontalStr + ' ' + errorStr}
					disabled={this.props.disabled}
					aria-labelledby={this.props.errorMessage ? 'radio-error-legend radio-error-desc-error' : 'radio-legend'}
					role='group'>
					<legend
						className='fr-fieldset__legend fr-text--regular'
						id={this.props.errorMessage ? 'radio-error-legend' : 'radio-legend'}>
						{this.props.title}
					</legend>
					<div className='fr-fieldset__content'>{itemElements}</div>
					{this.props.errorMessage && (
						<p
							id='radio-error-desc-error'
							className='fr-error-text'>
							<ConvertHTML text={this.props.errorMessage} />
						</p>
					)}
				</fieldset>
			</div>
		);
	}
}

export interface RadioItem {
	DisplayName: string;
	Value: any;
	Checked?: boolean | undefined;
	HintText?: string;
	AppointmentModalityTypeSelected?: number;
	IsAppointmentLabel?: boolean | undefined;
}

export default Radio;
