import { Step } from '../models';

class StepService {
	public BuildStep(
		title: string,
		componentStep: JSX.Element,
		valid: () => boolean,
		isFinish?: boolean,
		isHideButtonValidationStep?: boolean
	): Step {
		return {
			title: title,
			valid: valid,
			children: componentStep,
			isFinish: isFinish,
			isHideButtonValidationStep: isHideButtonValidationStep
		};
	}
}

export default new StepService();
