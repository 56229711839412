import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import Modal, { ModalSize } from '../../components/modal/Modal';
import PageTitle from '../../components/page-title/PageTitle';
import TileContainer from '../../components/tile-container/TileContainer';
import Tile from '../../components/tile/Tile';
import { v4 as uuid } from 'uuid';
import ReferentialSelect from '../../components/referential-select/ReferentialSelect';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import DateService from '../../services/DateService';
import { NephosESLScopPoolEmploymentHistoryDetail } from '../../sparta.api';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import EnumGroupingType from '../../enums/EnumGroupingType';
import FieldCheckerService from '../../services/FieldCheckerService';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import { BrowserView, MobileView } from 'react-device-detect';
import TabItem from '../../components/tabs/TabItem';
import AttachmentUpload from '../../components/attachment-upload/AttachmentUpload';
import { AttachmentState } from '../../services/AttachmentService';
import Tabs from '../../components/tabs/Tabs';
import { TabHeaderItem } from '../../components/tabs/TabHeader';
import { createRef } from 'react';
interface ICompetencesExperiencesProfessionnellesState extends IProfileState {
	currentExperience?: NephosESLScopPoolEmploymentHistoryDetail;
	isExperienceLoading?: boolean;
	successMessage?: string;
	isUploadEmployerCertificateOrAdminConsent: boolean;
	isDeleteEmployerCertificateOrAdminConsent: boolean;
}

class CompetencesExperiencesProfessionnelles extends BaseComponent<ICompetencesExperiencesProfessionnellesState> {
	state: ICompetencesExperiencesProfessionnellesState = {
		isExperienceLoading: false,
		isEdit: false,
		isUploadEmployerCertificateOrAdminConsent: false,
		isDeleteEmployerCertificateOrAdminConsent: false
	};
	inputRef: any = createRef();
	experienceModalId: string = uuid();
	currentExperienceIndex: number = -1;

	groupingType: number = EnumGroupingType.COMPETENCES_EXPERIENCE_PROFESSIONNELLE;

	componentDidUpdate() {
		FieldCheckerService.check(
			EnumApplicantInputArmyType.EXPERIENCES_PROFESSIONNELLES_SITUATION_VIS_A_VIS_DE_L_EMPLOI,
			this.state.profile?.professional?.employmentStatus,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.EXPERIENCES_PROFESSIONNELLES_DATE_DE_DISPONIBILITE_AU_RECRUTEMENT,
			this.state.profile?.professional?.availabilityDate,
			this.requiredFields
		);
	}

	async checkIfCanDelete() {
		return await this.getRequiredFields();
	}

	setEmployerCertificateOrAdminConsent(guid?: string) {
		this.setState({
			profile: {
				...this.state.profile,
				professional: {
					...this.state.profile?.professional,
					employerCertificateOrAdminConsent: {
						id: guid,
						validationState: AttachmentState.WAITING_FOR_VALIDATION
					}
				}
			},
			isUploadEmployerCertificateOrAdminConsent: true
		});
	}

	deleteEmployerCertificateOrAdminConsent() {
		this.setState({
			profile: {
				...this.state.profile,
				professional: {
					...this.state.profile?.professional,
					employerCertificateOrAdminConsent: undefined
				}
			},
			isDeleteEmployerCertificateOrAdminConsent: true
		});
	}

	initUpload() {
		this.setState({
			isDeleteEmployerCertificateOrAdminConsent: false,
			isUploadEmployerCertificateOrAdminConsent: false
		});
	}

	tabs: TabHeaderItem[] =
		ReferentialsService.getArmy() === 1 || ReferentialsService.getArmy() === 2
			? [
					{
						displayName: 'Formulaire',
						id: 'form',
						tabIndex: 0,
						selected: true
					},
					{
						displayName: 'Pièces jointes',
						id: 'attachments',
						tabIndex: 1,
						selected: false
					}
			  ]
			: [{ displayName: 'Formulaire', id: 'form', tabIndex: 0, selected: true }];

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Expériences professionnelles civiles'
							description='Veuillez remplir les informations demandées ci-dessous'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.successMessage && this.state.groupingIsValidate == false && (
							<Alert
								description={this.state.successMessage}
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center  fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						<Tabs
							mobileOnly={true}
							isFormContainer={true}
							items={this.tabs}>
							<TabItem
								id='form'
								tabIndex={0}
								selected={true}
								mobileOnly={true}>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<h5>Ma situation professionnelle</h5>
										<ReferentialSelect
											label="Situation vis-à-vis de l'emploi"
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														professional: {
															...this.state.profile?.professional,
															employmentStatus: {
																code: e?.id,
																value: e?.name
															}
														}
													}
												});
											}}
											value={this.state.profile?.professional?.employmentStatus?.code}
											disabled={this.state.groupingIsValidate ?? false}
											type={ReferentialType.PROFESSIONAL_SITUATION}
										/>
									</div>
								</div>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<Input
											title='Date de disponibilité au recrutement'
											type={InputType.Date}
											disabled={this.state.groupingIsValidate ?? false}
											value={DateService.Convert(this.state.profile?.professional?.availabilityDate || '')}
											onChange={(e) => {
												this.setState({
													profile: {
														...this.state.profile,
														professional: {
															...this.state.profile?.professional,
															availabilityDate: DateService.Convert(e)
														}
													}
												});
											}}></Input>
									</div>
								</div>
								<BrowserView>
									{!this.state.groupingIsValidate && (
										<Button
											disabled={this.state.groupingIsValidate ?? false}
											onClick={() => {
												this.updateProfile(() => {
													this.setState({
														successMessage: `Les informations renseignées ont été enregistrées avec succès`
													});
													this.displaySuccess();
													this.initUpload();
												});
											}}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											iconClassName=''
											size={ButtonSize.Medium}
											modalId=''></Button>
									)}
								</BrowserView>
								<br></br>
								<br></br>
								<div className='fr-grid-row'>
									<div className='fr-col-12'>
										<h5>Mes expériences professionnelles civiles</h5>
										<p>
											Pour ajouter une expérience à votre dossier de candidature, veuillez cliquer sur le
											bouton “Ajouter une expérience”
										</p>
										<Button
											disabled={this.state.groupingIsValidate ?? false}
											size={ButtonSize.Medium}
											text='Ajouter une expérience'
											type={ButtonType.Primary}
											modalId={this.experienceModalId}
											onClick={() => {
												this.currentExperienceIndex = -1;
												this.setState(
													{ isEdit: false, currentExperience: {}, isExperienceLoading: true },
													() => {
														this.setState({ isExperienceLoading: false });
													}
												);
											}}></Button>
									</div>
								</div>
								<Modal
									title={this.state.isEdit ? 'Modifier une expérience' : 'Ajouter une expérience'}
									id={this.experienceModalId}
									size={ModalSize.Large}
									buttons={[
										{
											DisplayName: this.state.isEdit ? 'Modifier une expérience' : 'Ajouter une expérience',
											OnClick: () => {
												if (this.inputRef && this.inputRef.current && !this.inputRef.current.disabled) {
													this.inputRef.current.disabled = true;
													var experiences = [
														...(this.state.profile?.professional?.employmentHistoryDetails || [])
													];
													if (this.state.currentExperience) {
														if (this.currentExperienceIndex === -1) {
															experiences.push(this.state.currentExperience);
														} else {
															experiences[this.currentExperienceIndex] =
																this.state.currentExperience;
														}
													}
													this.setState(
														{
															successMessage: this.state.isEdit
																? "L'expérience a été modifié avec succès"
																: "L'expérience a été ajoutée avec succès",
															profile: {
																...this.state.profile,
																professional: {
																	...this.state.profile?.professional,
																	employmentHistoryDetails: experiences
																}
															}
														},
														() => {
															this.updateProfile();
															setTimeout(() => {
																this.inputRef.current.disabled = false;
															}, 2000);
														}
													);
												}
											}
										}
									]}>
									<>
										{!this.state.isExperienceLoading && (
											<>
												<Input
													title='Date de début'
													type={InputType.Date}
													value={DateService.Convert(this.state.currentExperience?.startDate || '')}
													onChange={(e) =>
														this.setState({
															currentExperience: { ...this.state.currentExperience, startDate: e }
														})
													}></Input>
												<Input
													title='Date de fin'
													type={InputType.Date}
													value={DateService.Convert(this.state.currentExperience?.endDate || '')}
													onChange={(e) =>
														this.setState({
															currentExperience: { ...this.state.currentExperience, endDate: e }
														})
													}></Input>
												<ReferentialSelect
													label='Type de contrat'
													onChange={(e) =>
														this.setState({
															currentExperience: {
																...this.state.currentExperience,
																contractTypeId: e?.id
															}
														})
													}
													value={this.state.currentExperience?.contractTypeId}
													type={ReferentialType.CONTRACT_TYPE}
												/>
												<Input
													title='Emploi tenu'
													type={InputType.Text}
													value={this.state.currentExperience?.jobTitle}
													onChange={(e) =>
														this.setState({
															currentExperience: { ...this.state.currentExperience, jobTitle: e }
														})
													}></Input>
												<Input
													title="Nom de l'entreprise"
													type={InputType.Text}
													value={this.state.currentExperience?.employer}
													onChange={(e) =>
														this.setState({
															currentExperience: { ...this.state.currentExperience, employer: e }
														})
													}></Input>
											</>
										)}
									</>
								</Modal>
								{this.state.profile?.professional?.employmentHistoryDetails &&
									this.state.profile?.professional?.employmentHistoryDetails.length > 0 && (
										<TileContainer>
											{this.state.profile?.professional?.employmentHistoryDetails?.map((x, i) => {
												return (
													<Tile
														key={x.jobTitle + '' + i}
														title={x.jobTitle || ''}
														description={
															this.state.groupingIsValidate ?? false ? '' : 'En cours de validation'
														}
														modalId={this.experienceModalId}
														disabled={this.state.groupingIsValidate ?? false}
														onDelete={async () => {
															let isGroupingValidated = await this.checkIfCanDelete();
															if (!isGroupingValidated) {
																var experiences = [
																	...(this.state.profile?.professional
																		?.employmentHistoryDetails || [])
																];
																experiences.splice(i, 1);
																this.setState(
																	{
																		successMessage:
																			"L'expérience a été supprimée avec succès",
																		profile: {
																			...this.state.profile,
																			professional: {
																				...this.state.profile?.professional,
																				employmentHistoryDetails: experiences
																			}
																		}
																	},
																	() => {
																		this.updateProfile();
																	}
																);
															}
														}}
														onEdit={() => {
															this.currentExperienceIndex = i;
															this.setState(
																{
																	isEdit: true,
																	currentExperience:
																		this.state.profile?.professional?.employmentHistoryDetails?.at(
																			i
																		),
																	isExperienceLoading: true
																},
																() => {
																	this.setState({ isExperienceLoading: false });
																}
															);
															this.setState({ isExperienceLoading: false });
														}}></Tile>
												);
											})}
										</TileContainer>
									)}
							</TabItem>
							{(ReferentialsService.getArmy() === 1 || ReferentialsService.getArmy() === 2) && (
								<>
									<BrowserView>
										<hr className='fr-hr desktop-only' />
										<h5 className='desktop-only'>Pièces jointes</h5>
									</BrowserView>
									<TabItem
										id='attachments'
										tabIndex={1}
										selected={false}
										mobileOnly={true}>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<span className='fr-hint-text'>Taille maximale : 10 Mo.</span>
												<span className='fr-hint-text'>Formats supportés : jpg, png, pdf...</span>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<AttachmentUpload
													noExplanation
													hintText="Attestation employeur ou consentement de l'administration"
													guid={this.state.profile?.professional?.employerCertificateOrAdminConsent?.id}
													onDelete={this.deleteEmployerCertificateOrAdminConsent.bind(this)}
													onUpload={this.setEmployerCertificateOrAdminConsent.bind(this)}
													isDisabled={this.state.groupingIsValidate ?? false}
													isUploadInProgress={this.state.isUploadEmployerCertificateOrAdminConsent}
													isDeleteInProgress={this.state.isDeleteEmployerCertificateOrAdminConsent}
												/>
											</div>
										</div>
									</TabItem>
									<MobileView>
										{!this.state.groupingIsValidate && (
											<div className='fr-grid-row fr-grid-row--center'>
												<div className='fr-col-8'>
													<Button
														disabled={this.state.groupingIsValidate ?? false}
														onClick={() => {
															this.updateProfile(() => {
																this.setState({
																	successMessage: `Les informations renseignées ont été enregistrées avec succès`
																});
																this.displaySuccess();
																this.initUpload();
															});
														}}
														className='mobile-button'
														text='Enregistrer mes informations'
														type={ButtonType.Primary}
														iconClassName=''
														size={ButtonSize.Medium}
														modalId=''></Button>
												</div>
											</div>
										)}
									</MobileView>
								</>
							)}
						</Tabs>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-8'>
										<Button
											className='mobile-button'
											disabled={false}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											iconClassName=''
											size={ButtonSize.Medium}
											modalId=''
											onClick={() => {
												this.updateProfile(() => {
													this.setState({
														successMessage: `Les informations renseignées ont été enregistrées avec succès`
													});
													this.displaySuccess();
													this.initUpload();
												});
											}}></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
			</Container>
		);
	}
}
export default CompetencesExperiencesProfessionnelles;
