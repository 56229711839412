import React from 'react';

class ButtonHeader extends React.Component<{ label: string; isActive: boolean; ariaControls: string }, {}> {
	render() {
		return (
			<>
				{this.props.isActive == true && (
					<button
						className='fr-nav__btn'
						aria-expanded='false'
						aria-controls={this.props.ariaControls}
						aria-current='true'>
						{this.props.label}
					</button>
				)}
				{this.props.isActive == false && (
					<button
						className='fr-nav__btn'
						aria-expanded='false'
						aria-controls={this.props.ariaControls}>
						{this.props.label}
					</button>
				)}
			</>
		);
	}
}
export default ButtonHeader;
