import { useEffect, useState } from 'react';
import AccountPublicService from '../../../services/public/AccountPublicService';
import FormatCheckService from '../../../services/FormatCheckService';
import Checkbox from '../../checkbox/Checkbox';
import Input, { InputType } from '../../input/Input';
import { EventCustomer } from '../../../models';
import { AccountStepProps } from '../../../models/ComponentStepProps';

function IdentifiantConnexionStep({ model, handleChange }: AccountStepProps) {
	const [emailErrorMessage, setEmailErrorMessage] = useState('');
	useEffect(() => {
		if (model.email === undefined || model.email === '') {
			setEmailErrorMessage('');
		} else if (model.email !== undefined && model.email !== '' && FormatCheckService.checkEmail(model.email)) {
			AccountPublicService.checkIfUserExist(model.email!, false).then(
				(resp: { hasApplicantAccount: boolean; errorMessage?: string; hasSpartaAccount?: boolean }) => {
					setEmailErrorMessage(!resp.hasApplicantAccount ? '' : resp.errorMessage ? resp.errorMessage : '');
					let eventHasSpartaAccount: EventCustomer = { name: 'hasSpartaAccount', value: resp.hasSpartaAccount };
					let eventHasApplicantAccount: EventCustomer = {
						name: 'hasApplicantAccount',
						value: resp.hasApplicantAccount
					};
					handleChange(eventHasSpartaAccount);
					handleChange(eventHasApplicantAccount);
				}
			);
		} else {
			setEmailErrorMessage(
				`Le format de l\’adresse électronique saisie n’est pas valide. Le format attendu est : nom@exemple.org`
			);
		}
	}, [model.email]);

	return (
		<div>
			<h5>Se créer un compte en renseignant une adresse électronique</h5>
			<p className='fr-hint-text account-creation-hint'>Sauf mention contraire, tous les champs sont obligatoires.</p>
			<Input
				title='Adresse électronique'
				description='Format attendu : nom@domaine.fr'
				name='email'
				type={InputType.Text}
				value={model.email || ''}
				onChangeEvent={(e: any) => handleChange(e)}
				errorMessage={emailErrorMessage}
			/>
			<Checkbox
				name='acceptance'
				items={[
					{
						Checked: model.acceptance ?? false,
						DisplayName: `J’accepte que les informations saisies dans le formulaire soient utilisées pour créer mon compte (SPARTA) et déclare accepter les conditions générales d’utilisation`,
						Value: model.acceptance ?? false
					}
				]}
				onChange={(e: any) => handleChange(e)}
			/>
			<p className='fr-text fr-text--xs'>
				Pour connaitre et exercer vos droits relatifs à l’utilisation des données collectées par ce formulaire, veuillez
				consulter{' '}
				<a
					href='#'
					title="Nos conditions générales d'utilisation"
					target='_blank'
					rel='noopener'>
					nos conditions générales d’utilisation
				</a>
				<br />À tout moment, vous pouvez retirer votre consentement et supprimer votre compte en vous connectant et en
				cliquant sur "Paramétrage, connexion et sécurité"
			</p>
		</div>
	);
}

export default IdentifiantConnexionStep;
