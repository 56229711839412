import React from 'react';
import { v4 as uuid } from 'uuid';

class Breadcrumb extends React.Component<{ items: BreadcrumbItem[] }, {}> {
	renderElement(display: string, link?: string | null) {
		if (link) {
			return (
				<a
					href={link}
					className='fr-breadcrumb__link'>
					{display}
				</a>
			);
		}

		return <span className='fr-breadcrumb__link'>{display}</span>;
	}

	render() {
		var itemElements = this.props.items.map((x) => {
			return <li key={x.DisplayName}>{this.renderElement(x.DisplayName, x.Link)}</li>;
		});

		return (
			<nav
				role='navigation'
				className='fr-breadcrumb'
				aria-label='vous êtes ici :'>
				<button
					className='fr-breadcrumb__button'
					aria-expanded='false'
					aria-controls='breadcrumb-1'>
					Voir le fil d’Ariane
				</button>
				<div
					className='fr-collapse'
					id='breadcrumb-1'>
					<ol className='fr-breadcrumb__list'>{itemElements}</ol>
				</div>
			</nav>
		);
	}
}

export interface BreadcrumbItem {
	DisplayName: string;
	Link?: string | null;
}

export default Breadcrumb;
