import http from '../http-common';
import AssociatedRecruiter from '../models/AssociatedRecruiter';
import { NephosESLScopPoolApplicantProfile } from '../sparta.api';

class ProfileService {
	private _baseURL: string = '/api/profiles';

	public profile?: NephosESLScopPoolApplicantProfile | null;

	constructor() {
		this.profile = null;
	}

	async get() {
		if (this.profile !== null) {
			return this.profile;
		}

		const getResp = await http.get<{ profile: NephosESLScopPoolApplicantProfile }>(this._baseURL);
		this.profile = getResp.data.profile;
		return this.profile;
	}

	update(profile?: NephosESLScopPoolApplicantProfile | null) {
		if (!this.profile) return;

		this.profile = profile;

		return http.put(this._baseURL, profile);
	}

	async getAssociatedRecruiter(armyId: string) {
		try {
			const getResp = await http.get<AssociatedRecruiter>(`${this._baseURL}/associated-recruiter` + `?armyId=${armyId}`);
			return getResp.data;
		} catch (error) {
			console.log(error);
		}
	}
}
export default new ProfileService();
