import { v4 as uuid } from 'uuid';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import AttachmentUpload from '../../components/attachment-upload/AttachmentUpload';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import Modal from '../../components/modal/Modal';
import PageTitle from '../../components/page-title/PageTitle';
import Radio from '../../components/radio/Radio';
import ReferentialSelect from '../../components/referential-select/ReferentialSelect';
import Select from '../../components/select/Select';
import TileContainer from '../../components/tile-container/TileContainer';
import Tile from '../../components/tile/Tile';
import Upload from '../../components/upload/Upload';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import EnumGroupingType from '../../enums/EnumGroupingType';
import { AttachmentState } from '../../services/AttachmentService';
import DateService from '../../services/DateService';
import FieldCheckerService from '../../services/FieldCheckerService';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import { NephosESLScopPoolDriverLicense } from '../../sparta.api';
import { NephosESLScopPoolDocument } from '../../sparta.api';
import { createRef } from 'react';
interface ICompetencesPermisState extends IProfileState {
	currentPermis?: NephosESLScopPoolDriverLicense;
	currentPermisIndex: number;
	isPermisLoading?: boolean;
	successMessage?: string;
	isUploadPermisRecto: boolean;
	isUploadPermisVerso: boolean;
	isDeletePermisRecto: boolean;
	isDeletePermisVerso: boolean;
}

class CompetencesPermis extends BaseComponent<ICompetencesPermisState> {
	state: ICompetencesPermisState = {
		currentPermisIndex: -1,
		isPermisLoading: false,
		isEdit: false,
		isUploadPermisRecto: false,
		isDeletePermisRecto: false,
		isUploadPermisVerso: false,
		isDeletePermisVerso: false
	};
	permisModalId: string = uuid();
	inputRef: any = createRef();
	groupingType: number = EnumGroupingType.COMPETENCES_PERMIS;

	componentDidUpdate() {
		FieldCheckerService.check(
			EnumApplicantInputArmyType.PERMIS_AVEZ_VOUS_UN_PERMIS,
			this.state.profile?.personal?.driverLicenses,
			this.requiredFields
		);
	}

	async checkIfCanDelete() {
		return await this.getRequiredFields();
	}

	setPermisAtIndex(index: number, guid?: string) {
		var rectoVersoAttachmentIds = this.state.currentPermis?.rectoVersoAttachmentIds
			? [...this.state.currentPermis?.rectoVersoAttachmentIds]
			: [];

		rectoVersoAttachmentIds[index] = {
			id: guid,
			validationState: AttachmentState.ADDED
		};

		this.setState({
			currentPermis: {
				...this.state.currentPermis,
				rectoVersoAttachmentIds: rectoVersoAttachmentIds
			},
			isUploadPermisRecto: this.state.isUploadPermisRecto || index === 0,
			isUploadPermisVerso: this.state.isUploadPermisVerso || index === 1
		});
	}

	deletePermisAtIndex(index: number) {
		var rectoVersoAttachmentIds = this.state.currentPermis?.rectoVersoAttachmentIds
			? [...this.state.currentPermis?.rectoVersoAttachmentIds]
			: [];

		if (rectoVersoAttachmentIds.length) {
			rectoVersoAttachmentIds[index] = {};
		}
		this.setState({
			currentPermis: {
				...this.state.currentPermis,
				rectoVersoAttachmentIds: rectoVersoAttachmentIds
			},
			isDeletePermisRecto: this.state.isDeletePermisRecto || index === 0,
			isDeletePermisVerso: this.state.isDeletePermisVerso || index === 1
		});
	}

	initUpload() {
		this.setState({
			isUploadPermisRecto: false,
			isDeletePermisRecto: false,
			isUploadPermisVerso: false,
			isDeletePermisVerso: false
		});
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Permis'
							description='Veuillez remplir les informations demandées ci-dessous'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.successMessage && this.state.groupingIsValidate == false && (
							<Alert
								description={this.state.successMessage}
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<FormContainer>
							<h5>Permis</h5>
							<p>Avez vous un permis ?</p>
							<Radio
								items={[
									{
										DisplayName: 'Oui',
										Value: 1,
										Checked: this.state.profile?.personal?.hasDriverLicense
									},
									{
										DisplayName: 'Non',
										Value: 0,
										Checked: !this.state.profile?.personal?.hasDriverLicense
									}
								]}
								disabled={this.state.groupingIsValidate ?? false}
								isHorizontal={false}
								isSmall={false}
								name='has-driving-license'
								title=''
								onChange={(e) => {
									this.setState({
										profile: {
											...this.state.profile,
											personal: {
												...this.state.profile?.personal,
												hasDriverLicense: e.currentTarget.value === '1'
											}
										},
										currentPermis: e.currentTarget.value === '1' ? {} : undefined
									});
								}}></Radio>
							{this.state.profile?.personal?.hasDriverLicense && (
								<Button
									text='Ajouter un permis'
									disabled={this.state.groupingIsValidate ?? false}
									size={ButtonSize.Medium}
									type={ButtonType.Primary}
									modalId={this.permisModalId}
									onClick={() => {
										this.setState(
											{
												isEdit: false,
												currentPermis: {
													licenseDate: '',
													licenseTypeId: 0,
													rectoVersoAttachmentIds: new Array<NephosESLScopPoolDocument>(
														{
															id: '00000000-0000-0000-0000-000000000000',
															validationState: 0
														},
														{
															id: '00000000-0000-0000-0000-000000000000',
															validationState: 0
														}
													)
												},
												currentPermisIndex: -1,
												isPermisLoading: true,
												isUploadPermisRecto: false,
												isDeletePermisRecto: false,
												isUploadPermisVerso: false,
												isDeletePermisVerso: false
											},
											() => {
												this.setState({ isPermisLoading: false });
											}
										);
									}}></Button>
							)}
							{!this.state.profile?.personal?.hasDriverLicense && (
								<Button
									text='Enregistrer mes informations'
									disabled={this.state.groupingIsValidate ?? false}
									size={ButtonSize.Medium}
									type={ButtonType.Primary}
									onClick={() => {
										this.updateProfile(() => {
											this.setState({
												successMessage: `Les informations renseignées ont été enregistrées avec succès`
											});
											this.displaySuccess();
											this.initUpload();
										});
									}}></Button>
							)}
							<TileContainer>
								{this.state.profile?.personal?.driverLicenses?.map((x, i) => {
									return (
										<Tile
											key={i + ' ' + x.licenseTypeId + '' + x.licenseDate}
											title=''
											onLoad={() =>
												ReferentialsService.getReferential(
													ReferentialType.DRIVING_LICENCE_TYPE,
													x.licenseTypeId ? x.licenseTypeId : 0
												)
											}
											description={this.state.groupingIsValidate ?? false ? '' : 'En cours de validation'}
											modalId={this.permisModalId}
											disabled={this.state.groupingIsValidate ?? false}
											onDelete={async () => {
												let isGroupingValidated = await this.checkIfCanDelete();
												if (!isGroupingValidated) {
													var permis = [...(this.state.profile?.personal?.driverLicenses || [])];
													permis.splice(i, 1);
													this.setState(
														{
															successMessage: 'Le permis a été supprimé avec succès',
															profile: {
																...this.state.profile,
																personal: {
																	...this.state.profile?.personal,
																	driverLicenses: permis,
																	hasDriverLicense: permis.length == 0 ? false : true
																}
															}
														},
														() => {
															this.updateProfile(() => {
																this.displaySuccess();
																this.initUpload();
															});
														}
													);
												}
											}}
											onEdit={() => {
												this.setState(
													{
														currentPermisIndex: i,
														isEdit: true,
														currentPermis: this.state.profile?.personal?.driverLicenses?.at(i),
														isPermisLoading: true,
														isDeletePermisVerso: false,
														isUploadPermisRecto: false,
														isDeletePermisRecto: false,
														isUploadPermisVerso: false
													},
													() => {
														this.setState({ isPermisLoading: false });
													}
												);
												this.setState({ isPermisLoading: false });
											}}></Tile>
									);
								})}
							</TileContainer>
							<Modal
								title={this.state.isEdit ? 'Modifier un permis' : 'Ajouter un permis'}
								id={this.permisModalId}
								buttons={[
									{
										Disabled: this.state.currentPermis?.licenseTypeId == 0,
										DisplayName: this.state.isEdit ? 'Modifier un permis' : 'Ajouter un permis',
										OnClick: () => {
											if (this.inputRef && this.inputRef.current && !this.inputRef.current.disabled) {
												this.inputRef.current.disabled = true;
												var permis = [...(this.state.profile?.personal?.driverLicenses || [])];
												if (this.state.currentPermis) {
													if (this.state.currentPermisIndex === -1) {
														permis.push(this.state.currentPermis);
													} else {
														permis[this.state.currentPermisIndex] = this.state.currentPermis;
													}
												}
												this.setState(
													{
														successMessage: this.state.isEdit
															? 'Le permis a été modifié avec succès'
															: 'Le permis a été ajouté avec succès',
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																driverLicenses: permis
															}
														}
													},
													() => {
														this.updateProfile(() => {
															this.displaySuccess();
															this.initUpload();
														});
														setTimeout(() => {
															this.inputRef.current.disabled = false;
														}, 2000);
													}
												);
											}
										}
									}
								]}>
								<>
									{!this.state.isPermisLoading && (
										<>
											<ReferentialSelect
												label='Type de permis'
												type={ReferentialType.DRIVING_LICENCE_TYPE}
												onChange={(e) => {
													this.setState({
														currentPermis: {
															...this.state.currentPermis,
															licenseTypeId: e?.id
														}
													});
												}}
												value={this.state.currentPermis?.licenseTypeId}></ReferentialSelect>
											<Input
												title="Date d'obtention"
												type={InputType.Date}
												value={
													this.state.currentPermis?.licenseDate
														? DateService.Convert(this.state.currentPermis?.licenseDate)
														: ''
												}
												onChange={(e) => {
													this.setState({
														currentPermis: {
															...this.state.currentPermis,
															licenseDate: DateService.Convert(e)
														}
													});
												}}></Input>
											<hr className='fr-hr'></hr>
											{(ReferentialsService.getArmy() === 1 || ReferentialsService.getArmy() === 2) && (
												<>
													<AttachmentUpload
														noExplanation
														title='Pièces jointes'
														hintText='Permis correspondant (recto)'
														isDisabled={this.state.groupingIsValidate ?? false}
														guid={this.state.currentPermis?.rectoVersoAttachmentIds?.at(0)?.id}
														validationState={
															this.state.currentPermis?.rectoVersoAttachmentIds?.at(0)
																?.validationState
														}
														onDelete={this.deletePermisAtIndex.bind(this, 0)}
														onUpload={this.setPermisAtIndex.bind(this, 0)}
														isUploadInProgress={this.state.isUploadPermisRecto}
														isDeleteInProgress={this.state.isDeletePermisRecto}
													/>
													<AttachmentUpload
														noExplanation
														hintText='Permis correspondant (verso)'
														isDisabled={this.state.groupingIsValidate ?? false}
														guid={this.state.currentPermis?.rectoVersoAttachmentIds?.at(1)?.id}
														validationState={
															this.state.currentPermis?.rectoVersoAttachmentIds?.at(1)
																?.validationState
														}
														onDelete={this.deletePermisAtIndex.bind(this, 1)}
														onUpload={this.setPermisAtIndex.bind(this, 1)}
														isUploadInProgress={this.state.isUploadPermisVerso}
														isDeleteInProgress={this.state.isDeletePermisVerso}
													/>
												</>
											)}
										</>
									)}
								</>
							</Modal>
						</FormContainer>
					</div>
				</div>
			</Container>
		);
	}
}
export default CompetencesPermis;
