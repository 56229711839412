import React from 'react';
import PageTitle from '../../components/page-title/PageTitle';
import Tabs from '../../components/tabs/Tabs';
import TabItem from '../../components/tabs/TabItem';
import Card from '../../components/card/Card';
import CardContainer from '../../components/card-container/CardContainer';
import DateService from '../../services/DateService';
import AppointmentService from '../../services/AppointmentService';

class Index extends React.Component<{}, { futureAppointmentCards: JSX.Element[]; pastAppointmentCards: JSX.Element[] }> {
	constructor(props: any) {
		super(props);
		this.state = {
			futureAppointmentCards: [],
			pastAppointmentCards: []
		};
	}

	buildcard(index: number, appointment: any) {
		var date = DateService.ConvertDateTimeLabel(appointment.startDate || '');
		var label_date = '' + date;

		return (
			<Card
				key={index}
				topDetail={{ IconClass: 'fr-icon-arrow-right-line', DisplayName: label_date }}
				title={appointment.displayName}
				hasBottomArrow={false}
				isHorizontal={true}
				linkUrl='#'
				links={[
					{
						DisplayName: 'Voir plus de detail',
						Link: '/rendez-vous/detail?id=' + appointment.id,
						IconClass: 'fr-icon-arrow-right-line'
					}
				]}
				description={appointment.modality}></Card>
		);
	}

	async componentDidMount() {
		var items = JSON.parse(localStorage.getItem('future_appointments') || '');
		if (items.length == 0) {
			await AppointmentService.getFutureAppointmentList(localStorage.getItem('armyId') || '').then((data: any) => {
				const appointmentElements = data.data.map((appointment: any, i: number) => {
					return this.buildcard(i, appointment);
				});
				localStorage.setItem('future_appointments', JSON.stringify(data.data));
				this.setState({ futureAppointmentCards: appointmentElements });
			});
		} else {
			const appointmentElements = items.map((appointment: any, i: number) => {
				return this.buildcard(i, appointment);
			});
			this.setState({ futureAppointmentCards: appointmentElements });
		}

		await AppointmentService.getPastAppointmentList(localStorage.getItem('armyId') || '').then((data: any) => {
			const appointmentElements = data.data.map((appointment: any, i: number) => {
				return this.buildcard(i, appointment);
			});
			localStorage.setItem('past_appointments', JSON.stringify(data.data));
			this.setState({ pastAppointmentCards: appointmentElements });
		});
	}

	render() {
		return (
			<>
				<div className='fr-container'>
					<div className='fr-grid-row'>
						<div className='fr-col-12'>
							<PageTitle
								imgLink=''
								size='h1'
								title='Mes rendez-vous'
								description='Trouvez ci-dessous tous vos rendez-vous à venir et passés.'
							/>
						</div>
						<div className='fr-col-12'>
							<Tabs
								mobileOnly={false}
								isFormContainer={true}
								items={[
									{
										displayName: 'À venir (' + this.state.futureAppointmentCards.length + ')',
										id: 'future',
										tabIndex: 0,
										selected: true
									},
									{
										displayName: 'Passés (' + this.state.pastAppointmentCards.length + ')',
										id: 'past',
										tabIndex: 1,
										selected: false
									}
								]}>
								<TabItem
									id='future'
									tabIndex={0}
									selected={true}
									mobileOnly={false}
									noBackroundGray={true}>
									{this.state.futureAppointmentCards.length > 0 && (
										<CardContainer>{this.state.futureAppointmentCards}</CardContainer>
									)}
									{this.state.futureAppointmentCards.length == 0 && (
										<div>
											Aucun rendez-vous à venir. Prenez un rendez-vous d'information avec un conseiller.
										</div>
									)}
								</TabItem>
								<TabItem
									id='past'
									tabIndex={1}
									selected={false}
									mobileOnly={false}
									noBackroundGray={true}>
									{this.state.pastAppointmentCards.length > 0 && (
										<CardContainer>{this.state.pastAppointmentCards}</CardContainer>
									)}
									{this.state.pastAppointmentCards.length == 0 && (
										<div>
											Aucun rendez-vous passé. Prenez un rendez-vous d'information avec un conseiller.
										</div>
									)}
								</TabItem>
							</Tabs>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default Index;
