import { baseURL } from '../../http-common';
import axios from 'axios';

export enum PasswordResetResponseStatus {
	SUCCESS,
	NOTFOUND,
	INTERNAL_SERVER_ERROR,
	UNKNOWN
}

export interface PasswordResetResponse {
	token?: string;
	status: PasswordResetResponseStatus;
}

class PasswordPublicService {
	private routeUrl: string = '/api/public/passwordreset';

	async requestPasswordReset(email: string): Promise<PasswordResetResponse> {
		const getResp = axios.get(baseURL + `${this.routeUrl}?email=${email}`, {
			validateStatus: function (status) {
				return true;
			}
		});

		const resp = await getResp;

		switch (resp.status) {
			case 200:
				return { token: resp.data.token, status: PasswordResetResponseStatus.SUCCESS };
			case 400:
				return { status: PasswordResetResponseStatus.NOTFOUND };
			default:
				return { status: PasswordResetResponseStatus.INTERNAL_SERVER_ERROR };
		}
	}

	async changePassword(token: string, password: string): Promise<PasswordResetResponseStatus> {
		const getResp = axios.post<{ password: string; token: string }>(
			baseURL + `${this.routeUrl}`,
			{ password: password, token: token },
			{
				validateStatus: function (status) {
					return true;
				}
			}
		);

		const resp = await getResp;

		switch (resp.status) {
			case 200:
				return PasswordResetResponseStatus.SUCCESS;
			case 400:
				return PasswordResetResponseStatus.NOTFOUND;
			default:
				return PasswordResetResponseStatus.INTERNAL_SERVER_ERROR;
		}
	}

	verify(password: string) {
		const valid =
			password.length >= 10 &&
			/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) &&
			/[0-9]/.test(password) &&
			/[a-zàâäéèêëiïîôöùûüÿçæœ]/.test(password) &&
			/[A-ZÂÊÎÔÛÄËÏÖÜÀÆÇÉÈŒÙ]/.test(password);
		return valid;
	}
}

export default new PasswordPublicService();
