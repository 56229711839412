/* Composant pour les titres des pages souvent présente en dessous du breadcrumb */

import React from 'react';
import ConvertHTML from '../tools/ConvertHTML';

import './0-PageTitle-mobile.scss';
import './1-PageTitle-desktop.scss';

class PageTitle extends React.Component<{ title: string; description?: string; imgLink?: string; size?: string }, {}> {
	renderImageElement() {
		if (this.props.imgLink?.length && this.props.imgLink!.length > 0) {
			return (
				<img
					className='fr-responsive-img sparta-page-title-icon'
					src={this.props.imgLink}
					alt=''></img>
			);
		}
		return '';
	}

	render() {
		return (
			<div className='fr-grid-row page-title'>
				<div className='fr-col-12'>
					{this.props.size == 'h1' && (
						<h1 className='fr-h1'>
							{this.renderImageElement()}
							{this.props.title}
						</h1>
					)}
					{(this.props?.size == null || this.props.size == 'h2') && (
						<h2 className='fr-h2'>
							{this.renderImageElement()}
							{this.props.title}
						</h2>
					)}
					{this.props.size == 'h3' && (
						<h3 className='fr-h3'>
							{this.renderImageElement()}
							{this.props.title}
						</h3>
					)}
					{this.props.description && (
						<span className='fr-text--regular'>
							<ConvertHTML text={this.props.description}></ConvertHTML>
						</span>
					)}
				</div>
			</div>
		);
	}
}

export default PageTitle;
