import React from 'react';
import Avatar from '../images/pictograms/avatar.png';
import Book from '../images/pictograms/book.png';
import Padlock from '../images/pictograms/padlock.png';
import Card from '../components/card/Card';
import { LabelType } from '../components/label/Label';
import CardContainer from '../components/card-container/CardContainer';
import PageTitle from '../components/page-title/PageTitle';
import Breadcrumb, { BreadcrumbItem } from '../components/breadcrumb/Breadcrumb';
import { ApplicationCardProperties } from './Applications';
import GroupingValidationService from '../services/GroupingValidationService';
import EnumGroupingType from '../enums/EnumGroupingType';
import DateService from '../services/DateService';
import ProfileService from '../services/ProfileService';

class ApplicationsMobile extends React.Component<{}, { groupingCards: JSX.Element[] }> {
	constructor(props: any) {
		super(props);
		this.state = {
			groupingCards: []
		};
	}

	private setCardImgAndIcon(groupingName: number): ApplicationCardProperties {
		switch (groupingName) {
			case EnumGroupingType.INFORMATIONS_PERSONNELLES:
				return { Icon: Avatar, Link: '/infos-perso' };
			case EnumGroupingType.COMPETENCES:
				return { Icon: Book, Link: '/competences' };
			case EnumGroupingType.SECURITE:
				return { Icon: Padlock, Link: '/securite' };
		}
		return { Icon: '', Link: '' };
	}

	async componentDidMount() {
		var isAdult = false;
		await ProfileService.get().then((profile) => {
			if (profile) {
				const dateOfBirth = profile?.personal?.identity?.dateOfBirth;
				if (DateService.getAgeFromString(dateOfBirth || '') >= 18) {
					isAdult = true;
				}
			}
		});

		await GroupingValidationService.getGroupingValidationsList(localStorage.getItem('armyId') || '').then((groupings) => {
			const groupingElements = groupings
				.filter((grouping) => grouping.type % 100 === 0)
				.map((grouping) => {
					var cardProperties = this.setCardImgAndIcon(grouping.type);
					const cardTagAndDesc = GroupingValidationService.getParentCardTagAndDesc(grouping.type, groupings, isAdult);
					return (
						<Card
							key={grouping.name}
							title={grouping.name}
							hasBottomArrow={true}
							isHorizontal={true}
							imgLink={cardProperties.Icon}
							linkUrl={cardProperties.Link}
							tags={[cardTagAndDesc.cardTag]}
							description={cardTagAndDesc.description}
							isDisabled={!grouping.isVisible}></Card>
					);
				});
			this.setState({ groupingCards: groupingElements });
		});
	}

	render() {
		const breadcrumbItems: BreadcrumbItem[] = [
			{
				DisplayName: 'Accueil',
				Link: '/'
			},
			{
				DisplayName: 'Mon dossier de candidature',
				Link: ''
			}
		];

		return (
			<div className='fr-container'>
				<div className='fr-grid-row'>
					<Breadcrumb items={breadcrumbItems}></Breadcrumb>
				</div>
				<div className='fr-grid-row'>
					<div className='fr-col-12'>
						<PageTitle
							imgLink=''
							title='Mon dossier de candidature'
							description="Vous trouverez ci-dessous l'accès à votre dossier de candidature"
						/>
					</div>
				</div>
				<div className='fr-grid-row'>
					<div className='fr-col-12'>
						<CardContainer>{this.state.groupingCards}</CardContainer>
					</div>
				</div>
			</div>
		);
	}
}

export default ApplicationsMobile;
