import React from 'react';
import ConvertHTML from '../tools/ConvertHTML';

import './0-Alert-mobile.scss';

interface IAlertState {
	show: boolean;
}

class Alert extends React.Component<
	{
		title?: string;
		description?: string;
		type?: AlertType;
		style?: React.CSSProperties;
		size?: AlertSize;
		hasCloseButton?: boolean;
		isShown?: boolean;
		onHide?: () => void;
	},
	{ show: boolean }
> {
	state: IAlertState = {
		show: this.props.isShown || false
	};

	constructor(props: any) {
		super(props);
		this.hide = this.hide.bind(this);
	}

	componentDidUpdate(prevProps: any) {
		if (prevProps.isShown !== this.props.isShown) {
			this.setState({ show: this.props.isShown || false });
		}
	}

	render() {
		var typeStr = 'info';
		var sizeStr = '';

		switch (this.props.type) {
			case AlertType.Error:
				typeStr = 'error';
				break;
			case AlertType.Success:
				typeStr = 'success';
				break;
			case AlertType.Warning:
				typeStr = 'warning';
				break;
			case AlertType.Info:
				typeStr = 'info';
				break;
		}

		switch (this.props.size) {
			case AlertSize.Small:
				sizeStr = 'fr-alert--sm';
				break;
		}

		return (
			this.state.show && (
				<div
					role={
						this.props.type == AlertType.Success
							? 'status'
							: this.props.type == AlertType.Error
							  ? 'error'
							  : this.props.type == AlertType.Warning
							    ? 'log'
							    : ''
					}
					className={'fr-alert fr-alert--' + typeStr + ' ' + sizeStr}
					style={this.props.style}>
					{this.props.size !== AlertSize.Small && <p className='fr-alert__title'>{this.props.title}</p>}
					<p>
						<ConvertHTML text={this.props.description || ''}></ConvertHTML>
					</p>
					{this.props.hasCloseButton && (
						<button
							className='fr-btn--close fr-btn'
							onClick={() => this.hide()}></button>
					)}
				</div>
			)
		);
	}

	private hide() {
		if (this.props.onHide) this.props.onHide();
		this.setState({ show: false });
	}
}

export enum AlertType {
	Error,
	Success,
	Warning,
	Info
}

export enum AlertSize {
	Small,
	Large
}

export default Alert;
