import React from 'react';

class Banner extends React.Component<
	{
		armyid: string;
	},
	{}
> {
	render() {
		switch (this.props.armyid) {
			case '1':
				return <div className='army-banner army-banner-adt'></div>;

			case '2':
				return <div className='army-banner army-banner-mn'></div>;

			case '3':
				return <div className='army-banner army-banner-air'></div>;

			default:
				return '';
		}
	}
}

export default Banner;
