/* Page Competences / Divers */

import React from 'react';
import RequiredField from '../models/RequiredField';
import GroupingValidationService from '../services/GroupingValidationService';
import { Navigate } from 'react-router-dom';
import profileService from '../services/ProfileService';
import { NephosESLScopPoolApplicantProfile } from '../sparta.api';
import { ReferentialsService } from '../services';

export interface IProfileState {
	profile?: NephosESLScopPoolApplicantProfile | null;
	isLoading?: boolean | null;
	isSuccess?: boolean | null;
	isGroupingValidationError?: boolean | null;
	groupingIsValidate?: boolean | null;
	isEdit?: boolean;
	isPixCertification?: boolean;
	showFreeCityFieldsForBirthCountryId?: boolean;
	showFreeCityFieldsForContactDetailsAddressCountryId?: boolean;
	showFreeCityFieldsForLegalRepresentativeAddressCountryId?: boolean;
	showFreeCityFieldsForSecurityDetailsPreviousAddressCountryId?: boolean;
	showCityFreeFieldsParentOne?: boolean;
	showCityFreeFieldsParentTwo?: boolean;
	showCityFreeFieldsPartner?: boolean;
	showAddressCityFreeFieldsParentOne?: boolean;
	showAddressCityFreeFieldsParentTwo?: boolean;
	showAddressCityFreeFieldsPartner?: boolean;
}

class BaseComponent<S extends IProfileState = IProfileState, P = {}> extends React.Component<P, S> {
	updateHooks: Array<() => void>;
	getHooks: Array<() => void>;
	requiredFields: RequiredField[] = [];
	optionalFields: RequiredField[] = [];
	groupingType: number = 0;

	constructor(props: any) {
		super(props);
		this.updateHooks = new Array<() => void>();
		this.getHooks = new Array<() => void>();
		if (!localStorage.getItem('auth')) {
			window.location.href = '/login?redirect_url=' + encodeURI(window.location.pathname);
		}
	}

	registerUpdateHook(hook: () => void) {
		this.updateHooks.push(hook.bind(this));
	}

	registerGetHook(hook: () => void) {
		this.getHooks.push(hook.bind(this));
	}

	async componentDidMount() {
		this.setState({ isLoading: true, groupingIsValidate: false });
		await this.getProfile();
		this.getRequiredFields();
	}

	async getProfile(successCallback?: () => void) {
		await profileService
			.get()
			.then((response: any) => {
				if (successCallback) successCallback();
				this.setState(
					{
						profile: response,
						isPixCertification: response.education?.pixCertification,
						showFreeCityFieldsForBirthCountryId:
							response.personal?.identity?.birthCountryId !== ReferentialsService.getCountryIdFrance(),
						showFreeCityFieldsForContactDetailsAddressCountryId:
							response.personal?.contactDetails?.address?.countryId !== ReferentialsService.getCountryIdFrance(),
						showFreeCityFieldsForLegalRepresentativeAddressCountryId:
							response.personal?.legalRepresentative?.address?.countryId !==
							ReferentialsService.getCountryIdFrance(),
						showFreeCityFieldsForSecurityDetailsPreviousAddressCountryId:
							response.securityDetails?.previousAddress?.countryId !== ReferentialsService.getCountryIdFrance(),
						showCityFreeFieldsParentOne:
							response.securityDetails?.familyMembers?.parentOne?.birthCountryId !==
							ReferentialsService.getCountryIdFrance(),
						showCityFreeFieldsParentTwo:
							response.securityDetails?.familyMembers?.parentTwo?.birthCountryId !==
							ReferentialsService.getCountryIdFrance(),
						showCityFreeFieldsPartner:
							response.securityDetails?.familyMembers?.partner?.birthCountryId !==
							ReferentialsService.getCountryIdFrance(),
						showAddressCityFreeFieldsParentOne:
							response.securityDetails?.familyMembers?.parentOne?.address?.countryId !==
							ReferentialsService.getCountryIdFrance(),
						showAddressCityFreeFieldsParentTwo:
							response.securityDetails?.familyMembers?.parentTwo?.address?.countryId !==
							ReferentialsService.getCountryIdFrance(),
						showAddressCityFreeFieldsPartner:
							response.securityDetails?.familyMembers?.partner?.address?.countryId !==
							ReferentialsService.getCountryIdFrance()
					},
					() => {
						this.setState({ isLoading: false });
						this.getHooks.forEach((hook) => hook());
					}
				);
			})
			.catch((error: any) => {
				this.setState({ isLoading: false });
				// Alerter l'utilisateur
			});
	}

	async getRequiredFields() {
		if (this.groupingType % 100 == 0) return;
		var isGroupingValidated = false;
		await GroupingValidationService.getGroupingValidation(localStorage.getItem('armyId') || '', this.groupingType).then(
			(result) => {
				isGroupingValidated = result.isValidated;
				this.setState({ groupingIsValidate: result.isValidated }, () => {
					if (result && result.requiredFieldTypes) {
						result.requiredFieldTypes.forEach((type: number) => {
							this.requiredFields.push({ Type: type, IsValid: false });
						});
						result.optionalFieldTypes.forEach((type: number) => {
							this.optionalFields.push({ Type: type, IsValid: false });
						});
						if (!this.requiredFields.length) {
							GroupingValidationService.updateGroupingValidation(
								localStorage.getItem('armyId') || '',
								true,
								this.groupingType,
								this.state.profile?.id || 0
							);
						}
					}
				});
			}
		);
		return isGroupingValidated;
	}

	updateProfile(successCallback?: () => void) {
		this.setState({ isLoading: true });

		GroupingValidationService.getGroupingValidation(localStorage.getItem('armyId') || '', this.groupingType).then(
			(result) => {
				if (result.isValidated == false) {
					profileService.update(this.state.profile)?.then((value) => {
						this.setState({ isLoading: false, isSuccess: true });

						GroupingValidationService.updateGroupingValidation(
							localStorage.getItem('armyId') || '',
							this.requiredFields.every((f) => f.IsValid),
							this.groupingType,
							this.state.profile?.id || 0
						);
					});
				} else {
					profileService.profile = null;
					profileService.get().then((profile) => {
						if (profile) {
							this.setState({ profile: profile, isLoading: false, isSuccess: false, groupingIsValidate: true, isGroupingValidationError: true });
						} else {
							this.setState({ isLoading: false, isSuccess: false, groupingIsValidate: true });
						}
					});
				}

				if (successCallback) successCallback();
				this.updateHooks.forEach((hook) => hook());
				window.location.href = window.location.href.replace(/#[A-Za-z-0-9-]*$/, '') + '#top-anchor';
			}
		);
	}

	displaySuccess = () => {
		this.setState({ isSuccess: true });
	};

	render() {
		return (
			<Navigate
				replace
				to={'/'}
			/>
		);
	}
}

export default BaseComponent;
