import http from '../../http-common';

class AttachmentPublicService {
	async post(file: File, confirmationCode: string, email: string, attachmentType: number) {
		var data = new FormData();
        data.append('file', file);
		data.append('confirmationCode', confirmationCode);
		data.append('email', email);
		data.append('attachmentType', attachmentType.toString());
		const getResp = http.post<{ guid?: string }>('/api/public/attachment', data);
		return (await getResp).data.guid;
	}
}
export default new AttachmentPublicService();