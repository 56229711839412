import React from 'react';
import { ActionMeta, createFilter } from 'react-select';
import ReferentialsService, { ReferentialType, NationItem } from '../../services/ReferentialsService';
import AsyncSelect from 'react-select/async';

import './0-ReferentialSelect-mobile.scss';
import { v4 as uuidv4 } from 'uuid';

interface INationSelectState {
	isLoading?: boolean;
	value?: any;
	fullValue?: any;
}

export default class NationSelect extends React.Component<{
	type: ReferentialType;
	value?: any | null;
	label?: string | null;
	description?: string | null;
	onChange?: (value?: any) => void;
	customOptionLabel?: (option?: any) => string;
	disabled?: boolean;
}> {
	page: number = 1;
	initialOption?: { id: number; name: string };
	state: INationSelectState = { isLoading: true };

	componentDidUpdate(prevProps: any) {
		if (prevProps.value !== this.props.value && this.props.value && this.props.value !== '0') {
			ReferentialsService.getNation(this.props.value).then((value) => {
				this.setState({
					isLoading: false,
					value: {
						id: value.id,
						name: this.props.customOptionLabel ? this.props.customOptionLabel(value) : value.name
					},
					fullValue: value
				});
			});
		}
	}

	handleInputChange = (newValue: NationItem) => {
		this.page = 1;
		return newValue.id;
	};

	render() {
		var disabledStr = this.props.disabled ? ' fr-select-group--disabled' : '';
		const selectid = uuidv4();
		const promiseOptions = (inputValue: string) =>
			new Promise<any[]>(async (resolve) => {
				await ReferentialsService.getNations(this.props.type == ReferentialType.NATIONALITY).then((data) => {
					var ar = [{ id: '', name: 'Sélectionnez', formattedNationality: '', shortName: '' }, ...data]
						.filter(
							(f) =>
								(this.props.type == ReferentialType.NATION &&
									f.shortName.toLowerCase().includes(inputValue.toLowerCase())) ||
								(this.props.type == ReferentialType.NATIONALITY &&
									f.formattedNationality.toLowerCase().includes(inputValue.toLowerCase()))
						)
						.map((i) => {
							return {
								id: i.id,
								name: this.props.customOptionLabel ? this.props.customOptionLabel(i) ?? i.name : i.name,
								shortname: i.shortName,
								nationality: i.formattedNationality
							};
						});
					resolve(ar);
				});
			});

		return (
			<div className={'fr-select-group' + disabledStr}>
				{(this.props.label || this.props.description) && (
					<label
						className='fr-label'
						htmlFor={selectid}
						style={{ color: '#161616' }}>
						<span>{this.props.label}</span>
						<span
							className='fr-hint-text'
							style={{ color: '#161616' }}>
							{this.props.description}
						</span>
					</label>
				)}
				<AsyncSelect
					onChange={(option: any | null, actionMeta: ActionMeta<any>) => {
						this.setState({
							value: { id: option.id, name: option.name }
						});
						if (this.props.onChange) this.props.onChange(option);
					}}
					inputId={selectid}
					isDisabled={this.props.disabled}
					className='sparta-select-container'
					classNamePrefix='sparta-select'
					value={this.state.value}
					loadOptions={promiseOptions}
					defaultValue={this.state.value ?? { id: '', name: 'Sélectionnez' }}
					defaultOptions={true}
					noOptionsMessage={(input) => 'Aucun résultat'}
					loadingMessage={(input) => 'Chargement...'}
					isOptionSelected={(o, s) => o.id == this.props.value}
					getOptionLabel={(o: any) => o.name}
					getOptionValue={(o: any) => '' + o.id}
					filterOption={createFilter({
						ignoreCase: true,
						ignoreAccents: true,
						trim: false
					})}
				/>
			</div>
		);
	}
}
