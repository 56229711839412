import PageTitle from '../../components/page-title/PageTitle';
import Input, { InputType } from '../../components/input/Input';
import { SelectItem } from '../../components/select/Select';
import Button, { ButtonSize, ButtonType } from '../../components/button/Button';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import { BrowserView, MobileView } from 'react-device-detect';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import EnumGroupingType from '../../enums/EnumGroupingType';
import FieldCheckerService from '../../services/FieldCheckerService';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import NationSelect from '../../components/referential-select/NationSelect';
import FormatCheckService from '../../services/FormatCheckService';
import ReferentialSelectOptimise from '../../components/referential-select/ReferentialSelectOptimise';

interface IContactState extends IProfileState {
	zipCode?: string;
	emailError?: string;
	phoneError?: string;
	otherPhoneError?: string;
}

class Contact extends BaseComponent<IContactState> {
	groupingType: number = EnumGroupingType.INFORMATIONS_PERSONNELLES_CONTACT;

	componentDidUpdate() {
		FieldCheckerService.check(
			EnumApplicantInputArmyType.CONTACT_ADRESSE,
			this.state.profile?.personal?.contactDetails?.address?.street,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.CONTACT_COMPLEMENT_D_ADRESSE,
			this.state.profile?.personal?.contactDetails?.address?.additionalInformation,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.CONTACT_CODE_POSTAL,
			this.state.profile?.personal?.contactDetails?.address?.cityId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.CONTACT_CODE_POSTAL,
			this.state.profile?.personal?.contactDetails?.address?.city?.zipCode,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.CONTACT_VILLE_OU_COMMUNE,
			this.state.profile?.personal?.contactDetails?.address?.cityId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.CONTACT_VILLE_OU_COMMUNE,
			this.state.profile?.personal?.contactDetails?.address?.city?.name,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.CONTACT_PAYS_DE_RESIDENCE,
			this.state.profile?.personal?.contactDetails?.address?.countryId,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.CONTACT_NUMERO_DE_TELEPHONE,
			this.state.profile?.personal?.contactDetails?.phoneNumbers?.at(0)?.number,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.CONTACT_AUTRE_NUMERO,
			this.state.profile?.personal?.contactDetails?.phoneNumbers?.at(1)?.number,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.CONTACT_ADRESSE_ELECTRONIQUE,
			this.state.profile?.personal?.contactDetails?.email,
			this.requiredFields
		);
	}

	constructor(props: any) {
		super(props);

		this.state = {
			zipCode: '',
			emailError: '',
			phoneError: '',
			otherPhoneError: ''
		};
	}

	validForm() {
		var phone1 = FormatCheckService.checkPhoneNumber(
			this.state.profile?.personal?.contactDetails?.phoneNumbers?.at(0)?.number || ''
		);
		var phone2 = FormatCheckService.checkPhoneNumber(
			this.state.profile?.personal?.contactDetails?.phoneNumbers?.at(1)?.number || ''
		);

		if (
			this.state.profile?.personal?.contactDetails?.phoneNumbers?.at(1)?.number == '' ||
			this.state.profile?.personal?.contactDetails?.phoneNumbers?.at(1)?.number == null
		) {
			phone2 = true;
		}

		this.setState({
			phoneError: phone1 ? '' : 'Le format du numéro de téléphone est erroné.',
			otherPhoneError: phone2 ? '' : 'Le format du numéro de téléphone est erroné.'
		});

		return phone1 && phone2;
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Contact'
							description='Veuillez vérifier et/ou remplir les champs d’informations ci-dessous'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.groupingIsValidate == false && (
							<Alert
								description='Les informations renseignées ont été enregistrées avec succès'
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						<FormContainer>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Adresse'
										description='Numéro et voie'
										value={this.state.profile?.personal?.contactDetails?.address?.street || ''}
										disabled={this.state.groupingIsValidate ?? false}
										type={InputType.Text}
										iconClass=''
										onChange={(e) =>
											this.setState({
												profile: {
													...this.state.profile,
													personal: {
														...this.state.profile?.personal,
														contactDetails: {
															...this.state.profile?.personal?.contactDetails,
															address: {
																...this.state.profile?.personal?.contactDetails?.address,
																street: e
															}
														}
													}
												}
											})
										}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Complément d’adresse'
										description='Bâtiment, immeuble, escalier et numéro d’appartement'
										value={this.state.profile?.personal?.contactDetails?.address?.additionalInformation || ''}
										disabled={this.state.groupingIsValidate ?? false}
										type={InputType.Text}
										iconClass=''
										onChange={(e) =>
											this.setState({
												profile: {
													...this.state.profile,
													personal: {
														...this.state.profile?.personal,
														contactDetails: {
															...this.state.profile?.personal?.contactDetails,
															address: {
																...this.state.profile?.personal?.contactDetails?.address,
																additionalInformation: e
															}
														}
													}
												}
											})
										}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<NationSelect
										label='Pays de résidence'
										onChange={(e) => {
											this.setState({
												showFreeCityFieldsForContactDetailsAddressCountryId: e?.name !== 'FRANCE',
												profile: {
													...this.state.profile,
													personal: {
														...this.state.profile?.personal,
														contactDetails: {
															...this.state.profile?.personal?.contactDetails,
															address: {
																...this.state.profile?.personal?.contactDetails?.address,
																countryId: e?.id,
																country: {
																	...this.state.profile?.personal?.contactDetails?.address
																		?.country,
																	name: e?.name,
																	shortName: e?.shortname,
																	nationality: e?.nationality
																},
																cityId: undefined,
																city: {
																	...this.state.profile?.personal?.contactDetails?.address
																		?.city,
																	id: undefined,
																	zipCode: e?.name === 'MONACO' ? '98000' : '',
																	name: ''
																}
															}
														}
													}
												}
											});
										}}
										value={this.state.profile?.personal?.contactDetails?.address?.countryId}
										disabled={this.state.groupingIsValidate ?? false}
										type={ReferentialType.NATION}
										customOptionLabel={(o: any) => o.shortName}
									/>
								</div>
							</div>
							{this.state.showFreeCityFieldsForContactDetailsAddressCountryId && (
								<>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title='Code postal'
												type={InputType.Text}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																contactDetails: {
																	...this.state.profile?.personal?.contactDetails,
																	address: {
																		...this.state.profile?.personal?.contactDetails?.address,
																		city: {
																			...this.state.profile?.personal?.contactDetails
																				?.address?.city,
																			zipCode: e
																		}
																	}
																}
															}
														}
													})
												}
												value={this.state.profile?.personal?.contactDetails?.address?.city?.zipCode}
												disabled={this.state.groupingIsValidate ?? false}></Input>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<Input
												title='Ville ou commune'
												type={InputType.Text}
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																contactDetails: {
																	...this.state.profile?.personal?.contactDetails,
																	address: {
																		...this.state.profile?.personal?.contactDetails?.address,
																		city: {
																			...this.state.profile?.personal?.contactDetails
																				?.address?.city,
																			name: e
																		}
																	}
																}
															}
														}
													})
												}
												value={this.state.profile?.personal?.contactDetails?.address?.city?.name}
												disabled={this.state.groupingIsValidate ?? false}></Input>
										</div>
									</div>
								</>
							)}
							{!this.state.showFreeCityFieldsForContactDetailsAddressCountryId && (
								<>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<ReferentialSelectOptimise
												label='Code postal'
												onChange={(e) =>
													this.setState({
														zipCode: e?.zipCode,
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																contactDetails: {
																	...this.state.profile?.personal?.contactDetails,
																	address: {
																		...this.state.profile?.personal?.contactDetails?.address,
																		cityId: undefined,
																		city: {
																			...this.state.profile?.personal?.contactDetails
																				?.address?.city,
																			id: e?.id,
																			zipCode: e?.zipCode
																		}
																	}
																}
															}
														}
													})
												}
												zipCode={this.state.profile?.personal?.contactDetails?.address?.city?.zipCode}
												value={this.state.profile?.personal?.contactDetails?.address?.city?.id}
												disabled={this.state.groupingIsValidate ?? false}
												type={ReferentialType.ZIPCODE}
												customOptionLabel={(o) => o.zipCode}
											/>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<ReferentialSelectOptimise
												label='Ville ou commune'
												onChange={(e) =>
													this.setState({
														profile: {
															...this.state.profile,
															personal: {
																...this.state.profile?.personal,
																contactDetails: {
																	...this.state.profile?.personal?.contactDetails,
																	address: {
																		...this.state.profile?.personal?.contactDetails?.address,
																		cityId: e?.id,
																		city: {
																			...this.state.profile?.personal?.contactDetails
																				?.address?.city,
																			id: e?.id,
																			zipCode: e?.zipCode,
																			name: e?.name != 'Sélectionnez' ? e?.name : undefined
																		}
																	}
																}
															}
														}
													})
												}
												zipCode={this.state.profile?.personal?.contactDetails?.address?.city?.zipCode}
												name={this.state.profile?.personal?.contactDetails?.address?.city?.name}
												value={this.state.profile?.personal?.contactDetails?.address?.cityId}
												disabled={this.state.groupingIsValidate ?? false}
												type={ReferentialType.CITY}
											/>
										</div>
									</div>
								</>
							)}
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Numéro de téléphone'
										description='Format attendu : (+33)xxxxxxxxx'
										value={this.state.profile?.personal?.contactDetails?.phoneNumbers?.at(0)?.number || ''}
										disabled={this.state.groupingIsValidate ?? false}
										errorMessage={this.state.phoneError}
										type={InputType.Tel}
										iconClass=''
										onChange={(e) => {
											if (e.startsWith('(+33)')) {
												if (e.length > 14) {
													return;
												}
											} else {
												if (e.length > 30) {
													return;
												}
											}
											var isValid = FormatCheckService.checkPhoneNumber(e);
											let phoneNumbers = [
												...(this.state.profile?.personal?.contactDetails?.phoneNumbers || [{}, {}])
											];
											phoneNumbers[0].number = e;
											this.setState({
												profile: {
													...this.state.profile,
													personal: {
														...this.state.profile?.personal,
														contactDetails: {
															...this.state.profile?.personal?.contactDetails,
															phoneNumbers: phoneNumbers
														}
													}
												},
												phoneError: isValid ? '' : 'Le format du numéro de téléphone est erroné.'
											});
										}}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Autre numéro'
										description='Format attendu : (+33)xxxxxxxxx'
										value={this.state.profile?.personal?.contactDetails?.phoneNumbers?.at(1)?.number || ''}
										disabled={this.state.groupingIsValidate ?? false}
										errorMessage={this.state.otherPhoneError}
										type={InputType.Tel}
										iconClass=''
										onChange={(e) => {
											if (e.startsWith('(+33)')) {
												if (e.length > 14) {
													return;
												}
											} else {
												if (e.length > 30) {
													return;
												}
											}
											var isValid = FormatCheckService.checkPhoneNumber(e);
											let phoneNumbers = [
												...(this.state.profile?.personal?.contactDetails?.phoneNumbers || [{}, {}])
											];
											if (!phoneNumbers[1]) phoneNumbers[1] = {};
											phoneNumbers[1].number = e;
											this.setState({
												profile: {
													...this.state.profile,
													personal: {
														...this.state.profile?.personal,
														contactDetails: {
															...this.state.profile?.personal?.contactDetails,
															phoneNumbers: phoneNumbers
														}
													}
												},
												otherPhoneError: isValid ? '' : 'Le format du numéro de téléphone est erroné.'
											});
										}}></Input>
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Input
										title='Adresse électronique'
										value={this.state.profile?.personal?.contactDetails?.email || ''}
										disabled={this.state.groupingIsValidate ?? false}
										type={InputType.Email}
										errorMessage={this.state.emailError}
										onChange={(email) => {
											this.setState({
												emailError: !FormatCheckService.checkEmail(email)
													? "Format d'adresse électronique incorrect"
													: '',
												profile: {
													...this.state.profile,
													personal: {
														...this.state.profile?.personal,
														contactDetails: {
															...this.state.profile?.personal?.contactDetails,
															email: email
														}
													}
												}
											});
										}}></Input>
								</div>
							</div>
							<BrowserView>
								{!this.state.groupingIsValidate && (
									<div className='fr-grid-row fr-grid-row--right'>
										<div className='fr-col-12 fr-col-md-6 fr-align-right'>
											<Button
												className='no-margin'
												disabled={!!this.state.emailError}
												text='Enregistrer mes informations'
												type={ButtonType.Primary}
												iconClassName=''
												size={ButtonSize.Medium}
												modalId=''
												onClick={() => {
													if (this.validForm() == true) {
														this.updateProfile(() => this.displaySuccess());
													}
												}}></Button>
										</div>
									</div>
								)}
							</BrowserView>
						</FormContainer>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-8'>
										<Button
											className='mobile-button'
											disabled={!!this.state.emailError}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											iconClassName=''
											onClick={() => {
												if (this.validForm() == true) {
													this.updateProfile(() => this.displaySuccess());
												}
											}}
											size={ButtonSize.Medium}
											modalId=''></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
			</Container>
		);
	}
}

export default Contact;
