import Document from '../images/pictograms/document.png';
import Avatar from '../images/pictograms/avatar.png';
import Book from '../images/pictograms/book.png';
import Padlock from '../images/pictograms/padlock.png';
import React from 'react';
import Select, { SelectItem } from '../components/select/Select';
import Card from '../components/card/Card';
import CardContainer from '../components/card-container/CardContainer';
import PageTitle from '../components/page-title/PageTitle';
import { LabelType } from '../components/label/Label';
import ReferentialsService from '../services/ReferentialsService';
import GroupingValidationService from '../services/GroupingValidationService';
import EnumGroupingType from '../enums/EnumGroupingType';
import Button, { ButtonSize, ButtonType } from '../components/button/Button';
import { BrowserView, MobileView } from 'react-device-detect';
import profileService from '../services/ProfileService';
import AssociatedRecruiter from '../models/AssociatedRecruiter';
import AppointmentService from '../services/AppointmentService';
import DateService from '../services/DateService';
import Link, { LinkSize } from '../components/link/Link';
import Banner from '../components/banner/Banner';
import conf from '../configuration/api.json';
import ChatService from '../services/ChatService';
import ProfileService from '../services/ProfileService';
import { v4 as uuid } from 'uuid';
import StatusTimeline from '../components/status-timeline/StatusTimeline';
import ApplicationStatus from '../models/ApplicationStatus';
import ApplicationStatusService from '../services/ApplicationStatusSevice';
import Alert, { AlertSize, AlertType } from '../components/alert/Alert';

declare global {
	interface Window {
		SpartaChat: Function;
	}
}

class Applications extends React.Component<
	{
		switchArmy: (e: string) => void;
	},
	{
		rdvId: string;
		armySelectItems: SelectItem[];
		groupingCards: JSX.Element[];
		appointmentCards: JSX.Element[];
		recruiter?: AssociatedRecruiter;
		loadingArmies: boolean;
		loadingRecruiter: boolean;
		isAdult: boolean;
		applicationStatus: ApplicationStatus[];
		isSyncError: boolean;
	}
> {
	constructor(props: any) {
		super(props);
		this.state = {
			rdvId: '',
			armySelectItems: [],
			groupingCards: [],
			appointmentCards: [],
			recruiter: undefined,
			loadingArmies: true,
			loadingRecruiter: true,
			isAdult: false,
			applicationStatus: [],
			isSyncError: false
		};
	}

	private setCardImgAndIcon(groupingName: number): ApplicationCardProperties {
		switch (groupingName) {
			case EnumGroupingType.INFORMATIONS_PERSONNELLES:
				return { Icon: Avatar, Link: '/infos-perso' };
			case EnumGroupingType.COMPETENCES:
				return { Icon: Book, Link: '/competences' };
			case EnumGroupingType.SECURITE:
				return { Icon: Padlock, Link: '/securite' };
		}
		return { Icon: '', Link: '' };
	}

	async componentDidMount() {
		document.title = 'Espace Candidat - Accueil';
		const newRdvId = uuid();
		localStorage.setItem('rdvId', newRdvId);
		this.setState({ rdvId: newRdvId });

		await ReferentialsService.getAvailableArmies().then((armies) => {
			if (armies) {
				const armyItems: SelectItem[] = armies.map((x) => {
					return { DisplayName: x.name, Value: x.id };
				});
				this.setState({ armySelectItems: armyItems, loadingArmies: false });
			}
		});

		await ProfileService.get().then((profile) => {
			if (profile) {
				const dateOfBirth = profile?.personal?.identity?.dateOfBirth;
				if (DateService.getAgeFromString(dateOfBirth || '') >= 18) {
					this.setState({ isAdult: true });
				}
			}
		});

		await GroupingValidationService.getGroupingValidationsList(localStorage.getItem('armyId') || '').then((groupings) => {
			const groupingElements = groupings
				.filter((grouping) => grouping.type % 100 === 0)
				.map((grouping) => {
					var cardProperties = this.setCardImgAndIcon(grouping.type);
					const cardTagAndDesc = GroupingValidationService.getParentCardTagAndDesc(
						grouping.type,
						groupings,
						this.state.isAdult
					);
					return (
						<Card
							key={grouping.name}
							title={grouping.name}
							hasBottomArrow={true}
							isHorizontal={true}
							imgLink={cardProperties.Icon}
							linkUrl={cardProperties.Link}
							tags={[cardTagAndDesc.cardTag]}
							description={cardTagAndDesc.description}
							isDisabled={!grouping.isVisible}></Card>
					);
				});
			this.setState({ groupingCards: groupingElements });
		});

		await this.loadAppointementsAndChatAndRecruiterAndApplicationStatus();
	}

	async loadApplicationStatus() {
		await ApplicationStatusService.get(localStorage.getItem('armyId') || '').then((data: any) => {
			if (data == 'ERR_BAD_REQUEST') {
				this.setState({
					isSyncError: true
				});
			} else {
				const applicationStatusList: ApplicationStatus[] = data.applicationStatuses.map((x: any) => {
					return {
						Order: x.order,
						ApplicantDisplayName: x.applicantDisplayName,
						Description: x.description,
						StatusHistoryDate: x.statusHistoryDate != undefined ? x.statusHistoryDate : '9999-11-30T00:00:00.000',
						IsCurrentApplicantStatus: x.isCurrentApplicantStatus
					};
				});
				this.setState({
					applicationStatus: ApplicationStatusService.ConvertToApplicationStatusListToDisplay(applicationStatusList)
				});
			}
		});
	}

	async loadAppointementsAndChatAndRecruiterAndApplicationStatus() {
		await this.loadAppointements();
		await this.loadApplicationStatus();
		var recruiter = await this.loadRecruiter();
		this.setState({
			recruiter: recruiter,
			loadingRecruiter: false
		});
		if (recruiter) {
			this.loadSpartaChat();
		}
	}

	async loadAppointements() {
		await AppointmentService.getFutureAppointmentList(localStorage.getItem('armyId') || '').then((data: any) => {
			const appointmentElements = data.data.map((appointment: any) => {
				var date = DateService.ConvertDateTimeLabel(appointment.startDate || '');
				var label_date = '' + date;

				return (
					<Card
						key={appointment.DisplayName}
						topDetail={{ IconClass: 'fr-icon-arrow-right-line', DisplayName: label_date }}
						title={appointment.displayName}
						hasBottomArrow={false}
						isHorizontal={true}
						linkUrl='#'
						links={[
							{
								DisplayName: 'Voir plus de détails',
								Link: '/rendez-vous/detail?id=' + appointment.id,
								IconClass: 'fr-icon-arrow-right-line'
							}
						]}
						description={appointment.modality}></Card>
				);
			});
			localStorage.setItem('future_appointments', JSON.stringify(data.data));
			this.setState({ appointmentCards: appointmentElements });
		});
	}

	loadSpartaChat() {
		ChatService.getSenderAndChannel(+localStorage.getItem('armyId')!).then((result) => {
			const authStorage = localStorage.getItem('auth');
			let auth = null;
			if (authStorage) {
				auth = JSON.parse(authStorage);
			}

			window.SpartaChat('#spartachat-wrapper', {
				displayText: 'Contacter votre conseiller',
				wsEndpoint: conf.CHAT_WS_URL,
				apiEndpoint: conf.API_URL + '/api/chat',
				channelId: result.channelId,
				senderId: `${result.senderId}`,
				headerText: this.state.armySelectItems.find((x) => x.Value === +localStorage.getItem('armyId')!)?.DisplayName,
				senderDisplayReturnEndpoint: conf.API_URL + '/api/chat',
				requestHeaders: `Bearer ${auth?.access_token}`
			});
		});
	}

	async loadRecruiter(): Promise<AssociatedRecruiter | undefined> {
		var result = undefined;
		await profileService.getAssociatedRecruiter(localStorage.getItem('armyId') || '').then((recruiter) => {
			result = recruiter;
		});
		return result;
	}

	render() {
		return (
			<div>
				{this.state.recruiter && (
					<div
						style={{ position: 'fixed', top: 0, width: '100%', height: '100%', zIndex: 9999, pointerEvents: 'none' }}
						id='spartachat-wrapper'></div>
				)}
				<Banner armyid={localStorage.getItem('armyId') || ''} />

				<div className='fr-container'>
					<div className='fr-grid-row'>
						<div
							className='fr-col-12'
							style={{ marginTop: '2rem' }}>
							<PageTitle
								imgLink=''
								size='h1'
								title='Espace candidat'
							/>
						</div>
					</div>
					{this.state.armySelectItems.length > 0 && (
						<div className='single-element'>
							<Select
								defaultOptionDisplayName=''
								title='Armée de référence'
								items={this.state.armySelectItems}
								description=''
								disabled={false}
								errorMessage=''
								onChange={(e) => {
									this.props.switchArmy(e);
									this.loadAppointementsAndChatAndRecruiterAndApplicationStatus();
								}}
								value={localStorage.getItem('armyId') || 1}></Select>
						</div>
					)}
				</div>

				<div className='fr-container'>
					<div className='fr-grid-row'>
						<div className='fr-col-12'>
							{this.state.armySelectItems.length > 0 && (
								<div className='fr-home'>
									<div className='form-container for-home-page'>
										<div className='fr-grid-row'>
											{this.state.isSyncError && (
												<div className='fr-col-md-12 fr-col-12'>
													<div className='fr-col-md-12 fr-col-12'>
														<PageTitle
															imgLink=''
															title='Suivi du Recrutement'
															description='Vous trouverez ci-dessous votre suivi de recrutement.'
														/>
													</div>
													<Alert
														size={AlertSize.Small}
														description="La visualisation du statut de votre dossier de candidature n'est pas disponible pour le moment. Veuillez réessayer ultérieurement."
														type={AlertType.Warning}
														isShown={this.state.isSyncError}
													/>
												</div>
											)}
											{!this.state.isSyncError && (
												<>
													<div className='fr-col-md-6 fr-col-12'>
														<PageTitle
															imgLink=''
															title='Suivi du Recrutement'
															description='Vous trouverez ci-dessous votre suivi de recrutement.'
														/>
													</div>
													{this.state.applicationStatus && this.state.applicationStatus.length > 0 && (
														<div className='fr-col-md-6 fr-col-12'>
															<StatusTimeline applicationStatus={this.state.applicationStatus} />
														</div>
													)}
												</>
											)}
										</div>
										<div className='separator'></div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<PageTitle
													imgLink=''
													title='Mes rendez-vous'
													description={
														this.state.appointmentCards.length > 0
															? 'Vous avez ' +
															  this.state.appointmentCards.length +
															  ' rendez-vous à venir'
															: 'Aucun rendez-vous à venir. Prenez un rendez-vous en contactant un conseiller.'
													}
												/>
											</div>
											{this.state.appointmentCards.length > 0 && (
												<>
													<div
														className='fr-col-12'
														style={{ marginBottom: '1rem' }}>
														<CardContainer>{this.state.appointmentCards}</CardContainer>
													</div>
													<div className='fr-col-12'>
														<Link
															iconClass='fr-icon-arrow-right-line'
															isIconLeft={false}
															link='/rendez-vous'
															size={LinkSize.Large}
															title='Accéder à mes rendez-vous'></Link>
													</div>
												</>
											)}
											{!this.state.recruiter && !this.state.loadingRecruiter && (
												<>
													<div className='fr-col-12 fr-col-lg-4'>
														<Button
															text='Prendre rendez-vous'
															type={ButtonType.Primary}
															size={ButtonSize.Medium}
															className='mobile-button'
															onClick={() =>
																(window.location.href = `/prise-de-rdv?army_id=${ReferentialsService.getArmy()?.toString()}&origin_id=0&rdv_id=${
																	this.state.rdvId
																}`)
															}></Button>
													</div>
												</>
											)}
										</div>

										<div className='separator'></div>

										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<PageTitle
													imgLink=''
													title='Mon dossier de candidature'
													description="Vous trouverez ci-dessous l'accès à votre dossier de candidature"
												/>
											</div>
										</div>
										{this.state.groupingCards.length > 0 && (
											<div className='fr-grid-row'>
												<div className='fr-col-12'>
													<div className='single-element mobile-global-application-card'>
														<Card
															key='0'
															title='Dossier de candidature'
															description=''
															isHorizontal={true}
															imgLink={Document}
															tags={[{ DisplayName: 'INCOMPLET', Type: LabelType.Error }]}
															hasBottomArrow={true}
															links={[]}
															linkUrl='applications-mobile'></Card>
													</div>
													<div className='card-container-desktop'>
														<CardContainer>{this.state.groupingCards}</CardContainer>
													</div>
												</div>
											</div>
										)}
										<div className='separator'></div>
									</div>
								</div>
							)}
							{this.state.loadingArmies && <div className='loader'></div>}
							{this.state.armySelectItems.length == 0 && !this.state.loadingArmies && (
								<p style={{ marginLeft: '30px' }}>Veuillez sélectionner une armée sur la page Mon compte</p>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export interface ApplicationCardProperties {
	Icon: string;
	Link: string;
}

export default Applications;
