import './0-MonCompte-Mobile.scss';
import { v4 as uuid } from 'uuid';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import Input, { InputType } from '../../components/input/Input';
import { ButtonType } from '../../components/button/Button';
import ReferentialsService from '../../services/ReferentialsService';
import { ReferentialType } from '../../services/ReferentialsService';
import Padlock from '../../images/pictograms/padlock.png';
import CityHall from '../../images/pictograms/city-hall.png';
import Community from '../../images/pictograms/community.png';
import Gendarmerie from '../../images/pictograms/gendarmerie.png';
import Notification from '../../images/pictograms/notification.png';
import SearchBar from '../../components/search-bar/SearchBar';
import CirfaPublicService, { CirfaItem } from '../../services/public/CirfaPublicService';
import CirfaCard from '../../components/cirfa-card/CirfaCard';
import Modal, { ModalSize } from '../../components/modal/Modal';
import AssociatedRecruiter from '../../models/AssociatedRecruiter';
import profileService from '../../services/ProfileService';
import PasswordPublicService, { PasswordResetResponseStatus } from '../../services/public/PasswordPublicService';
import Container from '../../components/container/Container';
import React, { MouseEventHandler } from 'react';
import QueryString, { ParsedQs } from 'qs';
import Toggle from '../../components/toggle/Toggle';
import PreferenceService from '../../services/PreferenceService';
import PreferenceCard from '../../components/preference-card/PreferenceCard';
import PageTitle from '../../components/page-title/PageTitle';

interface IMyAccountState {
	token?: ParsedQs;
	zipCode: string;
	cirfaID: number;
	selectedZipCode?: string;
	wrongZipcode: boolean;
	newCirfa?: CirfaItem;
	oldCirfa?: CirfaItem;
	cirfas?: CirfaItem[];
	currentRecruiter?: AssociatedRecruiter;
	AdtRecruiter?: AssociatedRecruiter;
	MarineRecruiter?: AssociatedRecruiter;
	AaeRecruiter?: AssociatedRecruiter;
	validationLength: boolean;
	password?: string;
	confirmPassword?: string;
	isLoading: false;
	validationSpecialCharacter: boolean;
	validationLowerCaseCharacter: boolean;
	validationUpperCaseCharacter: boolean;
	validationNumber: boolean;
	validationSamePassword?: boolean;
	isValid: boolean;
	isDeleteDataToggle: boolean;
	changePasswordResponse: PasswordResetResponseStatus;
	AAE: boolean;
	ADT: boolean;
	MN: boolean;
	oldAAE: boolean;
	oldADT: boolean;
	oldMN: boolean;
	emailCampaignNotification: boolean;
	instantMessagingNotification: boolean;
	otherNotification: boolean;
	oldEmailCampaignNotification: boolean;
	oldInstantMessagingNotification: boolean;
	oldOtherNotification: boolean;
	successMessage: string;
	errorMessage: string;
	armyName: string;
}

class MonCompte extends React.Component<{
	switchArmy: (e: string) => void;
}> {
	state: IMyAccountState = {
		armyName: '',
		oldCirfa: undefined,
		newCirfa: undefined,
		cirfaID: 0,
		zipCode: '',
		wrongZipcode: false,
		currentRecruiter: undefined,
		AdtRecruiter: undefined,
		MarineRecruiter: undefined,
		AaeRecruiter: undefined,
		isLoading: false,
		validationLength: false,
		validationSpecialCharacter: false,
		validationLowerCaseCharacter: false,
		validationUpperCaseCharacter: false,
		validationNumber: false,
		isDeleteDataToggle: false,
		changePasswordResponse: PasswordResetResponseStatus.UNKNOWN,
		isValid: false,
		AAE: false,
		ADT: false,
		MN: false,
		oldAAE: false,
		oldADT: false,
		oldMN: false,
		emailCampaignNotification: false,
		instantMessagingNotification: false,
		otherNotification: false,
		oldEmailCampaignNotification: false,
		oldInstantMessagingNotification: false,
		oldOtherNotification: false,
		successMessage: '',
		errorMessage: ''
	};
	army: any;

	constructor(props: any) {
		super(props);

		const parsedQueryString = QueryString.parse('this.props.location.search', {
			ignoreQueryPrefix: true
		});
		if (parsedQueryString.token) {
			this.state.token = parsedQueryString.token as ParsedQs;
		}
	}

	async getPreferencesInformations() {
		this.army = ReferentialsService.getArmy();
		try {
			if (this.army != 0) {
				ReferentialsService.getReferential(ReferentialType.ARMY_PUBLIC, +(ReferentialsService.getArmy() || '')).then(
					(x) => {
						var resp = { ...x.data };
						resp.name = resp.name as string;

						this.army = resp;
						this.setState({
							armyName: resp.name
						});
					}
				);

				await profileService.getAssociatedRecruiter(localStorage.getItem('armyId') || '').then((recruiter) => {
					this.setState({
						currentRecruiter: recruiter
					});
				});

				await profileService.getAssociatedRecruiter('1').then((recruiter) => {
					this.setState({
						AdtRecruiter: recruiter
					});
				});

				await profileService.getAssociatedRecruiter('2').then((recruiter) => {
					this.setState({
						MarineRecruiter: recruiter
					});
				});

				await profileService.getAssociatedRecruiter('3').then((recruiter) => {
					this.setState({
						AaeRecruiter: recruiter
					});
				});

				await PreferenceService.getCurrentCirfaPreferences().then((pref) => {
					this.setState({
						oldCirfa: pref
					});
				});
			}
		} catch (error) {
			console.log(error);
		}

		try {
			await PreferenceService.getArmyPreferences().then((pref) => {
				this.setState({
					AAE: pref.aae,
					ADT: pref.adt,
					MN: pref.mn,
					oldAAE: pref.aae,
					oldADT: pref.adt,
					oldMN: pref.mn
				});
			});
		} catch (error) {
			console.log(error);
		}

		try {
			await PreferenceService.getNotificationPreferences().then((pref) => {
				this.setState({
					emailCampaignNotification: pref.emailCampaignNotification,
					instantMessagingNotification: pref.instantMessagingNotification,
					otherNotification: pref.otherNotification,
					oldEmailCampaignNotification: pref.emailCampaignNotification,
					oldInstantMessagingNotification: pref.instantMessagingNotification,
					oldOtherNotification: pref.otherNotification
				});
			});
		} catch (error) {
			console.log(error);
		}
	}

	async componentDidMount() {
		await this.getPreferencesInformations();
	}

	rgpdModalId: string = uuid();
	deleteDataModalId: string = uuid();
	cirfaModalId: string = uuid();
	cirfaConfirmationModalId: string = uuid();
	cirfaWithRecruiterModalId: string = uuid();
	notificationModalId: string = uuid();
	armyModalId: string = uuid();
	passwordModalId: string = uuid();

	formatArmyName(armyName: string) {
		var resp = armyName;
		if (resp[0] === 'A') {
			resp = "l'" + resp;
		} else {
			resp = 'la ' + resp;
		}
		return resp;
	}

	searchCirfa() {
		this.setState(
			{
				isLoading: true,
				selectedZipCode: this.state.zipCode
			},
			() => {
				CirfaPublicService.getCirfasByZipCode(this.state.selectedZipCode || '', 0).then((resp) => {
					this.setState({
						isLoading: false,
						cirfas: resp
							? resp.map((data, key) => {
									return { ...data, isSelected: false };
							  })
							: null,
						wrongZipcode: resp ? false : true
					});
				});
			}
		);
	}

	onChangeAAE() {
		this.setState({
			AAE: !this.state.AAE
		});
	}

	onChangeMN() {
		this.setState({
			MN: !this.state.MN
		});
	}

	onChangeADT() {
		this.setState({
			ADT: !this.state.ADT
		});
	}

	onChangeIsDeleteDataTogle() {
		this.setState({
			isDeleteDataToggle: !this.state.isDeleteDataToggle
		});
	}

	onChangeEmailCampaignNotification() {
		this.setState({
			emailCampaignNotification: !this.state.emailCampaignNotification
		});
	}

	onChangeInstantMessagingNotification() {
		this.setState({
			instantMessagingNotification: !this.state.instantMessagingNotification
		});
	}

	onChangeOtherNotification() {
		this.setState({
			otherNotification: !this.state.otherNotification
		});
	}

	setSuccess(message: string) {
		this.setState({ successMessage: message, errorMessage: '' });
		window.location.href = window.location.href.replace(/#[A-Za-z-0-9-]*$/, '') + '#top-anchor';
	}

	setError(message: string) {
		this.setState({ errorMessage: message, successMessage: '' });
		window.location.href = window.location.href.replace(/#[A-Za-z-0-9-]*$/, '') + '#top-anchor';
	}

	onSaveArmyPref() {
		PreferenceService.putArmyPreferences(this.state.AAE, this.state.ADT, this.state.MN)
			.then(() => {
				this.setState({ oldCirfa: undefined });
				var armyId = localStorage.getItem('armyId') || '';
				switch(armyId)
				{
					case '1':
						if(this.state.ADT == false)
						{
							if (this.state.MN)
							{
								this.props.switchArmy('2');
							}
							else if(this.state.AAE)
							{
								this.props.switchArmy('3');
							}
							else
							{
								this.props.switchArmy('0');
							}
						}
						break;
					case '2':
						if(this.state.MN == false)
						{
							if (this.state.ADT)
							{
								this.props.switchArmy('1');
							}
							else if(this.state.AAE)
							{
								this.props.switchArmy('3');
							}
							else
							{
								this.props.switchArmy('0');
							}
						}
						break;
					case '3':
						if(this.state.AAE == false)
							{
								if (this.state.ADT)
								{
									this.props.switchArmy('1');
								}
								else if(this.state.MN)
								{
									this.props.switchArmy('2');
								}
								else
								{
									this.props.switchArmy('0');
								}
							}
						break;
					default:
						this.props.switchArmy('0');
						break;
				}
				this.getPreferencesInformations();
				this.setSuccess("Armées d'intérêt modifiées avec succès.");
			})
			.catch(() => {
				this.setError("Erreur lors de la modification des armées d'intérêt.");
			});
	}

	onSaveRGPDPref() {
		PreferenceService.postRGPDPreferences()
			.then(() => {
				this.setSuccess('RGPD modifié avec succès.');
			})
			.catch(() => {
				this.setError('Erreur lors de la modification du RGPD.');
			});
	}

	onSavePasswordPref() {
		if (this.state.password && this.state.confirmPassword && this.state.password === this.state.confirmPassword) {
			PreferenceService.putPasswordPreferences(this.state.password)
				.then(() => {
					this.setSuccess('Mot de passe modifié avec succès.');
				})
				.catch(() => {
					this.setError('Erreur lors de la modification du mot de passe.');
				});
		}
	}

	onSaveCirfaPref() {
		if (this.state.newCirfa) {
			PreferenceService.putCirfaPreferences(this.state.newCirfa)
				.then(() => {
					this.getPreferencesInformations();
					this.setSuccess('Cirfa modifié avec succès.');
				})
				.catch(() => {
					this.setError('Erreur lors de la modification du Cirfa.');
				});
		}
	}

	onSaveNotificationPref() {
		PreferenceService.putNotificationPreferences(
			this.state.emailCampaignNotification,
			this.state.instantMessagingNotification,
			this.state.otherNotification
		)
			.then(() => {
				this.getPreferencesInformations();
				this.setSuccess('Notifications modifiées avec succès.');
			})
			.catch(() => {
				this.setError('Erreur lors de la modification des notifications.');
			});
	}

	onChangePassword: MouseEventHandler = async (e) => {
		this.setState(
			{
				isLoading: true
			},
			() => {
				if (this.state.password && this.state.confirmPassword && this.state.password === this.state.confirmPassword) {
					PasswordPublicService.changePassword(this.state.token!.toString(), this.state.password).then(
						(resp: PasswordResetResponseStatus) => {
							this.setState({
								isLoading: false,
								changePasswordResponse: resp
							});
						}
					);
				}
			}
		);
	};

	validatePassword() {
		let newState = {
			validationLength: false,
			validationSpecialCharacter: false,
			validationNumber: false,
			isValid: false,
			validationLowerCaseCharacter: false,
			validationUpperCaseCharacter: false,
			validationSamePassword: this.state.validationSamePassword
		};

		if (this.state.password) {
			if (this.state.password.length >= 10) {
				newState.validationLength = true;
			}

			if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.state.password)) {
				newState.validationSpecialCharacter = true;
			}

			if (/[0-9]/.test(this.state.password)) {
				newState.validationNumber = true;
			}

			if (/[a-zàâäéèêëiïîôöùûüÿçæœ]/.test(this.state.password)) {
				newState.validationLowerCaseCharacter = true;
			}

			if (/[A-ZÂÊÎÔÛÄËÏÖÜÀÆÇÉÈŒÙ]/.test(this.state.password)) {
				newState.validationUpperCaseCharacter = true;
			}

			if (this.state.password === this.state.confirmPassword) {
				newState.validationSamePassword = true;
			}
		}

		this.setState(newState, () => this.isValidPassword());
	}

	isValidPassword() {
		this.setState({
			isValid:
				this.state.validationLength &&
				this.state.validationNumber &&
				this.state.validationSpecialCharacter &&
				this.state.validationLowerCaseCharacter &&
				this.state.validationUpperCaseCharacter &&
				this.state.validationSamePassword
		});
	}

	render() {
		return (
			<div>
				<div className='fr-container'>
					<div className='fr-grid-row'>
						<PageTitle
							title='Mon compte'
							description='Trouvez ci-dessous les informations relatives à votre compte'
						/>
					</div>
				</div>
				<div className='fr-container'>
					<div className='fr-grid-row fr-grid-row fr-grid-row--gutters'>
						<div className='fr-col-12 fr-col-lg-8'>
							<Alert
								description={this.state.successMessage}
								hasCloseButton={true}
								size={AlertSize.Large}
								type={AlertType.Success}
								style={{ width: 'fit-content' }}
								isShown={this.state.successMessage !== ''}></Alert>
							<Alert
								description={this.state.errorMessage}
								hasCloseButton={true}
								size={AlertSize.Large}
								type={AlertType.Error}
								isShown={this.state.errorMessage !== ''}></Alert>
						</div>

						<div className='fr-col-12'>
							<h3 style={{ fontSize: '24 px', marginBottom: '0px' }}>Informations de connexion</h3>
						</div>
						<PreferenceCard
							titleName='Mot de passe'
							description='******'
							modalId={this.passwordModalId}
							imgUrl={Padlock}
						/>
						{(this.state.oldADT == true || this.state.oldMN == true || this.state.oldAAE == true) && (
							<div className='fr-col-12'>
								<h3 style={{ fontSize: '24 px', marginBottom: '0px' }}>CIRFA de rattachement</h3>
							</div>
						)}
						{(this.state.oldADT == true || this.state.oldMN == true || this.state.oldAAE == true) && (
							<PreferenceCard
								titleName='CIRFA'
								description={
									this.state.armyName
										? this.state.armyName + ' - ' + (this.state.oldCirfa?.name ?? 'Aucun CIRFA')
										: 'Aucune armée selectionnée'
								}
								modalId={!this.state.currentRecruiter ? this.cirfaModalId : this.cirfaWithRecruiterModalId}
								imgUrl={CityHall}
							/>
						)}

						<div className='fr-col-12'>
							<h3 style={{ fontSize: '24 px', marginBottom: '0px' }}>Préférences</h3>
						</div>

						<PreferenceCard
							titleName="Armées d'intérêt"
							description=''
							modalId={this.armyModalId}
							imgUrl={Gendarmerie}
						/>

						<PreferenceCard
							titleName='Notifications'
							description=''
							modalId={this.notificationModalId}
							imgUrl={Notification}
						/>

						<div className='fr-col-12'>
							<h3 style={{ fontSize: '24 px', marginBottom: '0px' }}>Confidentialité</h3>
						</div>

						<PreferenceCard
							titleName='RGPD'
							description=''
							modalId={this.rgpdModalId}
							imgUrl={Community}
						/>
					</div>
				</div>

				<Modal
					size={ModalSize.Large}
					title='Changer de CIRFA'
					id={this.cirfaModalId}
					onClose={() => {
						this.setState({
							zipCode: '',
							selectedZipCode: '',
							cirfas: []
						});
					}}
					buttons={[]}
					children={
						<>
							<h6>Renseignez votre code postal</h6>
							<SearchBar
								label='Renseignez votre code postal'
								onClick={() => {
									this.searchCirfa();
								}}
								onChange={(e) => {
									this.setState({ zipCode: e });
								}}
								value={this.state.zipCode}
								onkeydown={(e) => {
									if (e === 'Enter') {
										this.searchCirfa();
									}
								}}
								isLarge={false}
								placeholder='Votre code postal (exemple. : 69700)'></SearchBar>
							<h6>Votre CIRFA de rattachement :</h6>
							<CirfaCard
								cirfa={this.state.oldCirfa}
								noButton={true}
								onClick={(id) => {
									this.setState({}, () => {});
								}}></CirfaCard>
							<br></br>
							<br></br>
							<h6>Liste des CIRFA disponibles pour le code postal : {this.state.selectedZipCode}</h6>
							<Alert
								description="Le code postal saisi n'existe pas"
								size={AlertSize.Small}
								type={AlertType.Warning}
								isShown={this.state.wrongZipcode}></Alert>
							{!this.state.selectedZipCode && (
								<span className='fr-text fr-text--xs'>Aucun code postal renseigné</span>
							)}
							{this.state.cirfas && (
								<div className='card-container card-container-cirfa row-2-elem'>
									{this.state.cirfas.map((cirfa) => (
										<CirfaCard
											key={cirfa.id}
											cirfa={cirfa}
											modalId={this.cirfaConfirmationModalId}
											onClick={(id) => {
												this.setState(
													{
														newCirfa: cirfa,
														cirfaID: id,
														zipCode: '',
														selectedZipCode: '',
														cirfas: []
													},
													() => {}
												);
											}}></CirfaCard>
									))}
								</div>
							)}
						</>
					}
				/>
				<Modal
					size={ModalSize.Large}
					title='Changer de CIRFA'
					id={this.cirfaConfirmationModalId}
					buttons={[
						{
							DisplayName: 'Changer de CIRFA',
							IconClass: '',
							OnClick: () => {
								this.onSaveCirfaPref();
								window.location.href = window.location.href.replace(/#[A-Za-z-0-9-]*$/, '') + '#top-anchor';
							}
						},
						{
							DisplayName: 'Conserver le CIRFA',
							Type: ButtonType.Secondary
						}
					]}
					children={
						<>
							<p>
								Vous allez changer votre CIRFA actuel {this.state.oldCirfa?.name} pour {this.state.newCirfa?.name}
								. Êtes-vous sûre de vouloir changer de CIRFA ?{' '}
							</p>
						</>
					}
				/>
				<Modal
					size={ModalSize.Large}
					title='CIRFA'
					id={this.cirfaWithRecruiterModalId}
					buttons={[
						{
							DisplayName: 'Contacter votre conseiller',
							IconClass: '',
							Link: '/'
						},
						{
							DisplayName: 'Annuler',
							IconClass: '',
							Type: ButtonType.Secondary
						}
					]}
					children={
						<>
							<p>
								Vous ne pouvez pas changer votre CIRFA de rattachement {this.state.oldCirfa?.name} de{' '}
								{this.formatArmyName(this.state.armyName)}. Pour plus d’informations, veuillez contacter votre
								conseiller.
							</p>
						</>
					}
				/>
				<Modal
					size={ModalSize.Large}
					title="Armée d'intérêt"
					id={this.armyModalId}
					buttons={[
						{
							DisplayName: 'Enregistrer mes choix',
							IconClass: '',
							OnClick: () => {
								this.onSaveArmyPref();
							},
							Disabled:
								this.state.oldAAE === this.state.AAE &&
								this.state.oldMN === this.state.MN &&
								this.state.oldADT === this.state.ADT
						}
					]}
					children={
						<>
							<Toggle
								title='Armée de terre'
								description='En cochant cette case, vous confirmez votre intérêt pour cette Armée. Vous serez désormais visible pour les recruteurs de cette Armée.'
								disabled={this.state.AdtRecruiter?.fullName != undefined}
								value='toggle-1'
								isLabelLeft={true}
								checked={this.state.ADT}
								onChange={() => {
									this.onChangeADT();
								}}
							/>
							<Toggle
								title='Marine nationale'
								description='En cochant cette case, vous confirmez votre intérêt pour cette Armée. Vous serez désormais visible pour les recruteurs de cette Armée.'
								disabled={this.state.MarineRecruiter?.fullName != undefined}
								value='toggle-2'
								isLabelLeft={true}
								checked={this.state.MN}
								onChange={() => {
									this.onChangeMN();
								}}
							/>
							<Toggle
								title='Armée de l’air et de l’espace'
								description='En cochant cette case, vous confirmez votre intérêt pour cette Armée. Vous serez désormais visible pour les recruteurs de cette Armée.'
								disabled={this.state.AaeRecruiter?.fullName != undefined}
								value='toggle-3'
								isLabelLeft={true}
								checked={this.state.AAE}
								onChange={() => {
									this.onChangeAAE();
								}}
							/>
						</>
					}
				/>
				<Modal
					size={ModalSize.Large}
					title='Notifications'
					id={this.notificationModalId}
					buttons={[
						{
							DisplayName: 'Enregistrer mes choix',
							IconClass: '',
							OnClick: () => {
								this.onSaveNotificationPref();
								window.location.href = window.location.href.replace(/#[A-Za-z-0-9-]*$/, '') + '#top-anchor';
							},
							Disabled:
								this.state.oldEmailCampaignNotification === this.state.emailCampaignNotification &&
								this.state.oldInstantMessagingNotification === this.state.instantMessagingNotification &&
								this.state.oldOtherNotification === this.state.otherNotification
						}
					]}
					children={
						<>
							<Toggle
								title='Réception des campagnes de mails'
								description='En cochant cette case, vous confirmez vouloir recevoir des notifications de campagnes de mails.'
								disabled={false}
								value='toggle-4'
								isLabelLeft={true}
								checked={this.state.emailCampaignNotification}
								onChange={() => {
									this.onChangeEmailCampaignNotification();
								}}
							/>
							<Toggle
								title='Réception de mails de la messagerie instantanée'
								description='En décochant cette case, vous confirmez ne plus vouloir recevoir de notifications par mail pour la messagerie instantanée.'
								disabled={false}
								value='toggle-5'
								isLabelLeft={true}
								checked={this.state.instantMessagingNotification}
								onChange={() => {
									this.onChangeInstantMessagingNotification();
								}}
							/>
							<Toggle
								title='Réception des autres notifications par mail du système'
								description='En cochant cette case, vous confirmez vouloir recevoir des notifications par mail du système.'
								disabled={false}
								value='toggle-6'
								isLabelLeft={true}
								checked={this.state.otherNotification}
								onChange={() => {
									this.onChangeOtherNotification();
								}}
							/>
						</>
					}
				/>
				<Modal
					size={ModalSize.Large}
					title='RGPD'
					id={this.rgpdModalId}
					onClose={() => {
						this.setState({
							isDeleteDataToggle: false
						});
					}}
					buttons={[
						{
							DisplayName: 'Enregistrer mon choix',
							IconClass: '',
							ModalId: this.deleteDataModalId,
							Disabled: this.state.isDeleteDataToggle === false
						}
					]}
					children={
						<Toggle
							title='Effacement de vos données personnelles'
							description="Votre demande ne sera pas traitée automatiquement, elle sera renvoyée au préalable à un administrateur du MINARM qui se chargera de sa prise en compte et de son traitement selon les règles et normes en vigueur RGPD CNIL. Le traitement de vos données à caractère personnel est soumis au respect du Règlement Général sur la Protection des Données (RGPD).<p><a style='color: #000091;' href='https://www.cnil.fr/' title='CNIL - RGPD' target='_blank' rel='noopener'>CNIL - RGPD</a></p>"
							disabled={false}
							value='toggle-7'
							isLabelLeft={true}
							checked={this.state.isDeleteDataToggle}
							onChange={() => {
								this.onChangeIsDeleteDataTogle();
							}}
						/>
					}
				/>
				<Modal
					size={ModalSize.Large}
					title='Effacer mes données'
					id={this.deleteDataModalId}
					buttons={[
						{
							DisplayName: 'Effacer mes données',
							IconClass: '',
							Type: ButtonType.Primary,
							OnClick: () => {
								this.onSaveRGPDPref();
								window.location.href = window.location.href.replace(/#[A-Za-z-0-9-]*$/, '') + '#top-anchor';
							}
						},
						{
							DisplayName: 'Conserver mes données',
							IconClass: '',
							Type: ButtonType.Secondary
						}
					]}
					children={
						<>
							<p>
								Êtes-vous sûr(e) de vouloir effacer vos données ? Si vous disposez d'autres données pour
								différentes armées, elles seront également effacées.
							</p>
						</>
					}
				/>
				<Modal
					size={ModalSize.Large}
					title='Mot de passe'
					id={this.passwordModalId}
					children={
						<>
							<Input
								title='Mot de passe'
								type={InputType.Password}
								value={this.state.password}
								onChange={(e) => {
									this.setState(
										{
											password: e,
											validationSamePassword: this.state.confirmPassword === e
										},
										() => this.validatePassword()
									);
								}}></Input>
							<div className='change-password-rules'>
								<p className='fr-hint-text'>Votre mot de passe doit contenir:</p>
								<span
									className={
										'fr-hint-text fr-text-default--' + (this.state.validationLength ? 'success' : 'info')
									}>
									<span
										className={
											'fr-icon--sm fr-icon-' + (this.state.validationLength ? 'success-fill' : 'info-fill')
										}></span>{' '}
									10 caractères minimum
								</span>
								<span
									className={
										'fr-hint-text fr-text-default--' +
										(this.state.validationSpecialCharacter ? 'success' : 'info')
									}>
									<span
										className={
											'fr-icon--sm fr-icon-' +
											(this.state.validationSpecialCharacter ? 'success-fill' : 'info-fill')
										}></span>{' '}
									1 caractère spécial
								</span>
								<span
									className={
										'fr-hint-text fr-text-default--' +
										(this.state.validationLowerCaseCharacter ? 'success' : 'info')
									}>
									<span
										className={
											'fr-icon--sm fr-icon-' +
											(this.state.validationLowerCaseCharacter ? 'success-fill' : 'info-fill')
										}></span>{' '}
									1 caractère minuscule
								</span>
								<span
									className={
										'fr-hint-text fr-text-default--' +
										(this.state.validationUpperCaseCharacter ? 'success' : 'info')
									}>
									<span
										className={
											'fr-icon--sm fr-icon-' +
											(this.state.validationUpperCaseCharacter ? 'success-fill' : 'info-fill')
										}></span>{' '}
									1 caractère majuscule
								</span>
								<span
									className={
										'fr-hint-text fr-text-default--' + (this.state.validationNumber ? 'success' : 'info')
									}>
									<span
										className={
											'fr-icon--sm fr-icon-' + (this.state.validationNumber ? 'success-fill' : 'info-fill')
										}></span>{' '}
									1 chiffre minimum
								</span>
							</div>
							<Input
								title='Confirmation du mot de passe'
								type={InputType.Password}
								value={this.state.confirmPassword}
								onChange={(e) => {
									this.setState(
										{
											confirmPassword: e,
											validationSamePassword: this.state.password === e
										},
										() => this.isValidPassword()
									);
								}}
								errorMessage={
									this.state.validationSamePassword === false
										? 'Le champ "Confirmation du mot de passe" doit être similaire au champ "Mot de passe"'
										: undefined
								}></Input>
						</>
					}
					buttons={[
						{
							DisplayName: 'Enregistrer mes choix',
							IconClass: '',
							Disabled: !this.state.isValid,
							OnClick: () => {
								this.onSavePasswordPref();
								window.location.href = window.location.href.replace(/#[A-Za-z-0-9-]*$/, '') + '#top-anchor';
							}
						}
					]}
				/>
			</div>
		);
	}
}

export default MonCompte;
