import { BrowserView, MobileView } from 'react-device-detect';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Button, { ButtonIconState, ButtonSize, ButtonType } from '../../components/button/Button';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import PageTitle from '../../components/page-title/PageTitle';
import Radio from '../../components/radio/Radio';
import ReferentialSelect from '../../components/referential-select/ReferentialSelect';
import Select from '../../components/select/Select';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import EnumGroupingType from '../../enums/EnumGroupingType';
import FieldCheckerService from '../../services/FieldCheckerService';
import { ReferentialType } from '../../services/ReferentialsService';
import FormatCheckService from '../../services/FormatCheckService';
import React from 'react';
import { v4 as uuid } from 'uuid';
import Modal, { ModalSize } from '../../components/modal/Modal';

interface IEnvironnementNumeriqueState extends IProfileState {
	phoneNumberError: string[];
	emailError: string[];
}

class EnvironnementNumerique extends BaseComponent<IEnvironnementNumeriqueState> {
	state: IEnvironnementNumeriqueState = {
		phoneNumberError: [],
		emailError: [],
		isEdit: false
	};

	groupingType = EnumGroupingType.SECURITE_ENVIRONNEMENT_NUMERIQUE;
	securityInformationModalId: string = uuid();

	componentDidUpdate() {
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ENVIRONNEMENT_NUMERIQUE_UTILISEZ_VOUS_LES_RESEAUX_SOCIAUX,
			true,
			this.requiredFields
		);
		FieldCheckerService.check(
			EnumApplicantInputArmyType.ENVIRONNEMENT_NUMERIQUE_UTILISEZ_VOUS_D_AUTRES_NUMEROS_OU_ADRESSES_ELECTRONIQUES,
			true,
			this.requiredFields
		);
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Environnement numérique'
							description='Certains champs concernant vos informations personnelles sont automatiquement remplies. Veuillez vérifier l’exactitude des informations.'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.groupingIsValidate == false && (
							<Alert
								description='Les informations renseignées ont été enregistrées avec succès'
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<FormContainer>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Radio
										isHorizontal={false}
										items={[
											{
												DisplayName: 'Oui',
												Value: '1',
												Checked:
													this.state.profile?.securityDetails?.socialNetworkDetails
														?.isUsingSocialNetwork
											},
											{
												DisplayName: 'Non',
												Value: '0',
												Checked:
													!this.state.profile?.securityDetails?.socialNetworkDetails
														?.isUsingSocialNetwork
											}
										]}
										isSmall={false}
										name='social-network-use'
										title='Utilisez-vous des réseaux sociaux ?'
										disabled={this.state.groupingIsValidate ?? false}
										onChange={(e) => {
											this.setState({
												profile: {
													...this.state.profile,
													securityDetails: {
														...this.state.profile?.securityDetails,
														socialNetworkDetails: {
															isUsingSocialNetwork: e.currentTarget.value === '1',
															socialNetworks: [{}],
															otherEmails:
																this.state.profile?.securityDetails?.socialNetworkDetails
																	?.otherEmails,
															otherPhoneNumbers:
																this.state.profile?.securityDetails?.socialNetworkDetails
																	?.otherPhoneNumbers
														}
													}
												}
											});
										}}></Radio>
								</div>
							</div>
							{this.state.profile?.securityDetails?.socialNetworkDetails?.socialNetworks?.map((x: any, i) => {
								return (
									<React.Fragment key={i}>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<Input
													disabled={
														!this.state.profile?.securityDetails?.socialNetworkDetails
															?.isUsingSocialNetwork ||
														(this.state.groupingIsValidate ?? false)
													}
													title='Quel réseau utilisez-vous ?'
													type={InputType.Text}
													onChange={(e) => {
														var socialNetworks = [
															...(this.state.profile?.securityDetails?.socialNetworkDetails
																?.socialNetworks || [])
														];
														var index =
															this.state.profile?.securityDetails?.socialNetworkDetails?.socialNetworks?.indexOf(
																x
															) || 0;
														var newSocialNetwork = {
															...this.state.profile?.securityDetails?.socialNetworkDetails?.socialNetworks?.at(
																index
															)
														};
														newSocialNetwork.name = e;
														socialNetworks.splice(index, 1, newSocialNetwork);

														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	socialNetworkDetails: {
																		...this.state.profile?.securityDetails
																			?.socialNetworkDetails,
																		socialNetworks: socialNetworks
																	}
																}
															}
														});
													}}
													value={x.name}></Input>
											</div>
										</div>
										<div className='fr-grid-row'>
											<div className='fr-col-12'>
												<Input
													title='Quel(s) pseudo(s) utilisez-vous ?'
													value={x.userName}
													type={InputType.Text}
													disabled={
														!this.state.profile?.securityDetails?.socialNetworkDetails
															?.isUsingSocialNetwork ||
														(this.state.groupingIsValidate ?? false)
													}
													onChange={(e) => {
														var socialNetworks = [
															...(this.state.profile?.securityDetails?.socialNetworkDetails
																?.socialNetworks || [])
														];
														var index =
															this.state.profile?.securityDetails?.socialNetworkDetails?.socialNetworks?.indexOf(
																x
															) || 0;
														var newSocialNetwork = {
															...this.state.profile?.securityDetails?.socialNetworkDetails?.socialNetworks?.at(
																index
															)
														};
														newSocialNetwork.userName = e;
														socialNetworks.splice(index, 1, newSocialNetwork);

														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	socialNetworkDetails: {
																		...this.state.profile?.securityDetails
																			?.socialNetworkDetails,
																		socialNetworks: socialNetworks
																	}
																}
															}
														});
													}}></Input>
											</div>
										</div>
									</React.Fragment>
								);
							})}
							<div
								className='fr-grid-row'
								style={{ marginBottom: '2rem' }}>
								<div className='fr-col-12'>
									<ReferentialSelect
										label="Fréquence d'utilisation"
										value={this.state.profile?.securityDetails?.socialNetworkDetails?.usageFrequencyId}
										type={ReferentialType.SOCIAL_NETWORK_USE_FREQUENCY}
										disabled={
											!this.state.profile?.securityDetails?.socialNetworkDetails?.isUsingSocialNetwork ||
											(this.state.groupingIsValidate ?? false)
										}
										onChange={(e) => {
											this.setState({
												profile: {
													...this.state.profile,
													securityDetails: {
														...this.state.profile?.securityDetails,
														socialNetworkDetails: {
															...this.state.profile?.securityDetails?.socialNetworkDetails,
															usageFrequencyId: e?.id
														}
													}
												}
											});
										}}
									/>
								</div>
								<Button
									size={ButtonSize.Small}
									text='Ajouter un réseau'
									iconClassName='fr-icon-add-line'
									iconState={ButtonIconState.Left}
									type={ButtonType.Tertiary}
									disabled={
										!this.state.profile?.securityDetails?.socialNetworkDetails?.isUsingSocialNetwork ||
										(this.state.groupingIsValidate ?? false)
									}
									onClick={() => {
										var socialNetworks = [
											...(this.state.profile?.securityDetails?.socialNetworkDetails?.socialNetworks || [])
										];
										socialNetworks.push({});
										this.setState({
											profile: {
												...this.state.profile,
												securityDetails: {
													...this.state.profile?.securityDetails,
													socialNetworkDetails: {
														...this.state.profile?.securityDetails?.socialNetworkDetails,
														socialNetworks: socialNetworks
													}
												}
											}
										});
									}}></Button>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									<Radio
										isHorizontal={false}
										items={[
											{
												DisplayName: 'Oui',
												Value: '1',
												Checked:
													(this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails
														?.length || 0) > 0 &&
													(this.state.profile?.securityDetails?.socialNetworkDetails?.otherPhoneNumbers
														?.length || 0) > 0
											},
											{
												DisplayName: 'Non',
												Value: '0',
												Checked:
													this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails
														?.length === 0 &&
													this.state.profile?.securityDetails?.socialNetworkDetails?.otherPhoneNumbers
														?.length === 0
											}
										]}
										isSmall={false}
										name='other-emails'
										title="Utilisez-vous d'autres numéros ou adresses électroniques ?"
										disabled={this.state.groupingIsValidate ?? false}
										onChange={(e) => {
											this.setState({
												profile: {
													...this.state.profile,
													securityDetails: {
														...this.state.profile?.securityDetails,
														socialNetworkDetails: {
															...this.state.profile?.securityDetails?.socialNetworkDetails,
															otherEmails: e.currentTarget.value === '1' ? [''] : [],
															otherPhoneNumbers: e.currentTarget.value === '1' ? [{}] : []
														}
													}
												}
											});
										}}></Radio>
								</div>
							</div>
							<div
								className='fr-grid-row'
								style={{ marginBottom: '2rem' }}>
								<div className='fr-col-12'>
									{this.state.profile?.securityDetails?.socialNetworkDetails?.otherPhoneNumbers?.map(
										(x: any, i) => {
											return (
												<Input
													key={i}
													title='Numéro de téléphone'
													description='Format attendu : (+33)xxxxxxxxx'
													value={x.number}
													type={InputType.Tel}
													onChange={(e) => {
														if (e.startsWith('(+33)')) {
															if (e.length > 14) {
																return;
															}
														} else {
															if (e.length > 30) {
																return;
															}
														}
														var isValid = FormatCheckService.checkPhoneNumber(e);
														var phoneNumbers = [
															...(this.state.profile?.securityDetails?.socialNetworkDetails
																?.otherPhoneNumbers || [])
														];
														var index =
															this.state.profile?.securityDetails?.socialNetworkDetails?.otherPhoneNumbers?.indexOf(
																x
															) || 0;
														var newPhone = {
															...this.state.profile?.securityDetails?.socialNetworkDetails?.otherPhoneNumbers?.at(
																index
															)
														};
														newPhone.number = e;
														phoneNumbers.splice(index, 1, newPhone);

														this.state.phoneNumberError[
															this.state.profile?.securityDetails?.socialNetworkDetails?.otherPhoneNumbers?.indexOf(
																x
															) || 0
														] = isValid ? '' : 'Le format du numéro de téléphone est erroné.';

														this.setState({
															profile: {
																...this.state.profile,
																securityDetails: {
																	...this.state.profile?.securityDetails,
																	socialNetworkDetails: {
																		...this.state.profile?.securityDetails
																			?.socialNetworkDetails,
																		otherPhoneNumbers: phoneNumbers
																	}
																}
															}
														});
													}}
													disabled={
														(this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails
															?.length === 0 &&
															this.state.profile?.securityDetails?.socialNetworkDetails
																?.otherPhoneNumbers?.length === 0) ||
														(this.state.groupingIsValidate ?? false)
													}
													errorMessage={
														this.state.phoneNumberError[
															this.state.profile?.securityDetails?.socialNetworkDetails?.otherPhoneNumbers?.indexOf(
																x
															) || 0
														]
													}></Input>
											);
										}
									)}
									<Button
										disabled={
											(this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails?.length ===
												0 &&
												this.state.profile?.securityDetails?.socialNetworkDetails?.otherPhoneNumbers
													?.length === 0) ||
											(this.state.groupingIsValidate ?? false)
										}
										size={ButtonSize.Small}
										text='Ajouter un numéro de téléphone'
										iconClassName='fr-icon-add-line'
										iconState={ButtonIconState.Left}
										type={ButtonType.Tertiary}
										onClick={() => {
											var otherPhones = [
												...(this.state.profile?.securityDetails?.socialNetworkDetails
													?.otherPhoneNumbers || [])
											];
											otherPhones.push({});
											this.setState({
												profile: {
													...this.state.profile,
													securityDetails: {
														...this.state.profile?.securityDetails,
														socialNetworkDetails: {
															...this.state.profile?.securityDetails?.socialNetworkDetails,
															otherPhoneNumbers: otherPhones
														}
													}
												}
											});
										}}></Button>
								</div>
							</div>
							<div
								className='fr-grid-row'
								style={{ marginBottom: '2rem' }}>
								<div className='fr-col-12'>
									{this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails?.map((x: any, i) => {
										return (
											<Input
												key={i}
												title='Adresse électronique'
												type={InputType.Email}
												value={x}
												onChange={(e) => {
													var isValid = FormatCheckService.checkEmail(e);
													var emails = [
														...(this.state.profile?.securityDetails?.socialNetworkDetails
															?.otherEmails || [])
													];
													var index =
														this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails?.indexOf(
															x
														) || 0;
													var newMail =
														'' +
														this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails?.at(
															index
														);
													newMail = e;
													emails.splice(index, 1, newMail);

													this.state.emailError[
														this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails?.indexOf(
															x
														) || 0
													] = isValid
														? ''
														: 'Le format de l’adresse électronique saisie n’est pas valide.';

													this.setState({
														profile: {
															...this.state.profile,
															securityDetails: {
																...this.state.profile?.securityDetails,
																socialNetworkDetails: {
																	...this.state.profile?.securityDetails?.socialNetworkDetails,
																	otherEmails: emails
																}
															}
														}
													});
												}}
												disabled={
													(this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails
														?.length === 0 &&
														this.state.profile?.securityDetails?.socialNetworkDetails
															?.otherPhoneNumbers?.length === 0) ||
													(this.state.groupingIsValidate ?? false)
												}
												errorMessage={
													this.state.emailError[
														this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails?.indexOf(
															x
														) || 0
													]
												}></Input>
										);
									})}
									<Button
										disabled={
											(this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails?.length ===
												0 &&
												this.state.profile?.securityDetails?.socialNetworkDetails?.otherPhoneNumbers
													?.length === 0) ||
											(this.state.groupingIsValidate ?? false)
										}
										size={ButtonSize.Small}
										text='Ajouter une adresse électronique'
										iconClassName='fr-icon-add-line'
										iconState={ButtonIconState.Left}
										type={ButtonType.Tertiary}
										onClick={() => {
											var otherMails = [
												...(this.state.profile?.securityDetails?.socialNetworkDetails?.otherEmails || [])
											];
											otherMails.push('');
											this.setState({
												profile: {
													...this.state.profile,
													securityDetails: {
														...this.state.profile?.securityDetails,
														socialNetworkDetails: {
															...this.state.profile?.securityDetails?.socialNetworkDetails,
															otherEmails: otherMails
														}
													}
												}
											});
										}}></Button>
								</div>
							</div>
							<BrowserView>
								{!this.state.groupingIsValidate && (
									<div className='fr-grid-row fr-grid-row--right'>
										<div className='fr-col-12 fr-col-md-6 fr-align-right'>
											<Button
												className='no-margin'
												disabled={false}
												text='Enregistrer mes informations'
												type={ButtonType.Primary}
												size={ButtonSize.Medium}
												modalId={this.securityInformationModalId}></Button>
										</div>
									</div>
								)}
							</BrowserView>
						</FormContainer>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-8'>
										<Button
											className='mobile-button'
											disabled={false}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											size={ButtonSize.Medium}
											modalId={this.securityInformationModalId}></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
				<Modal
					size={ModalSize.Large}
					title='Certifier l’exactitude des informations renseignées'
					id={this.securityInformationModalId}
					buttons={[
						{
							DisplayName: 'Certifier l’exactitude des informations renseignées',
							IconClass: '',
							OnClick: () => {
								this.updateProfile(() => this.displaySuccess());
							}
						},
						{
							DisplayName: 'Annuler',
							IconClass: '',
							Type: ButtonType.Secondary
						}
					]}
					children={
						<>
							<div>
								Vous avez renseigné les informations concernant la section sécurité. Veuillez vérifier que les
								informations sont correctes et que vous reconnaissez les informations suivantes.
							</div>
							<br></br>
							<div>
								Je reconnais être informé(e) :
								<dl style={{ paddingLeft: 0 }}>
									<dt>- du caractère obligatoire des réponses qui me sont demandées ;</dt>
									<dt>
										- de ce que je dispose d'un droits d'accès et de rectification, en application des article
										34 et suivant de la loi n°78-17 du 6 janvier 1978 relatives à l'informatique aux fichiers
										et aux libertés que je pourrai exercer auprès des services du haut fonctionnaire de
										défense et de sécurité du ministère de la défense ;
									</dt>
									<dt>
										- que les informations recueillies font l'objet d'un traitement informatique destiné à la
										gestion de la protection du secret de la défense nationale ;
									</dt>
									<dt>
										- que les destinataires des données de ce traitement sont, en fonction de leurs
										attributions et dans la limite du besoin d'en connaître, les services du haut
										fonctionnaire de défense et de sécurité du ministère de la défense.
									</dt>
								</dl>
							</div>
						</>
					}
				/>
			</Container>
		);
	}
}
export default EnvironnementNumerique;
