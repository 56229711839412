import { AppointmentCreateModel, SendAppointmentMailModel } from '../AppointmentService';
import http, { baseURL } from '../../http-common';

class AppointmentPublicService {
	private routeUrl = '/api/public/appointment/';

	async createAppointment(model: AppointmentCreateModel) {
		const getResp = await http.post<{ data: any }>(baseURL + this.routeUrl, model);
		return (await getResp).data;
	}

	async sendAppointmentMail(model: SendAppointmentMailModel) {
		const getResp = await http.post<{ data: any }>(baseURL + this.routeUrl + 'sendappointmentmail/', model);
		return (await getResp).data;
	}
}

export default new AppointmentPublicService();
