import React, { createRef } from 'react';
import { v4 as uuid } from 'uuid';
import { ButtonType } from '../button/Button';

import './0-Modal-mobile.scss';
import './1-Modal-desktop.scss';

class Modal extends React.Component<
	{
		title: string;
		size?: ModalSize;
		buttons: ModalButton[];
		noLink?: boolean;
		children?: JSX.Element | null;
		id?: string;
		custom?: boolean;
		inputRef?: any;
		onClose?: () => void;
	},
	{ mouseOutside: boolean }
> {
	id: string;
	closeButton: React.RefObject<HTMLButtonElement>;

	constructor(props: any) {
		super(props);
		this.id = this.props.id || uuid();
		this.closeButton = createRef();
		this.state = { mouseOutside: false };
	}

	hide() {
		this.closeButton?.current?.click();
		if (this.props.onClose) this.props.onClose();
	}

	handleClick(e: any) {
		if (this.state.mouseOutside) {
			this.hide();
		}
	}

	buildBtn(buttonSizeStr: string, x: ModalButton, typeStr: string, inputRef?: any) {
		return (
			<button
				ref={inputRef}
				className={'fr-btn ' + buttonSizeStr + x.IconClass + ' ' + typeStr + ' '}
				disabled={x.Disabled}
				aria-controls={x.ModalId}
				data-fr-opened='false'
				onClick={() => {
					this.hide();
					if (x.OnClick) x.OnClick();
				}}>
				{x.DisplayName}
			</button>
		);
	}

	render() {
		var custom = '';
		if (this.props.custom == true) {
			custom = '_custom';
		}

		var sizeStr = 'fr-col-12 fr-col-md-8 fr-col-lg-6';

		switch (this.props.size) {
			case ModalSize.Small:
			default:
				sizeStr = 'fr-col-12 fr-col-md-4';
				break;
			case ModalSize.Large:
				sizeStr = 'fr-col-12 fr-col-md-8';
				break;
		}

		var buttonElements = this.props.buttons.map((x, i) => {
			var typeStr = '';
			var buttonSizeStr = '';

			switch (x.Type) {
				case ButtonType.Secondary:
					typeStr = 'fr-btn--secondary';
					break;
				case ButtonType.Tertiary:
					typeStr = 'fr-btn--tertiary';
					break;
			}

			switch (x.SizeStr) {
				case ButtonSize.Small:
					buttonSizeStr = 'fr-btn--sm';
					break;
				case ButtonSize.Large:
					buttonSizeStr = 'fr-btn--lg';
					break;
			}

			return (
				<li key={i}>
					{x.Link != null && (
						<a
							href={x.Link ? x.Link : '#'}
							style={{ backgroundImage: 'inherit' }}>
							{this.buildBtn(buttonSizeStr, x, typeStr, this.props.inputRef)}
						</a>
					)}
					{x.Link == null && this.buildBtn(buttonSizeStr, x, typeStr, this.props.inputRef)}
				</li>
			);
		});

		return (
			<dialog
				aria-labelledby='fr-modal-2-title'
				id={this.id}
				className='fr-modal'
				role='dialog'>
				<div
					onMouseOut={() => this.setState({ mouseOutside: true })}
					onMouseOver={() => this.setState({ mouseOutside: false })}
					className='fr-container fr-container--fluid fr-container-md'>
					<div className='fr-grid-row fr-grid-row--center'>
						<div className={sizeStr}>
							<div className={'fr-modal__body' + custom}>
								<div className='fr-modal__header'>
									<button
										ref={this.closeButton}
										className='fr-btn--close fr-btn'
										aria-controls={this.id}
										onClick={this.hide.bind(this)}>
										Fermer
									</button>
								</div>
								<div className='fr-modal__content'>
									<h1
										id='fr-modal-2-title'
										className='fr-modal__title'>
										{this.props.title}
									</h1>
									{this.props.children}
								</div>
								<div className='fr-modal__footer'>
									<ul className='fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left'>
										{buttonElements}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</dialog>
		);
	}
}

export interface ModalButton {
	DisplayName: string;
	IconClass?: string;
	Disabled?: boolean;
	Type?: ButtonType;
	SizeStr?: ButtonSize;
	OnClick?: () => void;
	ModalId?: string;
	Link?: string;
}

export enum ModalSize {
	Small,
	Large
}

export enum ButtonSize {
	Small,
	Medium,
	Large
}

export default Modal;
