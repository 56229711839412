import http, { baseURL } from '../../http-common';

class CirfaPublicService {
	private routeUrl: string = '/api/public/cirfa';

	async getCirfasByZipCode(zipCode: string, cirfaCategory: number) {
		var armyID = localStorage.getItem('armyId') || 1;
		const getResp = await http.get<{ cirfas: CirfaItem[] }>(
			baseURL + `${this.routeUrl}/0?zipCode=${zipCode}&armyID=${armyID}&cirfaCategory=${cirfaCategory}`
		);

		return (await getResp).data.cirfas;
	}
}

export interface CirfaItem {
	id: number;
	name: string;
	zipCode: string;
	city: string;
	phoneNumber: string;
	country: string;
	isSelected: boolean;
}

export default new CirfaPublicService();
