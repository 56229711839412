enum EnumGroupingType {
	INFORMATIONS_PERSONNELLES = 100,
	INFORMATIONS_PERSONNELLES_ETAT_CIVIL,
	INFORMATIONS_PERSONNELLES_CONTACT,
	INFORMATIONS_PERSONNELLES_SITUATION_FAMILLIALE,
	INFORMATIONS_PERSONNELLES_REPRESENTANT_LEGAL,

	COMPETENCES = 200,
	COMPETENCES_SCOLARITE,
	COMPETENCES_DIPLOMES,
	COMPETENCES_EXPERIENCE_PROFESSIONNELLE,
	COMPETENCES_DISPOSITIF_DEFENSE,
	COMPETENCES_EXPERIENCE_MILITAIRE,
	COMPETENCES_PERMIS,
	COMPETENCES_LANGUES,
	COMPETENCES_DIVERS,

	SECURITE = 300,
	SECURITE_RENSEIGNEMENTS,
	SECURITE_LIEU_NAISSANCE,
	SECURITE_NATIONALITE,
	SECURITE_DOMICILE_ACTUEL,
	SECURITE_DOMICILE_PRECEDENT,
	SECURITE_RENSEIGNEMENTS_FAMILLE,
	SECURITE_ENVIRONNEMENT_NUMERIQUE
}

export default EnumGroupingType;
