/* Composant permettant de contenir des composants Tile et de les afficher correctement */

import React from 'react';

import './1-TileContainer-desktop.scss';

class TileContainer extends React.Component<{ children: React.ReactNode }> {
	render() {
		return <div className={`tile-container`}>{this.props.children}</div>;
	}
}

export default TileContainer;
