import React from 'react';

class Label extends React.Component<{ description: string; type: LabelType; hasIcon: boolean; isSmall: boolean }, {}> {
	render() {
		var typeStr = '';
		var hasIconStr = this.props.hasIcon ? '' : 'fr-badge--no-icon';
		var isSmallStr = this.props.isSmall ? 'fr-badge--sm' : '';
		switch (this.props.type) {
			case LabelType.Success:
				typeStr = 'success';
				break;
			case LabelType.Error:
				typeStr = 'error';
				break;
			case LabelType.Info:
				typeStr = 'info';
				break;
			case LabelType.Warning:
				typeStr = 'warning';
				break;
			case LabelType.New:
				typeStr = 'new';
				break;
			case LabelType.Disabled:
				typeStr = 'disabled';
				break;
		}

		return <p className={'fr-badge fr-badge--' + typeStr + ' ' + isSmallStr + ' ' + hasIconStr}>{this.props.description}</p>;
	}
}

export enum LabelType {
	Success,
	Error,
	Info,
	Warning,
	New,
	Disabled
}

export default Label;
