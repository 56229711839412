/* Page Competences / Divers */

// TODO: Implementer l'upload de fichier

import React from 'react';
import Button, { ButtonIconState } from '../../components/button/Button';
import FormContainer from '../../components/form-container/FormContainer';
import PageTitle from '../../components/page-title/PageTitle';
import Radio from '../../components/radio/Radio';
import { ButtonSize, ButtonType } from '../../components/button/Button';
import Upload from '../../components/upload/Upload';
import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Alert, { AlertSize, AlertType } from '../../components/alert/Alert';
import Container from '../../components/container/Container';
import Tabs from '../../components/tabs/Tabs';
import TabItem from '../../components/tabs/TabItem';
import { BrowserView, MobileView } from 'react-device-detect';
import { TabHeaderItem } from '../../components/tabs/TabHeader';
import AttachmentUpload from '../../components/attachment-upload/AttachmentUpload';
import { AttachmentState } from '../../services/AttachmentService';
import EnumGroupingType from '../../enums/EnumGroupingType';
import FieldCheckerService from '../../services/FieldCheckerService';
import EnumApplicantInputArmyType from '../../enums/EnumApplicantInputArmyType';
import Input, { InputType } from '../../components/input/Input';
import ReferentialsService from '../../services/ReferentialsService';

interface ICompetencesMiscellaneousState extends IProfileState {
	swimmingCertification?: File;
	folderSynthesisShcolarship?: File;
	schoolReports?: File;
	upload?: Upload;
	isSuccess?: boolean;
	isUploadSwimmingCertificate: boolean;
	isDeleteSwimmingCertificate: boolean;
	isUploadFolderSynthesisShcolarship: boolean;
	isDeleteFolderSynthesisShcolarship: boolean;
	isUploadSchoolReports: boolean;
	isDeleteSchoolReports: boolean;
}

class CompetencesMiscellaneous extends BaseComponent<ICompetencesMiscellaneousState> {
	state: ICompetencesMiscellaneousState = {
		isEdit: false,
		isUploadSwimmingCertificate: false,
		isDeleteSwimmingCertificate: false,
		isUploadFolderSynthesisShcolarship: false,
		isDeleteFolderSynthesisShcolarship: false,
		isUploadSchoolReports: false,
		isDeleteSchoolReports: false
	};
	tabItems: TabHeaderItem[] = [];

	groupingType: number = EnumGroupingType.COMPETENCES_DIVERS;

	componentDidUpdate() {
		FieldCheckerService.check(
			EnumApplicantInputArmyType.DIVERS_AVEZ_VOUS_UN_BREVET_DE_NATATION,
			this.state.profile?.professional?.swimming,
			this.requiredFields
		);
	}

	setUploadElement(upload: Upload) {
		this.setState({ upload: upload });
	}

	handleChange = (event: React.FormEvent<HTMLInputElement>) => {
		const needAttachment = event.currentTarget.value === '1';
		this.setState((prevState) => ({
			profile: {
				...this.state.profile,
				professional: {
					...this.state.profile?.professional,
					swimming: needAttachment
				}
			}
		}));
	};
	initUpload() {
		this.setState({
			isUploadSwimmingCertificate: false,
			isDeleteSwimmingCertificate: false
		});
	}

	tabs: TabHeaderItem[] =
		ReferentialsService.getArmy() === 1 || ReferentialsService.getArmy() === 2
			? [
					{
						displayName: 'Formulaire',
						id: 'form',
						tabIndex: 0,
						selected: true
					},
					{
						displayName: 'Pièces jointes',
						id: 'attachments',
						tabIndex: 1,
						selected: false
					}
			  ]
			: [{ displayName: 'Formulaire', id: 'form', tabIndex: 0, selected: true }];

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Divers'
							description='Veuillez remplir les informations demandées ci-dessous'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						{this.state.isSuccess && this.state.groupingIsValidate == false && (
							<Alert
								description='Les informations renseignées ont été enregistrées avec succès'
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Success}
								isShown={this.state.isSuccess}
								onHide={() => this.setState({ isSuccess: false })}
							/>
						)}
						{this.state.isGroupingValidationError && this.state.groupingIsValidate == true && (
							<Alert
								description="Vos modifications n'ont pas été prises en compte, car vos données ont déjà été validées."
								hasCloseButton={true}
								size={AlertSize.Small}
								type={AlertType.Error}
								isShown={this.state.isGroupingValidationError}
								onHide={() => this.setState({ isSuccess: false, isGroupingValidationError: false })}
							/>
						)}
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-12 fr-col-lg-8'>
						<Tabs
							mobileOnly={true}
							isFormContainer={true}
							items={this.tabs}>
							<TabItem
								id='form'
								tabIndex={0}
								mobileOnly={true}>
								<h5>Formulaire</h5>
								<Input
									title='Compétences'
									type={InputType.TextArea}
									value={this.state.profile?.professional?.skills?.map((x) => x.value?.value).join('\n')}
									onChange={(e) => {
										this.setState({
											profile: {
												...this.state.profile,
												professional: {
													...this.state.profile?.professional,
													skills: e.split('\n').map((x) => {
														return { value: { value: x } };
													})
												}
											}
										});
									}}></Input>
								<Radio
									name='swiming'
									title='Avez-vous un brevet de natation ?'
									isHorizontal={false}
									items={[
										{
											DisplayName: 'Oui',
											Value: 1,
											Checked: this.state.profile?.professional?.swimming === true
										},
										{
											DisplayName: 'Non',
											Value: 0,
											Checked: this.state.profile?.professional?.swimming === false
										}
									]}
									disabled={this.state.groupingIsValidate ?? false}
									isSmall={false}
									errorMessage=''
									onChange={this.handleChange}></Radio>
							</TabItem>
							{((this.state.profile?.professional?.swimming && ReferentialsService.getArmy() === 1) ||
								ReferentialsService.getArmy() === 2) && (
								<BrowserView>
									<hr className='fr-hr desktop-only' />
									<h5 className='desktop-only'>Pièces jointes</h5>
								</BrowserView>
							)}
							{((this.state.profile?.professional?.swimming && ReferentialsService.getArmy() === 1) ||
								ReferentialsService.getArmy() === 2) && (
								<TabItem
									id='attachments'
									tabIndex={1}
									mobileOnly={true}>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											<span className='fr-hint-text'>Taille maximale : 10 Mo.</span>
											<span className='fr-hint-text'>Formats supportés : jpg, png, pdf...</span>
										</div>
									</div>
									<div className='fr-grid-row'>
										<div className='fr-col-12'>
											{((this.state.profile?.professional?.swimming &&
												ReferentialsService.getArmy() === 1) ||
												(this.state.profile?.professional?.swimming &&
													ReferentialsService.getArmy() === 2)) && (
												<AttachmentUpload
													noExplanation
													hintText='Attestation de natation'
													isDisabled={this.state.groupingIsValidate ?? false}
													guid={this.state.profile?.professional?.swimmingCertificate?.id}
													validationState={
														this.state.profile?.professional?.swimmingCertificate?.validationState
													}
													onUpload={(guid) => {
														this.setState({
															profile: {
																...this.state.profile,
																professional: {
																	...this.state.profile?.professional,
																	swimmingCertificate: {
																		id: guid,
																		validationState: AttachmentState.ADDED
																	}
																}
															},
															isUploadSwimmingCertificate: true
														});
													}}
													onDelete={() => {
														this.setState({
															profile: {
																...this.state.profile,
																professional: {
																	...this.state.profile?.professional,
																	swimmingCertificate: undefined
																}
															},
															isDeleteSwimmingCertificate: true
														});
													}}
													isUploadInProgress={this.state.isUploadSwimmingCertificate}
													isDeleteInProgress={
														this.state.isDeleteSwimmingCertificate
													}></AttachmentUpload>
											)}
											{ReferentialsService.getArmy() === 2 && (
												<>
													<AttachmentUpload
														noExplanation
														hintText='Fiche de synthèse du dossier scolaire'
														isDisabled={this.state.groupingIsValidate ?? false}
														guid={this.state.profile?.professional?.folderSynthesisShcolarship?.id}
														validationState={
															this.state.profile?.professional?.folderSynthesisShcolarship
																?.validationState
														}
														onUpload={(guid) => {
															this.setState({
																profile: {
																	...this.state.profile,
																	professional: {
																		...this.state.profile?.professional,
																		folderSynthesisShcolarship: {
																			id: guid,
																			validationState: AttachmentState.ADDED
																		}
																	}
																},
																isUploadFolderSynthesisShcolarship: true
															});
														}}
														onDelete={() => {
															this.setState({
																profile: {
																	...this.state.profile,
																	professional: {
																		...this.state.profile?.professional,
																		folderSynthesisShcolarship: undefined
																	}
																},
																isDeleteFolderSynthesisShcolarship: true
															});
														}}
														isUploadInProgress={this.state.isUploadFolderSynthesisShcolarship}
														isDeleteInProgress={
															this.state.isDeleteFolderSynthesisShcolarship
														}></AttachmentUpload>
													<AttachmentUpload
														noExplanation
														hintText='Bulletins scolaires et documents relatifs à la scolarité'
														isDisabled={this.state.groupingIsValidate ?? false}
														guid={this.state.profile?.professional?.schoolReports?.id}
														validationState={
															this.state.profile?.professional?.schoolReports?.validationState
														}
														onUpload={(guid) => {
															this.setState({
																profile: {
																	...this.state.profile,
																	professional: {
																		...this.state.profile?.professional,
																		schoolReports: {
																			id: guid,
																			validationState: AttachmentState.ADDED
																		}
																	}
																},
																isUploadSchoolReports: true
															});
														}}
														onDelete={() => {
															this.setState({
																profile: {
																	...this.state.profile,
																	professional: {
																		...this.state.profile?.professional,
																		schoolReports: undefined
																	}
																},
																isDeleteSchoolReports: true
															});
														}}
														isUploadInProgress={this.state.isUploadSchoolReports}
														isDeleteInProgress={this.state.isDeleteSchoolReports}></AttachmentUpload>
												</>
											)}
										</div>
									</div>
								</TabItem>
							)}
							<BrowserView>
								{!this.state.groupingIsValidate && (
									<div className='fr-grid-row fr-grid-row--right'>
										<div className='fr-col-12 fr-col-md-6 fr-align-right'>
											<Button
												disabled={false}
												onClick={() => {
													this.updateProfile(() => this.displaySuccess());
													this.initUpload();
												}}
												text='Enregistrer mes informations'
												type={ButtonType.Primary}
												iconClassName=''
												size={ButtonSize.Medium}
												modalId=''></Button>
										</div>
									</div>
								)}
							</BrowserView>
						</Tabs>
						<MobileView>
							{!this.state.groupingIsValidate && (
								<div className='fr-grid-row fr-grid-row--center'>
									<div className='fr-col-11 fr-col-lg-8'>
										<Button
											className='mobile-button'
											disabled={false}
											onClick={() => {
												this.updateProfile(() => this.displaySuccess());
												this.initUpload();
											}}
											text='Enregistrer mes informations'
											type={ButtonType.Primary}
											iconClassName=''
											size={ButtonSize.Medium}
											modalId=''></Button>
									</div>
								</div>
							)}
						</MobileView>
					</div>
				</div>
			</Container>
		);
	}
}

export default CompetencesMiscellaneous;
