// TODO: Implementer la pagination

import React, { useEffect, useState } from 'react';
import { ActionMeta, createFilter } from 'react-select';
import ReferentialsService, { ReferentialItem, ReferentialType } from '../../services/ReferentialsService';
import AsyncSelect from 'react-select/async';

import './0-ReferentialSelect-mobile.scss';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
	type: ReferentialType;
	name?: string;
	value?: any | null;
	label?: string | null;
	description?: string | null;
	onChange?: (value?: any) => void;
	customOptionLabel?: (option?: any) => string;
	disabled?: boolean;
	zipCode?: string;
	custom?: boolean;
}

const ReferentialSelectOptimise = ({
	type,
	name,
	value,
	label,
	description,
	onChange,
	customOptionLabel,
	disabled,
	zipCode,
	custom
}: IProps) => {
	const [data, setData] = useState<any[]>([]);
	const [selectId, setSelectId] = useState<string>(uuidv4());
	const [inputvalue, setInputvalue] = useState<any | undefined>();

	useEffect(() => {
		if (type === ReferentialType.ZIPCODE) {
			if (value && zipCode && zipCode != '99999') {
				setData([{ id: value, name: zipCode, zipCode: zipCode }]);
				setInputvalue({ id: value, name: zipCode, zipCode: zipCode });
			}
		}
	}, [value, zipCode]);

	useEffect(() => {
		if (type === ReferentialType.CITY && zipCode && zipCode != '99999') {
			var option = data.find((p) => p.id == value);
			if (data.length > 1) {
				if (value !== '0') {
					if (option) {
						setData([{ id: option.id, name: option.name, zipCode: option.zipCode }]);
						setInputvalue({ id: option.id, name: option.name, zipCode: option.zipCode });
					} else {
						searchCity();
					}
				}
			} else {
				searchCity();
			}
		}
	}, [value, zipCode]);

	const searchCity = () => {
		ReferentialsService.getPaginatedReferential(type, {
			PageNumber: 1,
			PageSize: 50000,
			RankId: 1,
			ShowActivesOnly: true,
			Name: '',
			ZipCode: zipCode
		}).then((result) => {
			if (result && result.data) {
				result.data.map((i) => {
					return {
						id: i.id,
						name:
							custom == true
								? customOptionLabel
									? customOptionLabel(i) ?? ReferentialsService.changeArmyName(i.name)
									: ReferentialsService.changeArmyName(i.name)
								: customOptionLabel
								  ? customOptionLabel(i) ?? i.name
								  : i.name,
						zipCode: i.zipCode
					};
				});

				if (result.data.length === 1) {
					setData(result.data);
					setInputvalue(result.data[0]);
				} else {
					var ar = [{ id: '0', name: 'Sélectionnez', zipCode: result.data[0].zipCode }, ...result.data];
					setData(ar);
					var option = ar.find((p) => p.id == value);
					if (option) {
						setInputvalue(option);
						if (onChange) onChange(option);
					} else {
						setInputvalue(ar[0]);
						if (onChange) onChange(ar[0]);
					}
				}
			}
		});
	};

	const createSearchPromise = (zipCode?: string) => {
		return (inputValue?: string) =>
			new Promise<any[]>(async (resolve) => {
				if (type === ReferentialType.ZIPCODE) {
					if (!zipCode && !inputValue) {
						resolve([]);
						return;
					}
				}

				await ReferentialsService.getPaginatedReferential(type, {
					PageNumber: 1,
					PageSize: 50000,
					RankId: 1,
					ShowActivesOnly: true,
					Name: inputValue,
					ZipCode: inputValue ? undefined : zipCode
				}).then((result) => {
					if (type === ReferentialType.ZIPCODE) {
						const key = 'zipCode';
						result.data = [...new Map(result.data.map((item) => [item[key], item])).values()];

						let zipCodeArray = [{ id: '0', name: 'Sélectionnez' }, ...result.data].map((i) => {
							return {
								id: i.id,
								name:
									custom == true
										? customOptionLabel
											? customOptionLabel(i) ?? ReferentialsService.changeArmyName(i.name)
											: ReferentialsService.changeArmyName(i.name)
										: customOptionLabel
										  ? customOptionLabel(i) ?? i.name
										  : i.name,
								zipCode: i.zipCode
							};
						});
						resolve(zipCodeArray);
					} else {
						if (result && result.data) {
							var ar = [{ id: '0', name: 'Sélectionnez' }, ...result.data];

							if (type === ReferentialType.JOB_CATEGORY_PUBLIC)
								ar = [{ id: '0', name: 'Sélectionnez' }, { id: '-1', name: 'Ne sais pas' }, ...result.data];

							ar.map((i) => {
								return {
									id: i.id,
									name:
										custom == true
											? customOptionLabel
												? customOptionLabel(i) ?? ReferentialsService.changeArmyName(i.name)
												: ReferentialsService.changeArmyName(i.name)
											: customOptionLabel
											  ? customOptionLabel(i) ?? i.name
											  : i.name,
									zipCode: i.zipCode
								};
							});
							setData(ar);
							setInputvalue(ar[0]);
							resolve(ar);
						}
					}
				});
			});
	};

	return (
		<div
			className={disabled ? 'fr-select-group fr-select-group--disabled' : 'fr-select-group'}
			onClick={(e) => e.stopPropagation()}>
			{(label || description) && (
				<label
					style={{ color: '#161616' }}
					className='fr-label'
					htmlFor={selectId}>
					<span>{label}</span>
					<span
						className='fr-hint-text'
						style={{ color: '#161616' }}>
						{description}
					</span>
				</label>
			)}
			<AsyncSelect
				id={selectId}
				isDisabled={disabled}
				onChange={(option: any | null, actionMeta: ActionMeta<any>) => {
					if (onChange) onChange(option);
				}}
				value={inputvalue ? inputvalue : { id: '0', name: 'Sélectionnez' }}
				defaultValue={inputvalue ? inputvalue : { id: '0', name: 'Sélectionnez' }}
				defaultInputValue={inputvalue ? inputvalue.name : ''}
				defaultOptions={data}
				cacheOptions
				loadOptions={createSearchPromise(zipCode)}
				className='sparta-select-container'
				classNamePrefix='sparta-select'
				noOptionsMessage={(input) => 'Aucun résultat'}
				loadingMessage={(input) => 'Chargement...'}
				getOptionLabel={(o: any) => o.name}
				getOptionValue={(o: any) => '' + o.id}
				isOptionSelected={(o, s) => o.id == inputvalue}
				filterOption={createFilter({
					ignoreCase: true,
					ignoreAccents: true,
					trim: false
				})}
			/>
		</div>
	);
};

export default ReferentialSelectOptimise;
