import './0-CirfaCard-mobile.scss';
import './1-CirfaCard-desktop.scss';
import React from 'react';
import Button, { ButtonSize, ButtonType } from '../button/Button';
import { CirfaItem } from '../../services/public/CirfaPublicService';

class CirfaCard extends React.Component<
	{
		cirfa?: CirfaItem;
		modalId?: string;
		noButton?: boolean;
		onClick?: (id: number) => void;
		onClickEvent?: (e: any) => void;
	},
	{}
> {
	render() {
		return this.props.cirfa ? (
			<div
				id='cirfa-card'
				className={
					`fr-card cirfa-card` +
					(this.props.noButton ? ' no-button' : '') +
					(this.props.cirfa.isSelected ? ' selected-cirfa-card' : '')
				}>
				<div
					className='fr-card__body'
					style={{ paddingTop: '1rem' }}>
					<h6 className='fr-h6 fr-text--lead'>{this.props.cirfa.name}</h6>
					<div className='fr-fr-text fr-text--sm'>
						{this.props.cirfa.zipCode + ', ' + this.props.cirfa.city}
						<br></br>
						{this.props.cirfa.country}
					</div>
					<div className='fr-fr-text fr-text--sm'>{this.props.cirfa.phoneNumber}</div>
				</div>
				{(this.props.noButton == null || this.props.noButton == null) && (
					<div className='fr-card__footer'>
						<Button
							size={ButtonSize.Small}
							text='Choisir ce lieu'
							type={ButtonType.Primary}
							modalId={this.props.modalId}
							onClick={(e: any) => {
								this.props.onClick && this.props.onClick(this.props.cirfa ? this.props.cirfa.id : 0);
								this.props.onClickEvent && this.props.onClickEvent(e);
							}}></Button>
					</div>
				)}
			</div>
		) : (
			<span className='fr-text fr-text--xs'>Aucun CIRFA</span>
		);
	}
}

export default CirfaCard;
