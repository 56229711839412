import http from '../http-common';
import ArmyPreferences from '../models/ArmyPreferences';
import NotificationPreferences from '../models/NotificationPreference';
class PreferenceService {
	private _armyPreferenceBaseUrl: string = '/api/armypreferences';
	private _passwordPreferenceBaseUrl: string = '/api/passwordpreferences';
	private _cirfaPreferenceBaseUrl: string = '/api/cirfapreferences';
	private _notificationPreferenceBaseUrl: string = '/api/notificationpreferences';
	private _rgpdPreferenceBaseUrl: string = '/api/rgpdpreferences';

	public async getArmyPreferences() {
		const getResp = http.get<ArmyPreferences>(this._armyPreferenceBaseUrl);
		const armypreferences = (await getResp).data;
		return armypreferences;
	}

	public async getNotificationPreferences() {
		const getResp = http.get<NotificationPreferences>(this._notificationPreferenceBaseUrl);
		const notificationpreferences = (await getResp).data;
		return notificationpreferences;
	}

	public async getCurrentCirfaPreferences() {
		var armyID = localStorage.getItem('armyId') || 1;
		const getResp = http.get<CirfaItem>(this._cirfaPreferenceBaseUrl + `?armyId=${armyID}`);
		const cirfapreferences = (await getResp).data;
		return cirfapreferences;
	}

	public async putArmyPreferences(AAE: boolean, ADT: boolean, MN: boolean) {
		const getResp = http.put(this._armyPreferenceBaseUrl, {
			AAE: AAE,
			ADT: ADT,
			MN: MN
		});
		return await getResp;
	}

	public async putCirfaPreferences(newCirfa: CirfaItem) {
		var armyID = localStorage.getItem('armyId') || 1;
		const getResp = http.put(this._cirfaPreferenceBaseUrl, {
			newCirfaId: newCirfa.id,
			armyId: armyID
		});
		return await getResp;
	}

	public async putPasswordPreferences(password: string) {
		const getResp = http.put(this._passwordPreferenceBaseUrl, {
			password: password
		});
	}

	public async putNotificationPreferences(
		emailCampaignNotification: boolean,
		instantMessagingNotification: boolean,
		otherNotification: boolean
	) {
		const getResp = http.put(this._notificationPreferenceBaseUrl, {
			emailCampaignNotification: emailCampaignNotification,
			instantMessagingNotification: instantMessagingNotification,
			otherNotification: otherNotification
		});
	}

	public async postRGPDPreferences() {
		const getResp = http.post(this._rgpdPreferenceBaseUrl, {});
	}
}

export interface CirfaItem {
	id: number;
	name: string;
	zipCode: string;
	city: string;
	phoneNumber: string;
	country: string;
	isSelected: boolean;
}

export default new PreferenceService();
