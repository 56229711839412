import { useEffect, useState } from 'react';
import Input, { InputType } from '../../input/Input';
import { EventCustomer } from '../../../models';
import axios from 'axios';
import { AccountStepProps } from '../../../models/ComponentStepProps';

declare var grecaptcha: any;

function PasswordStep({ model, handleChange }: AccountStepProps) {
	const [validationLength, setValidationLength] = useState(false);
	const [validationSpecialCharacter, setValidationSpecialCharacter] = useState(false);
	const [validationLowerCaseCharacter, setValidationLowerCaseCharacter] = useState(false);
	const [validationUpperCaseCharacter, setValidationUpperCaseCharacter] = useState(false);
	const [validationNumber, setValidationNumber] = useState(false);
	const [validationSamePassword, setValidationSamePassword] = useState(false);

	useEffect(() => {
		setValidationSamePassword(model.password == model.confirmPassword);
		if (process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY) {
			grecaptcha.enterprise.ready(() => {
				grecaptcha.enterprise.render('sparta-captcha', {
					sitekey: process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY,
					callback: () => handleCapchaChange(),
					'expired-callback': () => handleCapchaChange()
				});
			});
		}
	}, []);

	const handleCapchaChange = () => {
		var recaptchaResponse = grecaptcha.enterprise.getResponse();
		var recaptchaResponse = grecaptcha.enterprise.getResponse();
		axios.post(process.env.REACT_APP_RECAPTCHA_EVALUATION_URL!, {
			event: {
				token: recaptchaResponse,
				siteKey: process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY
			}
		});
		const isValidCapcha =
			(process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY && recaptchaResponse) ||
			!process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY;

		const evtIsValidCapcha: EventCustomer = { name: 'isValidCapcha', value: isValidCapcha };
		handleChange(evtIsValidCapcha);
	};

	const handlePasswordChange = (e: any) => {
		validPassword(e.target.value);
		setValidationSamePassword(e.target.value == model.confirmPassword);
		handleChange(e);
	};

	const handleConfirmPasswordChange = (e: any) => {
		setValidationSamePassword(e.target.value == model.password);
		handleChange(e);
	};

	const validPassword = (password: string) => {
		setValidationLength(password.length >= 10);
		setValidationSpecialCharacter(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password));
		setValidationNumber(/[0-9]/.test(password));
		setValidationLowerCaseCharacter(/[a-zàâäéèêëiïîôöùûüÿçæœ]/.test(password));
		setValidationUpperCaseCharacter(/[A-ZÂÊÎÔÛÄËÏÖÜÀÆÇÉÈŒÙ]/.test(password));
	};

	return (
		<div>
			<h5>Se créer un compte en renseignant votre mot de passe ci-dessous</h5>
			<p className='fr-hint-text account-creation-hint'>Sauf mention contraire, tous les champs sont obligatoires.</p>
			<div>
				<Input
					title='Mot de passe'
					name='password'
					type={InputType.Password}
					value={model.password}
					onChangeEvent={(e) => {
						handlePasswordChange(e);
					}}
				/>
				<div className='change-password-rules'>
					<p className='fr-hint-text'>Votre mot de passe doit contenir:</p>
					<span className={'fr-hint-text fr-text-default--' + (validationLength ? 'success' : 'info')}>
						<span className={'fr-icon--sm fr-icon-' + (validationLength ? 'success-fill' : 'info-fill')}></span> 10
						caractères minimum
					</span>
					<span className={'fr-hint-text fr-text-default--' + (validationSpecialCharacter ? 'success' : 'info')}>
						<span
							className={
								'fr-icon--sm fr-icon-' + (validationSpecialCharacter ? 'success-fill' : 'info-fill')
							}></span>{' '}
						1 caractère spécial
					</span>
					<span className={'fr-hint-text fr-text-default--' + (validationLowerCaseCharacter ? 'success' : 'info')}>
						<span
							className={
								'fr-icon--sm fr-icon-' + (validationLowerCaseCharacter ? 'success-fill' : 'info-fill')
							}></span>{' '}
						1 caractère minuscule
					</span>
					<span className={'fr-hint-text fr-text-default--' + (validationUpperCaseCharacter ? 'success' : 'info')}>
						<span
							className={
								'fr-icon--sm fr-icon-' + (validationUpperCaseCharacter ? 'success-fill' : 'info-fill')
							}></span>{' '}
						1 caractère majuscule
					</span>
					<span className={'fr-hint-text fr-text-default--' + (validationNumber ? 'success' : 'info')}>
						<span className={'fr-icon--sm fr-icon-' + (validationNumber ? 'success-fill' : 'info-fill')}></span> 1
						chiffre minimum
					</span>
				</div>
				<Input
					title='Confirmation du mot de passe'
					name='confirmPassword'
					type={InputType.Password}
					value={model.confirmPassword}
					onChangeEvent={(e) => {
						handleConfirmPasswordChange(e);
					}}
					errorMessage={
						model.confirmPassword !== undefined && model.confirmPassword !== '' && validationSamePassword === false
							? 'Le champ "Confirmation du mot de passe" doit être similaire au champ "Mot de passe"'
							: ''
					}
				/>
				{process.env.REACT_APP_RECAPTCHA_ACCOUNT_CREATION_KEY && (
					<>
						<hr className='fr-hr' />
						<h5>Code de sécurité</h5>
						{<div id='sparta-captcha'></div>}
					</>
				)}
			</div>
		</div>
	);
}

export default PasswordStep;
