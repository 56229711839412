import { isDisabled } from '@testing-library/user-event/dist/utils';
import React from 'react';

class Link extends React.Component<
	{
		className?: string;
		title: string;
		iconClass?: string;
		link?: string;
		isIconLeft?: boolean;
		size: LinkSize;
		isdisabled?: boolean;
		onClick?: React.MouseEventHandler<HTMLAnchorElement>;
	},
	{}
> {
	render() {
		var isIconLeftStr = this.props.isIconLeft ? 'fr-link--icon-left' : 'fr-link--icon-right';
		var sizeStr = '';
		switch (this.props.size) {
			case LinkSize.Small:
				sizeStr = 'fr-link--sm';
				break;
			case LinkSize.Large:
				sizeStr = 'fr-link--lg';
				break;
		}

		if (this.props.isdisabled) {
			return (
				<a
					className={
						' fr-link ' + sizeStr + ' ' + this.props.iconClass + ' ' + isIconLeftStr + ' ' + this.props.className
					}>
					{this.props.title}
				</a>
			);
		}

		return (
			<a
				className={' fr-link ' + sizeStr + ' ' + this.props.iconClass + ' ' + isIconLeftStr + ' ' + this.props.className}
				href={this.props.link}
				onClick={this.props.onClick}>
				{this.props.title}
			</a>
		);
	}
}

export enum LinkSize {
	Small,
	Medium,
	Large
}

export default Link;
