import React from 'react';

class LinkHeader extends React.Component<{ link: string; label: string; isActive: boolean }, {}> {
	render() {
		return (
			<li>
				{this.props.isActive == true && (
					<a
						className='fr-nav__link'
						href={this.props.link}
						target='_self'
						aria-current='page'>
						{this.props.label}
					</a>
				)}
				{this.props.isActive == false && (
					<a
						className='fr-nav__link'
						href={this.props.link}
						target='_self'>
						{this.props.label}
					</a>
				)}
			</li>
		);
	}
}

export default LinkHeader;
