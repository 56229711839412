/* Composant permettant d'encadrer les formulaires d'un background gris commme dans la majorité des écrans */

import React from 'react';

import './0-FormContainer-mobile.scss';
import './1-FormContainer-desktop.scss';

class FormContainer extends React.Component<{ children: React.ReactNode }> {
	render() {
		return <div className='form-container fr-background-alt--grey'>{this.props.children}</div>;
	}
}

export default FormContainer;
