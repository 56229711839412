import BaseComponent, { IProfileState } from '../../components/BaseComponent';
import Container from '../../components/container/Container';
import FormContainer from '../../components/form-container/FormContainer';
import Input, { InputType } from '../../components/input/Input';
import PageTitle from '../../components/page-title/PageTitle';
import EnumGroupingType from '../../enums/EnumGroupingType';
import ReferentialsService, { ReferentialType } from '../../services/ReferentialsService';

interface ILieuNaissanceState extends IProfileState {
	isLieuNaissanceLoading?: boolean;
}

class LieuNaissance extends BaseComponent<ILieuNaissanceState> {
	state: ILieuNaissanceState = { isEdit: false };
	birthCityName: any = {};
	birthCityZipcode: any = {};

	birthCountryShortName: any = {};

	groupingType: number = EnumGroupingType.SECURITE_LIEU_NAISSANCE;

	constructor(props: any) {
		super(props);
		this.registerGetHook(async () => {
			this.setState({ isLieuNaissanceLoading: true }, async () => {
				await ReferentialsService.getReferential(
					ReferentialType.CITY,
					this.state.profile?.personal?.identity?.birthCityId ?? 0
				).then((x) => {
					this.birthCityName = this.state.profile?.personal?.identity?.birthCity?.name ?? '';
					this.birthCityZipcode = this.state.profile?.personal?.identity?.birthCity?.zipCode ?? '';
				});
				await ReferentialsService.getReferential(
					ReferentialType.NATION,
					this.state.profile?.personal?.identity?.birthCountryId ?? 0
				).then((x) => {
					this.birthCountryShortName = x?.data?.shortName ?? '';
					this.setState({ isLieuNaissanceLoading: false });
				});
			});
		});
	}

	render() {
		return (
			<Container isLoading={this.state.isLoading}>
				<div className='fr-grid-row fr-grid-row--center fr-grid-row--gutters'>
					<div className='fr-col-11 fr-col-lg-8'>
						<PageTitle
							title='Lieu de naissance'
							description='Certains champs concernant vos informations personnelles sont automatiquement remplies. Veuillez vérifier l’exactitude des informations.'
						/>
					</div>
				</div>
				<div className='fr-grid-row fr-grid-row--center'>
					<div className='fr-col-12 fr-col-lg-8'>
						<FormContainer>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									{!this.state.isLieuNaissanceLoading && (
										<Input
											title='Code postal du lieu de naissance'
											value={this.birthCityZipcode}
											type={InputType.Text}
											disabled={true}></Input>
									)}
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									{!this.state.isLieuNaissanceLoading && (
										<Input
											title='Commune de naissance'
											value={this.birthCityName}
											type={InputType.Text}
											disabled={true}></Input>
									)}
								</div>
							</div>
							<div className='fr-grid-row'>
								<div className='fr-col-12'>
									{!this.state.isLieuNaissanceLoading && (
										<Input
											title='Pays de naissance'
											value={this.birthCountryShortName}
											type={InputType.Text}
											disabled={true}></Input>
									)}
								</div>
							</div>
						</FormContainer>
					</div>
				</div>
			</Container>
		);
	}
}
export default LieuNaissance;
