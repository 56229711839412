import { useEffect, useState } from 'react';
import Radio, { RadioItem } from '../../radio/Radio';
import ReferentialsService, { ReferentialType } from '../../../services/ReferentialsService';
import DateService from '../../../services/DateService';
import Input, { InputType } from '../../input/Input';
import { AccountStepProps } from '../../../models/ComponentStepProps';

function EligibilityStep({ model, handleChange }: AccountStepProps) {
	const [frenchItems, setFrenchItems] = useState<RadioItem[]>([]);
	const [army, setArmy] = useState<any>();

	useEffect(() => {
		const frenchItems: RadioItem[] = [
			{ DisplayName: 'Oui', Value: 'true', Checked: model.hasFrenchNationality == true },
			{ DisplayName: 'Non', Value: 'false', Checked: model.hasFrenchNationality == false }
		];
		setFrenchItems(frenchItems);
		ReferentialsService.getReferentialArmy()
			.then((army) => {
				setArmy(army);
			})
			.catch();
	}, []);

	const handleChangeFrenchNationality = (evt: any) => {
		if (evt.target) {
			frenchItems.filter((x) => x.Value !== evt.target.value).map((x) => (x.Checked = false));
			frenchItems.filter((x) => x.Value === evt.target.value).map((x) => (x.Checked = true));
			handleChange(evt);
		}
	};

	return (
		<div>
			<h5>Se créer un compte en renseignant les informations complémentaires ci-dessous</h5>
			<p className='fr-hint-text account-creation-hint'>Sauf mention contraire, tous les champs sont obligatoires.</p>
			<Radio
				title='Êtes-vous de nationalité française ?'
				name='hasFrenchNationality'
				items={frenchItems || []}
				isHorizontal={false}
				disabled={false}
				isSmall={false}
				onChange={(e) => handleChangeFrenchNationality(e)}
				errorMessage={
					model.hasFrenchNationality !== undefined && model.hasFrenchNationality == false
						? `Vous n'êtes pas de nationalité française, vous ne pouvez pas vous engager dans ` +
						  army?.name +
						  `. Veuillez consulter <a href='https://www.legion-recrute.com/fr' title='La légion étrangère' target='_blank' rel='noopener'>la légion étrangère</a>`
						: ''
				}
			/>
			<Input
				title='Date de naissance'
				name='birthDate'
				type={InputType.Date}
				value={DateService.Convert(model.birthDate ?? '')}
				onChangeEvent={(e) => handleChange(e)}
				errorMessage={
					model.birthDate !== undefined &&
					model.birthDate !== '' &&
					DateService.getAgeFromString(model.birthDate) < army?.minAge
						? `Vous devez avoir plus de ${army?.minAge} ans pour vous engager dans ${army?.name}.`
						: model.birthDate !== undefined &&
						    DateService.getAgeFromString(model.birthDate) > +(localStorage.getItem('maxAge') || army?.maxAge)
						  ? `Votre âge ne vous permet plus de vous engager comme soldat, mais vous pouvez encore devenir réserviste en appelant votre CIRFA ou entrer dans <a href='https://www.legion-recrute.com/fr' title='La légion étrangère' target='_blank' rel='noopener'>la légion étrangère</a>`
						  : ''
				}
			/>
		</div>
	);
}

export default EligibilityStep;
