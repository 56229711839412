import React from 'react';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

class TabItem extends React.Component<{
	id: string;
	tabIndex: number;
	selected?: boolean;
	children: React.ReactNode;
	mobileOnly: boolean;
	noBackroundGray?: boolean;
}> {
	render() {
		if (isMobile || !this.props.mobileOnly) {
			return (
				<div
					id={`tabpanel-${this.props.id}-panel`}
					className={
						'fr-tabs__panel ' +
						(this.props.noBackroundGray ? '' : 'fr-background-alt--grey') +
						' ' +
						(this.props.selected ? 'fr-tabs__panel--selected' : '')
					}
					role='tabpanel'
					aria-labelledby={`tabpanel-${this.props.id}`}
					tabIndex={this.props.tabIndex}>
					{this.props.children}
				</div>
			);
		} else {
			return this.props.children;
		}
	}
}

export default TabItem;
