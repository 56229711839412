import React from 'react';
import { ReactNode } from 'react';
import attachmentService, { AttachmentState } from '../../services/AttachmentService';
import Attachment from '../attachment/Attachment';
import Upload from '../upload/Upload';

interface IAttachmentUploadState {
	guid?: string;
}

class AttachmentUpload extends React.Component<{
	isDisabled?: boolean;
	isUploadInProgress: boolean;
	isDeleteInProgress: boolean;
	guid?: string;
	validationState?: AttachmentState;
	title?: string;
	hintText?: string;
	noExplanation?: boolean;
	onDelete?: () => void;
	onUpload?: (guid: string) => void;
	publicFunction?: Function;
	publicFile?: File;
}> {
	state: IAttachmentUploadState = {};
	upload?: Upload;

	setUploadElement(upload?: Upload) {
		this.upload = upload;
	}

	editAttachment = (): void => {
		this.upload?.click();
	};

	deleteAttachment = (): void => {
		this.upload?.setIsDisabled(false);

		if (this.props.onDelete) this.props.onDelete();
	};

	setAttachment(file: File | null) {
		this.upload?.setIsDisabled(true);

		if (file) {
			if (this.props.publicFunction) {
				this.props.publicFunction(file);
			}
			else {
				attachmentService.post(file).then((value) => {
					if (value) {
						this.setState({ guid: value }, () => {
							if (this.props.onUpload) this.props.onUpload(value);
						});
					}
				});
			}
		}
	}

	render(): ReactNode {
		return (
			<>
				<Upload
					assignMe={this.setUploadElement.bind(this)}
					hintText={this.props.hintText}
					onChange={this.setAttachment.bind(this)}
					noExplanation={this.props.noExplanation}
					title={this.props.title}
					isDisabled={this.props.isDisabled}
					isUploadInProgress={this.props.isUploadInProgress}
					isDeleteInProgress={this.props.isDeleteInProgress}
					publicFile={this.props.publicFile}
					guid={this.props.guid}></Upload>
				<Attachment
					guid={this.props.guid}
					validationState={this.props.validationState}
					onDelete={this.deleteAttachment.bind(this)}
					onEdit={this.editAttachment.bind(this)}
					isDisabled={this.props.isDisabled}
					isUploadInProgress={this.props.isUploadInProgress}
					isDeleteInProgress={this.props.isDeleteInProgress}
					publicFile={this.props.publicFile}
				/>
			</>
		);
	}
}

export default AttachmentUpload;
