// TODO: Implementer la pagination

import React from 'react';
import { ActionMeta, createFilter } from 'react-select';
import ReferentialsService, { ReferentialItem, ReferentialType } from '../../services/ReferentialsService';
import AsyncSelect from 'react-select/async';

import './0-ReferentialSelect-mobile.scss';
import { v4 as uuidv4 } from 'uuid';

interface IReferentialSelectState {
	isLoading?: boolean;
	value?: any;
	fullValue?: any;
}

export default class ReferentialSelect extends React.Component<{
	type: ReferentialType;
	name?: string;
	value?: any | null;
	label?: string | null;
	description?: string | null;
	onChange?: (value?: any) => void;
	customOptionLabel?: (option?: any) => string;
	disabled?: boolean;
	zipCode?: string;
	custom?: boolean;
}> {
	page: number = 1;
	initialOption?: { id: number; name: string };
	hasMore: boolean = true;
	state: IReferentialSelectState = { isLoading: true };
	zipCode: string | undefined;
	componentDidUpdate(prevProps: any) {
		if (this.state.value?.id != prevProps.value && prevProps.value) {
			if (this.props.value && this.props.value !== '0') {
				ReferentialsService.getReferential(this.props.type, this.props.value).then((value) => {
					this.setState(
						{
							isLoading: false,
							value: {
								id: value.data.id,
								name:
									this.props.custom == true
										? this.props.customOptionLabel
											? this.props.customOptionLabel(ReferentialsService.changeArmyName(value.data))
											: ReferentialsService.changeArmyName(value.data.name)
										: this.props.customOptionLabel
										  ? this.props.customOptionLabel(value.data)
										  : value.data.name
							},
							fullValue: value.data
						},
						() => {
							if (this.props.onChange) this.props.onChange(this.state.fullValue);
						}
					);
				});
			} else {
				this.setState(
					{
						isLoading: false,
						value: {
							id: '0',
							name: 'Sélectionnez'
						},
						fullValue: {
							id: '0',
							name: 'Sélectionnez'
						}
					},
					() => {
						if (this.props.onChange) this.props.onChange(this.state.fullValue);
					}
				);
			}
		}
	}

	componentDidMount() {
		if (this.props.value && this.props.value !== '0') {
			ReferentialsService.getReferential(this.props.type, this.props.value).then((value) => {
				let armyid = ReferentialsService.getArmy();
				if (!armyid || (value.data && value.data.armyID === armyid)) {
					this.setState(
						{
							isLoading: false,
							value: {
								id: value.data.id,
								name:
									this.props.custom == true
										? this.props.customOptionLabel
											? this.props.customOptionLabel(ReferentialsService.changeArmyName(value.data))
											: ReferentialsService.changeArmyName(value.data.name)
										: this.props.customOptionLabel
										  ? this.props.customOptionLabel(value.data)
										  : value.data.name
							},
							fullValue: value.data
						},
						() => {
							if (this.props.onChange) this.props.onChange(this.state.fullValue);
						}
					);
				} else {
					this.setState(
						{
							isLoading: false,
							value: {
								id: '0',
								name: 'Sélectionnez'
							},
							fullValue: {
								id: '0',
								name: 'Sélectionnez'
							}
						},
						() => {
							if (this.props.onChange) this.props.onChange(this.state.fullValue);
						}
					);
				}
			});
		} else {
			this.setState(
				{
					isLoading: false,
					value: {
						id: '0',
						name: 'Sélectionnez'
					},
					fullValue: {
						id: '0',
						name: 'Sélectionnez'
					}
				},
				() => {
					if (this.props.onChange) this.props.onChange(this.state.fullValue);
				}
			);
		}
	}

	refreshZipCode = (): boolean => {
		this.zipCode = this.props.zipCode;
		return true;
	};

	handleInputChange = (newValue: ReferentialItem) => {
		this.page = 1;
		return newValue.id;
	};

	handleOnScrollBottom = (e: WheelEvent | TouchEvent): void => {
		// TODO: Pagination
		/*
    if (this.hasMore)
      this.page = this.page + 1;
      */
	};

	createSearchPromise(zipCode?: string) {
		return (inputValue?: string) =>
			new Promise<any[]>(async (resolve) => {
				await ReferentialsService.getPaginatedReferential(this.props.type, {
					PageNumber: this.page,
					PageSize: 50000,
					RankId: 1,
					ShowActivesOnly: true,
					Name: inputValue,
					ZipCode: zipCode
				}).then((data) => {
					this.hasMore = data.currentPage < data.totalPages;
					if (this.props.type === ReferentialType.ZIPCODE) {
						const key = 'zipCode';
						data.data = [...new Map(data.data.map((item) => [item[key], item])).values()];

						let zipCodeArray = [{ id: '0', name: 'Sélectionnez' }, ...data.data].map((i) => {
							return {
								id: i.id,
								name:
									this.props.custom == true
										? this.props.customOptionLabel
											? this.props.customOptionLabel(i) ?? ReferentialsService.changeArmyName(i.name)
											: ReferentialsService.changeArmyName(i.name)
										: this.props.customOptionLabel
										  ? this.props.customOptionLabel(i) ?? i.name
										  : i.name,
								zipCode: i.zipCode
							};
						});
						resolve(zipCodeArray);
					} else {
						if (data && data.data) {
							var ar = [{ id: '0', name: 'Sélectionnez' }, ...data.data];

							if (this.props.type === ReferentialType.JOB_CATEGORY_PUBLIC)
								ar = [{ id: '0', name: 'Sélectionnez' }, { id: '-1', name: 'Ne sais pas' }, ...data.data];

							ar.map((i) => {
								return {
									id: i.id,
									name:
										this.props.custom == true
											? this.props.customOptionLabel
												? this.props.customOptionLabel(i) ?? ReferentialsService.changeArmyName(i.name)
												: ReferentialsService.changeArmyName(i.name)
											: this.props.customOptionLabel
											  ? this.props.customOptionLabel(i) ?? i.name
											  : i.name,
									zipCode: i.zipCode
								};
							});
							resolve(ar);
						}
					}
				});
			});
	}

	render() {
		var disabledStr = this.props.disabled ? ' fr-select-group--disabled' : '';
		const selectid = uuidv4();

		return (
			<div
				className={'fr-select-group' + disabledStr}
				onClick={(e) => e.stopPropagation()}>
				{(this.props.label || this.props.description) && (
					<label
						style={{ color: '#161616' }}
						className='fr-label'
						htmlFor={selectid}>
						<span>{this.props.label}</span>
						<span
							className='fr-hint-text'
							style={{ color: '#161616' }}>
							{this.props.description}
						</span>
					</label>
				)}
				{this.zipCode == this.props.zipCode && (
					<AsyncSelect
						onChange={(option: any | null, actionMeta: ActionMeta<any>) => {
							this.setState({
								value: { id: option.id, name: option.name }
							});
							if (this.props.onChange) this.props.onChange(option);
						}}
						inputId={selectid}
						name={this.props.name || ''}
						isDisabled={this.props.disabled}
						className='sparta-select-container'
						classNamePrefix='sparta-select'
						value={this.state.value}
						onMenuScrollToBottom={this.handleOnScrollBottom}
						loadOptions={this.createSearchPromise(this.props.zipCode)}
						defaultValue={{ id: '0', name: 'Sélectionnez' }}
						defaultOptions={true}
						noOptionsMessage={(input) => 'Aucun résultat'}
						loadingMessage={(input) => 'Chargement...'}
						isOptionSelected={(o, s) => o.id == this.props.value}
						getOptionLabel={(o: any) => o.name}
						getOptionValue={(o: any) => '' + o.id}
						filterOption={createFilter({
							ignoreCase: true,
							ignoreAccents: true,
							trim: false
						})}
					/>
				)}
				{this.zipCode != this.props.zipCode && (
					<AsyncSelect
						onChange={(option: any | null, actionMeta: ActionMeta<any>) => {
							this.setState({
								value: { id: option.id, name: option.name }
							});
							if (this.props.onChange) this.props.onChange(option);
						}}
						id={selectid}
						isDisabled={this.props.disabled}
						className='sparta-select-container'
						classNamePrefix='sparta-select'
						value={this.state.value}
						onMenuScrollToBottom={this.handleOnScrollBottom}
						loadOptions={this.createSearchPromise(this.props.zipCode)}
						defaultValue={{ id: '0', name: 'Sélectionnez' }}
						defaultOptions={true}
						noOptionsMessage={(input) => 'Aucun résultat'}
						loadingMessage={(input) => 'Chargement...'}
						isOptionSelected={(o, s) => o.id == this.props.value}
						getOptionLabel={(o: any) => o.name}
						getOptionValue={(o: any) => '' + o.id}
						filterOption={createFilter({
							ignoreCase: true,
							ignoreAccents: true,
							trim: false
						})}
					/>
				)}
				{this.zipCode != this.props.zipCode && this.refreshZipCode()}
			</div>
		);
	}
}
