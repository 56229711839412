import http from '../http-common';
import fileDownload from 'js-file-download';

// TODO: Implementer le Content et ExpirationDAte dans le retour de l'api
class AttachmentService {
	async post(file: File) {
		var data = new FormData();
		data.append('file', file);
		const getResp = http.post<{ guid?: string }>('/api/attachment', data);
		return (await getResp).data.guid;
	}

	async delete(id: string) {
		return http.delete('/api/attachment/' + id);
	}

	async get(id: string) {
		const getResp = await http.get<{ attachment: AttachmentResponse }>('/api/attachment/' + id, {
			params: { with_content: false }
		});
		if (getResp.status === 200) {
			return getResp.data.attachment;
		}
		return { guid: undefined } as AttachmentResponse;
	}

	async download(id: string) {
		const getResp = await http.get<{ attachment: AttachmentResponse }>('/api/attachment/' + id, {
			params: { with_content: true }
		});
		fileDownload(this.base64ToArrayBuffer(getResp.data.attachment.content), getResp.data.attachment.filename || 'monfichier');
	}

	private base64ToArrayBuffer(base64: string) {
		var binaryString = window.atob(base64);
		var binaryLen = binaryString.length;
		var bytes = new Uint8Array(binaryLen);
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		return bytes;
	}
}
export default new AttachmentService();

export interface AttachmentResponse {
	size?: number;
	filename?: string;
	displayName?: string;
	guid?: string;
	id: number;
	content: string;
}

export enum AttachmentState {
	ADDED,
	WAITING_FOR_VALIDATION,
	VALIDATED
}
