import React from 'react';

class Accordion extends React.Component<{ title: string; element: JSX.Element; id: string }, {}> {
	render() {
		return (
			<section className='fr-accordion'>
				<h3 className='fr-accordion__title'>
					<button
						onClick={(e) => e.preventDefault()}
						className='fr-accordion__btn'
						aria-expanded='false'
						aria-controls={this.props.id}>
						{this.props.title}
					</button>
				</h3>
				<div
					className='fr-collapse'
					id={this.props.id}>
					{this.props.element}
				</div>
			</section>
		);
	}
}

export default Accordion;
